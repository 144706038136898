import React, { useCallback, useEffect, useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import usePrevious from '../../hooks/usePrevious';

import ParameterFilter from './Filter/ParameterFilter';
import MapBox from '../ContentBox/MapBox';
import Card from '../Card/Card';

import { FLAG, SEEN, UNSEEN } from '../BulletinView/constantes';

const VariableView = ({
  disableFlag,
  setMapStatus,
  updateEntryStatus,
  userIsAuthenticated,
  variableIdToDisplay,
  variableToDisplay,
  nextEntryLink,
  prevEntryLink,
}) => {
  const [filterParameters, setFilterParameters] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [modalIsOpen, setModal] = useState(false);
  const [mapStatus, setStatus] = useState(null);
  const prevVariableIdToDisplay = usePrevious(variableIdToDisplay);

  const generateMapStatus = useCallback(() => {
    const nextMapStatus = {};
    variableToDisplay.graphs.forEach(({ id, status }) => {
      nextMapStatus[id] = status;
    });
    setStatus(nextMapStatus);
  }, [variableToDisplay]);

  const updateFilter = useCallback(
    nextFilterParameters => {
      setFilterParameters(nextFilterParameters);
    },
    [setFilterParameters],
  );

  const getFilteredGraphs = (graphs, filters) =>
    graphs.filter(graph =>
      Object.entries(filters).every(([key, value]) => graph[key] === value),
    );

  const filterGraphs = useCallback(
    bulletin => {
      const { graphs } = bulletin;
      const filteredGraphs = getFilteredGraphs(graphs, filterParameters);
      const mensualFilteredGraphs = getFilteredGraphs(graphs, {
        ...filterParameters,
        diagnostic_type: 'clim-RMSDobs',
      });
      const nextGraphs = [...filteredGraphs, ...mensualFilteredGraphs]

        // the sort function works because : 'FCST0' < 'FCST1' < 'FCST3'
        .sort((a, b) => a.leadtime.localeCompare(b.leadtime));

      if (!nextGraphs.some(graph => graph.metric_type === 'CLASS4')) {
        return nextGraphs.map(item => [item]);
      }

      // We need to groupBy CLASS
      return nextGraphs.reduce((list, item, index) => {
        if (index % 2 === 0) {
          list.push([item]);
        } else {
          list[list.length - 1].push(item);
        }
        return list;
      }, []);
    },
    [filterParameters],
  );

  // initialize the mapStatus if null
  useEffect(() => {
    if (mapStatus === null) {
      generateMapStatus();
    }
  }, [mapStatus, generateMapStatus]);

  useEffect(() => {
    // when maps are displayed, mark automatically all unseen maps as seen
    if (userIsAuthenticated) {
      const nextMapStatus = {};
      const nextStatus = filterGraphs(variableToDisplay)
        .flat()
        .reduce((list, item) => {
          // get item state from local state mapStatus (the map can be flagged)
          if (mapStatus?.[item.id] === UNSEEN) {
            nextMapStatus[item.id] = { status: SEEN };
            return {
              ...list,
              [item.id]: SEEN,
            };
          }
          return list;
        }, {});

      if (Object.keys(nextMapStatus).length !== 0) {
        setStatus(prevStatus => ({
          ...prevStatus,
          ...nextStatus,
        }));
        setMapStatus(nextMapStatus);
      }
    }
  }, [
    userIsAuthenticated,
    filterParameters,
    filterGraphs,
    variableToDisplay,
    setMapStatus,
    mapStatus,
  ]);

  useEffect(() => {
    // remove unseen eye in the menu if all unseen maps are seen
    if (
      mapStatus &&
      !Object.values(mapStatus).includes(UNSEEN) &&
      variableToDisplay.id === variableIdToDisplay
    ) {
      // next condition is to cover the specific case when a user switch
      // from one category to another onto the same variable
      if (prevVariableIdToDisplay === variableIdToDisplay) {
        updateEntryStatus(variableToDisplay, { isUnseen: false });
      }
    }
  }, [
    updateEntryStatus,
    variableToDisplay,
    mapStatus,
    variableIdToDisplay,
    prevVariableIdToDisplay,
  ]);

  // remove flag in the menu only if all maps have no flag
  useEffect(() => {
    if (
      mapStatus &&
      !Object.values(mapStatus).includes(FLAG) &&
      variableToDisplay.id === variableIdToDisplay
    ) {
      updateEntryStatus(variableToDisplay, { isFlagged: false });
    }
  }, [mapStatus, variableToDisplay, variableIdToDisplay, updateEntryStatus]);

  const toggleImageModal = useCallback(
    index => {
      const filteredGraphs = filterGraphs(variableToDisplay).flat();
      const nextSelectedIndex = Array.isArray(filteredGraphs[0])
        ? filteredGraphs
            .reduce(
              (list, item) => {
                if (item.length === 2) {
                  list.push(
                    list[list.length - 1] + 1,
                    list[list.length - 1] + 3,
                  );
                } else {
                  list.push(list[list.length - 1] - 1);
                }
                return list;
              },
              [-1],
            )
            .slice(1)[index]
        : index;
      setModal(boolean => !boolean);
      setSelectedIndex(nextSelectedIndex);
    },
    [filterGraphs, variableToDisplay],
  );

  const updateMapStatus = (variable, map, comment, isFlagged) => {
    // update the mapStatus
    setStatus(prevMapStatus => ({
      ...prevMapStatus,
      [map.id]: isFlagged ? FLAG : SEEN,
    }));

    if (isFlagged) {
      updateEntryStatus(variable, { isFlagged: true });
    }

    // Send the informations to the server
    setMapStatus({
      [map.id]: {
        status: isFlagged ? FLAG : SEEN,
        comment,
      },
    });
  };

  return (
    <div>
      <ParameterFilter
        variable={variableToDisplay}
        update={updateFilter}
        filterParameters={filterParameters}
        variableId={variableIdToDisplay}
        nextEntryLink={nextEntryLink}
        prevEntryLink={prevEntryLink}
      />

      {variableToDisplay.outdated && (
        <Card className="alert-danger warningCard">
          <div className="text-center">
            The maps displayed for this variable are outdated !
          </div>
        </Card>
      )}

      <div className="grid">
        {mapStatus &&
          filterGraphs(variableToDisplay).map((row, rowIndex) => {
            let Tag = React.Fragment;
            let nextIndex = rowIndex;
            if (row.length === 2) {
              Tag = 'div';
              nextIndex = rowIndex + Number(rowIndex > 0) * rowIndex;
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Tag key={rowIndex}>
                {row.map((graph, index) => (
                  <MapBox
                    key={graph.id}
                    content={graph}
                    index={nextIndex + index}
                    toggleImageModal={toggleImageModal}
                    updateFlag={updateMapStatus}
                    variable={variableToDisplay}
                    status={mapStatus[graph.id]}
                    disableFlag={!userIsAuthenticated || disableFlag}
                    userIsAuthenticated={userIsAuthenticated}
                  />
                ))}
              </Tag>
            );
          })}
      </div>

      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={toggleImageModal}>
            <Carousel
              views={filterGraphs(variableToDisplay)
                .flat()
                .map(g => ({
                  src: g.picture,
                  caption: g.name,
                }))}
              currentIndex={selectedIndex}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

export default VariableView;
