import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Card from '../Card/Card';
import MapFiltersSet from './MapFilterSet';
import Loader from '../Loader/Loader';
import MapBox from '../ContentBox/MapBox';
import ViewTitle from '../ViewTitle/ViewTitle';
import {
  useMapExistingFiltersQueries,
  usePictureQueries,
} from '../../services/api';
import getNewRoute from '../../containers/helpers';

import './MapView.scss';

const MapView = () => {
  const [selectedIndex, setIndex] = useState(0);
  const [modalIsOpen, setModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const activeFilters = useMemo(
    () => JSON.parse(searchParams.get('activeFilters')) ?? [],
    [searchParams],
  );

  const existingFiltersQuery = useMapExistingFiltersQueries(
    activeFilters?.length > 0 ? activeFilters : [{ date: null }],
  );

  const pictures = usePictureQueries(activeFilters);

  const existingFilters = existingFiltersQuery.map(({ data }) => data?.filters);
  const loadingExistingFilters = existingFiltersQuery.some(
    ({ isLoading }) => isLoading,
  );

  const generateFirstFilter = useCallback(() => {
    const defaultFilter = {};
    existingFilters[0].forEach(({ title, values: [first, second] }) => {
      if (title === 'date') {
        defaultFilter[title] = second; // set the default date as the most recent
      } else {
        defaultFilter[title] = first.pk ?? first;
        let next = first;
        while (next.child_title) {
          // browse recursively the first branch of the tree
          defaultFilter[next.child_title] =
            next.child_values[0].pk || next.child_values[0];
          [next] = next.child_values;
        }
      }
    });
    return defaultFilter;
  }, [existingFilters]);

  const handleAddNewFilter = useCallback(() => {
    const filtersLength = activeFilters.length;
    const newFilter = filtersLength
      ? activeFilters[filtersLength - 1]
      : generateFirstFilter();
    const nextActiveFilters = [...activeFilters, newFilter];
    navigate(getNewRoute(location, { activeFilters: nextActiveFilters }));
  }, [activeFilters, generateFirstFilter, location, navigate]);

  const handleRemoveFilter = useCallback(
    filterOrder => {
      const nextActiveFilters = [...activeFilters];
      nextActiveFilters.splice(filterOrder, 1);
      navigate(getNewRoute(location, { activeFilters: nextActiveFilters }));
    },
    [activeFilters, location, navigate],
  );

  const handleUpdateFilter = useCallback(
    (filter, filterOrder) => {
      const nextActiveFilters = [...activeFilters];
      nextActiveFilters[filterOrder] = filter;
      navigate(getNewRoute(location, { activeFilters: nextActiveFilters }));
    },
    [activeFilters, location, navigate],
  );

  const toggleModal = useCallback(index => {
    setModal(boolean => !boolean);
    setIndex(index);
  }, []);

  useEffect(() => {
    if (
      existingFilters.length &&
      !activeFilters?.length &&
      !loadingExistingFilters
    ) {
      handleAddNewFilter();
    }
  }, [
    activeFilters,
    existingFilters,
    existingFilters.length,
    handleAddNewFilter,
    loadingExistingFilters,
  ]);

  if (loadingExistingFilters) return <Loader>Loading filters...</Loader>;

  return (
    <>
      <ViewTitle pageTitle="Lon/Lat maps" />
      <div className="GraphView">
        <div className={`mapsDisplay size-${Math.min(pictures.length, 3)}`}>
          {pictures.map((picture, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {picture?.isLoading && <Loader />}
              {picture.data && (
                <MapBox
                  content={picture.data}
                  index={index}
                  toggleImageModal={toggleModal}
                  flagCode
                  noButton
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <Card className="filters">
          <MapFiltersSet
            filters={activeFilters}
            existingFilters={existingFilters}
            addVisualization={handleAddNewFilter}
            removeVisualization={handleRemoveFilter}
            updateFilters={handleUpdateFilter}
            updateActiveFilters={handleUpdateFilter}
          />
        </Card>
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={toggleModal}>
              <Carousel
                views={pictures.map(picture =>
                  picture.data
                    ? {
                        src: picture.data.picture,
                        caption: picture.data.name,
                      }
                    : { src: '/404', caption: 'No picture for the request' },
                )}
                currentIndex={selectedIndex}
              />
            </Modal>
          )}
        </ModalGateway>
      </div>
    </>
  );
};

export default MapView;
