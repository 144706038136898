import React from 'react';

import './CheckboxButton.scss';

const CheckboxButton = ({ id, label, defaultChecked, onChange }) => (
  <span className="Button-checkbox">
    <div className="checkbox">
      <input
        id={id}
        name={id}
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={e => {
          onChange(id, e.target.checked);
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id} arial-label={label} />
    </div>
    {label}
  </span>
);

export default CheckboxButton;
