import React, { useState, useCallback, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { EnvContext } from '../../context/Env';

import VariableListItem from './VariableListItem';
import './MenuDisplay.scss';

import { sortOrdersFunction, getCategoryToCollapse } from './Bulletin.helper';

const MenuDisplay = ({
  bulletinDate,
  systems,
  entriesStatus,
  userIsAuthenticated,
  entryId,
  entryType,
  prevNextCategory = undefined,
}) => {
  const {
    env: { BULLETIN_DEFAULT_DATE },
  } = EnvContext();

  // keep list of active categories per systems
  const [activeCategories, setActiveCategories] = useState(
    systems.reduce((obj, system) => ({ ...obj, [system.id]: [] }), {}),
  );
  const [formatedSystems, setFormatedSystems] = useState(systems);
  const previousActiveCategories = useRef();

  const getGroupSeenIconStatus = useCallback(
    entries => {
      const unseenList = entries.filter(
        ({ id, name }) => entriesStatus[`${id}-${name}`].isUnseen,
      );

      if (unseenList.length === entries.length) {
        return {
          icon: 'icon-circle-dash',
          title: 'All variables are unseen',
        };
      }
      if (unseenList.length > 0) {
        return {
          icon: 'icon-incomplete',
          title: 'Variables are partially seen',
        };
      }
      return {
        icon: 'icon-check',
        title: 'Everything is seen',
      };
    },
    [entriesStatus],
  );

  // keep previous active categories
  useEffect(() => {
    previousActiveCategories.current = activeCategories;
  }, [activeCategories]);

  // update actives categories if systems | entryId | entryType change
  useEffect(() => {
    const formatedSys = systems.sort(sortOrdersFunction).map(system => {
      const orderedCategories = system.categories.sort(sortOrdersFunction);
      // open accordion only for the category containing the current entry id
      // or if none are selected, for the first category of the first displayed system
      const currentCategoryName = orderedCategories.find(category =>
        category.entries.some(
          entry => entry.id === entryId && entry.type === entryType,
        ),
      )?.name;

      return {
        ...system,
        orderedCategories,
        activeCategories: currentCategoryName ? [currentCategoryName] : [],
      };
    });

    // In the bulletin, when using arrows to navigate
    // - to the next variable of another category, we want to close the previous category
    // - to the previous variable of another category, we want to close the next category
    let categoryToCollapse = null;
    if (prevNextCategory) {
      categoryToCollapse = getCategoryToCollapse(
        formatedSys,
        entryId,
        entryType,
        prevNextCategory,
      );
    }

    const activeCategoriesBySystem = formatedSys.reduce((obj, cur) => {
      // merge current active categories with previously selected categories
      // to keep the previous user selection, if any
      const activeCats = [
        ...new Set([
          ...cur?.activeCategories,
          ...(previousActiveCategories.current?.[cur?.id] ?? []),
        ]),
      ];

      // Delete specific category if necessary from the active ones
      // (it will collapse it)
      if (
        prevNextCategory &&
        categoryToCollapse?.categorySystemId === cur?.id
      ) {
        const categoryIndexToDelete = activeCats.indexOf(
          categoryToCollapse?.categoryName,
        );
        activeCats.splice(categoryIndexToDelete, 1);
      }

      return {
        ...obj,
        [cur?.id]: activeCats,
      };
    }, {});

    setActiveCategories(activeCategoriesBySystem);
    setFormatedSystems(formatedSys);
  }, [systems, entryId, entryType, prevNextCategory]);

  return formatedSystems.map(system => (
    <div
      key={system.id}
      className={`systems-item ${
        bulletinDate !== BULLETIN_DEFAULT_DATE
          ? 'olderBulletin'
          : 'newestBulletin'
      }`}
    >
      <h2 className="systems-item__title">{system.name}</h2>
      <Accordion
        activeKey={activeCategories[system.id]}
        onSelect={eventKey => {
          setActiveCategories(prevState => ({
            ...prevState,
            [system.id]: eventKey,
          }));
        }}
        alwaysOpen
        flush
      >
        {system?.orderedCategories?.map(category => (
          <Accordion.Item key={category.name} eventKey={category.name}>
            <Accordion.Header>
              <span className="accordion-button-span">
                {userIsAuthenticated && (
                  <span
                    className={`icon ${
                      getGroupSeenIconStatus(category.entries).icon
                    }`}
                    title={getGroupSeenIconStatus(category.entries).title}
                  />
                )}
                <span className="label">{category.name}</span>
              </span>
            </Accordion.Header>
            <Accordion.Body>
              {category.entries.sort(sortOrdersFunction).map((entry, index) => (
                <VariableListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${entry.id}-${category.id}-${index}`}
                  bulletinDate={bulletinDate}
                  entry={entry}
                  category={category}
                  status={entriesStatus[`${entry.id}-${entry.name}`]}
                  userIsAuthenticated={userIsAuthenticated}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  ));
};

export default MenuDisplay;
