import APIService from '../../services/api';
import { setError } from '../error/actions';

import { LOAD_3DVIEW_GRAPH, RECEIVE_3DVIEW_GRAPH } from './actions';

export const load3DViewGraph = () => ({
  type: LOAD_3DVIEW_GRAPH,
});

export const receive3DViewGraph = data => ({
  type: RECEIVE_3DVIEW_GRAPH,
  data,
});

export const get3DViewGraphData = filters => async dispatch => {
  dispatch(load3DViewGraph());
  try {
    const res = await APIService.get3DViewGraphData(filters);
    dispatch(receive3DViewGraph(res));
  } catch (err) {
    dispatch(setError(`Issue while fetching graph data: ${err}`));
    throw err;
  }
};
