import React, { memo, useMemo } from 'react';
import MapFilters from './MapFilters';
import '../OmiView/Filters/OmiFilterSet.scss';

const GenerateLabel = ({ column }) => (
  <React.Fragment key={column.child_title}>
    <th className="moniqua-select-title" key={column.child_title}>
      {column.child_title}
    </th>
    {column?.child_values?.[0].child_title && (
      <GenerateLabel column={column.child_values[0]} />
    )}
  </React.Fragment>
);

const getTHColumnsLabel = ({
  title: t,
  child_title: title = t,
  values: v,
  child_values: values = v,
}) => {
  const labels = [title];
  const childValues = values?.[0].child_title;
  if (childValues) {
    labels.push(getTHColumnsLabel(values[0]));
  }
  return labels.flat(Infinity);
};

const MapFiltersSet = ({
  filters,
  updateFilters,
  existingFilters,
  onlyOneFilter,
  removeVisualization,
  addVisualization,
  updateActiveFilters,
}) => {
  const labels = useMemo(() => {
    if (!existingFilters?.[0]?.[1]) {
      return [];
    }
    return getTHColumnsLabel(existingFilters[0][1]);
  }, [existingFilters]);

  if (
    !existingFilters ||
    existingFilters.length === 0 ||
    !existingFilters?.[0]?.[1]
  ) {
    return <div>No filters available</div>;
  }

  return (
    <div className="table-responsive">
      <h2>Parameters</h2>
      <table className="table borderless">
        <thead>
          <tr>
            <th label="button" />
            <th className="moniqua-select-title" key="date-selector-title">
              Date
            </th>
            {labels.map(label => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filters.map((filter, index) => (
            <MapFilters
              // eslint-disable-next-line react/no-array-index-key
              key={`filter-lonlat-${index}`}
              indexFilter={index}
              activeFilters={filter}
              existingFilters={existingFilters[index]}
              onClickRemoveButton={() => removeVisualization(index)}
              hasMoreThanOneFilter={filters.length > 1}
              onChange={(values, fieldName) =>
                updateFilters(index, values, fieldName)
              }
              isFirstRow={index === 0}
              updateActiveFilters={updateActiveFilters}
            />
          ))}
          <tr>
            <td>
              {!onlyOneFilter && (
                <button
                  className="filters2-button add-button2"
                  onClick={addVisualization}
                  type="button"
                >
                  +
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default memo(MapFiltersSet);
