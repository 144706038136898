import React from 'react';
import CheckboxButton from './CheckboxButton';
import InputText from './InputText';

import './StatFilter.scss';

const StatFilter = ({ statisticDisplay, updateStatDisplay }) => (
  <div className="StatFilter">
    <h2
      style={{
        color: '#273b4b',
        fontWeight: 'bold',
        fontSize: 18,
        borderBottom: 'none',
        marginLeft: 20,
      }}
    >
      Time Serie Options
    </h2>
    <div
      style={{
        backgroundColor: '#273b4b',
        marginLeft: 20,
        width: '90%',
        height: 1,
        marginTop: 10,
        marginBottom: 20,
      }}
    />
    <div className="StatFilter-Items">
      <CheckboxButton
        id="median"
        label="Median"
        defaultChecked={statisticDisplay && statisticDisplay.median}
        onChange={updateStatDisplay}
      />

      <CheckboxButton
        id="mean"
        label="Mean"
        defaultChecked={statisticDisplay && statisticDisplay.mean}
        onChange={updateStatDisplay}
      />

      <CheckboxButton
        id="lowTukey"
        label="Low Tukey"
        defaultChecked={statisticDisplay && statisticDisplay.lowTukey}
        onChange={updateStatDisplay}
      />

      <CheckboxButton
        id="highTukey"
        label="High Tukey"
        defaultChecked={statisticDisplay && statisticDisplay.highTukey}
        onChange={updateStatDisplay}
      />

      <CheckboxButton
        id="lowPercentile"
        label="Low Percentile"
        defaultChecked={statisticDisplay && statisticDisplay.lowPercentile}
        onChange={updateStatDisplay}
      />

      <CheckboxButton
        id="highPercentile"
        label="High Percentile"
        defaultChecked={statisticDisplay && statisticDisplay.highPercentile}
        onChange={updateStatDisplay}
      />

      <InputText
        id="percentile"
        label="Percentile Value"
        onChange={updateStatDisplay}
        value={statisticDisplay && statisticDisplay.percentile}
      />
    </div>
  </div>
);

export default StatFilter;
