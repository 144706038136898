import React, { memo, useEffect } from 'react';
import Loader from '../Loader/Loader';
import { useBulletinPercentageValidation } from '../../services/api';

const ReviewProgression = ({
  bulletinDate,
  variableIdToDisplay,
  reportIdToDisplay,
  entriesStatus,
  userIsAuthenticated,
}) => {
  const id =
    reportIdToDisplay !== null
      ? `r/${reportIdToDisplay}`
      : `v/${variableIdToDisplay}`;

  const {
    isLoading,
    data: bulletinPercentageValidation,
    refetch,
  } = useBulletinPercentageValidation({
    date: bulletinDate,
    id,
    enabled: userIsAuthenticated,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entriesStatus]);

  if (isLoading) {
    return <Loader />;
  }

  if (!userIsAuthenticated || !bulletinPercentageValidation) {
    return null;
  }

  const seensItemsAsPercentage = Math.round(bulletinPercentageValidation);

  return (
    <div className="reviewProgression">
      <div>Progression: {seensItemsAsPercentage}%</div>
      <progress max="100" value={seensItemsAsPercentage} />
    </div>
  );
};

export default memo(ReviewProgression);
