import { createContext, React, useContext } from 'react';

export const context = createContext(null);

export const EnvContext = () => useContext(context);

const { Provider } = context;

const EnvProvider = ({ env, ...props }) => {
  const value = {
    env,
  };

  return <Provider value={value} {...props} />;
};

export default EnvProvider;
