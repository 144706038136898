import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { EnvContext } from '../../context/Env';

const MessageBulletinDate = ({
  message = 'You are looking the bulletin from',
}) => {
  const { bulletinDate, reportId, variableId = reportId } = useParams();
  const {
    env: { BULLETIN_DEFAULT_DATE },
  } = EnvContext();

  if (BULLETIN_DEFAULT_DATE === bulletinDate) {
    return null;
  }

  const code = reportId ? 'r' : 'v';

  return (
    <div className="card info text-center">
      {message} {bulletinDate.replaceAll('-', '/')}.
      <Link to={`/bulletin/${BULLETIN_DEFAULT_DATE}/${code}/${variableId}`}>
        Go back to the latest bulletin.
      </Link>
    </div>
  );
};

export default MessageBulletinDate;
