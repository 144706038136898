import React from 'react';

import './InputText.scss';

const InputText = ({ id, label, value, onChange }) => (
  <span className="InputText">
    {label}
    <input
      type="text"
      value={value}
      onChange={e => {
        onChange(id, e.target.value);
      }}
    />
  </span>
);

export default InputText;
