import React from 'react';

import GraphBox from '../ContentBox/GraphBox';
import './ReportView.scss';
import ParameterFilter from '../VariableView/Filter/ParameterFilter';

import Loader from '../Loader/Loader';
import APIService, { getOMIGraphDataQuery } from '../../services/api';

import { FLAG, SEEN } from '../BulletinView/constantes';

class ReportView extends React.Component {
  constructor() {
    super();
    this.state = {
      graphsByUrl: {},
      amountOfRequests: 0,
      graphStatus: null,
    };
    this.updateGraphStatus = this.updateGraphStatus.bind(this);
  }

  componentDidMount() {
    const { reportToDisplay, updateEntryStatus } = this.props;
    this.fetchGraphData();
    this.generateGraphStatus();
    updateEntryStatus(reportToDisplay, { isUnseen: false });
  }

  fetchGraphData() {
    const { reportToDisplay } = this.props;
    let amountOfRequests = 0;
    reportToDisplay.graphs.forEach(graph => {
      const [baseURL, searchURL] = graph.url.split('?');
      const graphType = baseURL.split('/').slice(-1)[0];
      const parsedSearch = new URLSearchParams(searchURL);
      const urlActiveFilters = JSON.parse(parsedSearch.get('activeFilters'));
      try {
        switch (graphType) {
          case 'range':
            (async () =>
              APIService.getRangeGraphData(urlActiveFilters, {}))().then(
              data => {
                this.setState(({ graphsByUrl: prevGraphsByUrl }) => ({
                  graphsByUrl: {
                    ...prevGraphsByUrl,
                    [`${graph.url}-data`]: data,
                  },
                }));
              },
            );
            amountOfRequests += 1;
            break;
          case 'in-situ': {
            (async () =>
              APIService.getInSituGraphData(urlActiveFilters))().then(data => {
              this.setState(({ graphsByUrl: prevGraphsByUrl }) => ({
                graphsByUrl: {
                  ...prevGraphsByUrl,
                  [`${graph.url}-data`]: data,
                },
              }));
            });
            const filtersData = urlActiveFilters.map(currentFilter => ({
              ...currentFilter,
              stat: 1,
            }));
            (async () => APIService.getInSituGraphData(filtersData))().then(
              data => {
                this.setState(({ graphsByUrl: prevGraphsByUrl }) => ({
                  graphsByUrl: {
                    ...prevGraphsByUrl,
                    [`${graph.url}-data2`]: data,
                  },
                }));
              },
            );
            amountOfRequests += 2;
            break;
          }
          case 'tz-view':
            (async () =>
              APIService.get3DViewGraphData(urlActiveFilters))().then(data => {
              this.setState(({ graphsByUrl: prevGraphsByUrl }) => ({
                graphsByUrl: {
                  ...prevGraphsByUrl,
                  [`${graph.url}-data`]: data,
                },
              }));
            });
            amountOfRequests += 1;
            break;
          case 'omi':
            (async () => getOMIGraphDataQuery(urlActiveFilters, {}))().then(
              data => {
                this.setState(({ graphsByUrl: prevGraphsByUrl }) => ({
                  graphsByUrl: {
                    ...prevGraphsByUrl,
                    [`${graph.url}`]: data,
                  },
                }));
              },
            );
            amountOfRequests += 1;
            break;
          default:
            throw new Error(
              'Error with one of the given url to display. Please correct the configuration of the report.',
            );
        }
      } catch (err) {
        throw new Error(err);
      }
    });
    this.setState({ amountOfRequests });
  }

  generateGraphStatus() {
    const { reportToDisplay, setGraphStatus, userIsAuthenticated } = this.props;
    const graphStatus = {};
    reportToDisplay.graphs.forEach(({ id, status }) => {
      graphStatus[id] = status === FLAG ? FLAG : SEEN;
      if (userIsAuthenticated && status !== FLAG) {
        setGraphStatus({ [id]: { status: SEEN, comment: '' } });
      }
    });
    this.setState({ graphStatus });
  }

  updateGraphStatus(graph, comment, isFlagged) {
    const { updateEntryStatus, setGraphStatus, reportToDisplay } = this.props;
    let nextGraphStatus = {};
    // update the mapStatus
    this.setState(({ graphStatus: prevGraphStatus }) => {
      nextGraphStatus = {
        ...prevGraphStatus,
        [graph.id]: isFlagged ? FLAG : SEEN,
      };
      return {
        graphStatus: nextGraphStatus,
      };
    });
    if (isFlagged) {
      updateEntryStatus(reportToDisplay, { isFlagged: true });
    } else if (
      nextGraphStatus &&
      !Object.values(nextGraphStatus).includes(FLAG)
    ) {
      // remove flag icon in the menu if there is not flagged graph anymore
      updateEntryStatus(reportToDisplay, { isFlagged: false });
    }
    // Send the informations to the server
    setGraphStatus({
      [graph.id]: {
        status: isFlagged ? FLAG : SEEN,
        comment,
      },
    });
  }

  allGraphsLoaded() {
    const { amountOfRequests, graphsByUrl } = this.state;
    if (amountOfRequests > 0) {
      return (
        this.state &&
        graphsByUrl &&
        Object.keys(graphsByUrl).length === amountOfRequests
      );
    }
    return true;
  }

  render() {
    const {
      reportToDisplay,
      disableFlag,
      userIsAuthenticated,
      prevEntryLink,
      nextEntryLink,
    } = this.props;

    const { graphsByUrl, graphStatus } = this.state;

    if (!this.allGraphsLoaded() || !graphStatus) {
      return <Loader>Loading content...</Loader>;
    }

    return (
      <div>
        <ParameterFilter
          nextEntryLink={nextEntryLink}
          prevEntryLink={prevEntryLink}
        />
        <div className="graphs">
          {reportToDisplay &&
            reportToDisplay.graphs &&
            reportToDisplay.graphs.map(graph => (
              <GraphBox
                key={graph.id}
                content={graph}
                status={graphStatus[graph.id]}
                FLAG_CODE={FLAG}
                SEEN_CODE={SEEN}
                disableFlag={!userIsAuthenticated || disableFlag}
                data={
                  graphsByUrl[graph.url]
                    ? [graphsByUrl[graph.url]]
                    : [
                        graphsByUrl[`${graph.url}-data`],
                        graphsByUrl[`${graph.url}-data2`],
                      ]
                }
                updateFlagMenu={this.updateGraphStatus}
                userIsAuthenticated={userIsAuthenticated}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default ReportView;
