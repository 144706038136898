import React from 'react';
import Loader from '../Loader/Loader';
import InSituGraph from './Graph/InSituGraph';

import FiltersSet from '../FiltersSet/FiltersSet';
import Card from '../Card/Card';
import GenericView from './GenericView';
import ViewTitle from '../ViewTitle/ViewTitle';

import './InSituView.scss';

class InSituView extends GenericView {
  updateLayout = e => {
    let newLayoutDisplay = {};
    if (e.target.value === 'on') {
      newLayoutDisplay = {
        log: 'on',
      };
    }
    this.props.updateRoute(this.props.location, {
      layoutDisplay: newLayoutDisplay,
    });
  };

  render() {
    const {
      loadedFilters,
      loadingGraphData,
      activeFilters,
      availableFilters,
      activeSyncFilters,
      graphData,
      dateRange,
      colors,
      // In Situ props
      graphData2,
      logarithmicDisplay,
      graphOnly,
      urlActiveFilters,
    } = this.props;

    if (!loadedFilters) return <Loader>Loading filters...</Loader>;

    let FiltersView = <Loader />;
    let GraphView = <Loader />;
    let GraphDataView = <Loader />;
    let LogarithmicOption = null;

    let maxDepth = 0.0;
    if (graphData !== null) {
      graphData?.depth?.forEach(d => {
        const currentDepth = parseFloat(d);
        if (currentDepth > maxDepth) maxDepth = currentDepth;
      });
    }
    if (graphData2 !== null) {
      graphData2?.depth?.forEach(d => {
        const currentDepth = parseFloat(d);
        if (currentDepth > maxDepth) maxDepth = currentDepth;
      });
    }
    if (logarithmicDisplay === 'on') maxDepth = Math.log10(maxDepth);
    const customLayout = {
      yaxis: {
        title: 'depth',
        range: [maxDepth, 0],
        type: logarithmicDisplay === 'on' ? 'log' : '',
      },
    };

    GraphView = (
      <InSituGraph
        activeFilters={graphOnly ? urlActiveFilters : activeFilters}
        dateRange={dateRange}
        data={graphData}
        loading={loadingGraphData}
        color={colors}
        type="scatter"
        isExportable
        layout={customLayout}
      />
    );
    const customLayoutData = { ...customLayout, xaxis: { type: 'log' } };
    GraphDataView = (
      <InSituGraph
        activeFilters={graphOnly ? urlActiveFilters : activeFilters}
        dateRange={dateRange}
        data={graphData2}
        loading={loadingGraphData}
        color={colors}
        type="bar"
        isExportable
        layout={customLayoutData}
      />
    );
    LogarithmicOption = (
      <div className="LogarithmicOption">
        <h2
          style={{
            color: '#273b4b',
            fontWeight: 'bold',
            fontSize: 18,
            borderBottom: 'none',
            marginLeft: 20,
          }}
        >
          Logarithmic display
        </h2>
        <div
          style={{
            backgroundColor: '#273b4b',
            marginLeft: 20,
            width: '90%',
            height: 1,
            marginTop: 10,
            marginBottom: 20,
          }}
        />
        <span className="Button-radio">
          <input
            type="radio"
            name="logarithmicDisplay"
            id="logarithmicDisplay--on"
            value="on"
            onClick={this.updateLayout}
            defaultChecked={logarithmicDisplay === 'on'}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="logarithmicDisplay--on">ON</label>
        </span>
        <span className="Button-radio">
          <input
            type="radio"
            name="logarithmicDisplay"
            id="logarithmicDisplay--off"
            value="off"
            onClick={this.updateLayout}
            defaultChecked={logarithmicDisplay === 'off'}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="logarithmicDisplay--off">OFF</label>
        </span>
      </div>
    );

    if (loadedFilters) {
      FiltersView = (
        <FiltersSet
          filters={activeFilters}
          availableFilters={availableFilters}
          activeSyncFilters={activeSyncFilters}
          isSyncable={false}
          addVisualization={this.addVisualization}
          removeVisualization={this.removeVisualization}
          updateFilters={this.updateFilters}
          // In situ properties
          color={colors}
          onlyWednesday
          allowStatChoice={false}
        />
      );
    }

    if (graphOnly) {
      return (
        <div className="GraphView insitu">
          <Card className="graph no-border">
            {GraphView}
            {GraphDataView}
          </Card>
        </div>
      );
    }

    return (
      <>
        <ViewTitle pageTitle="Vertical Profile" />
        <div className="GraphView insitu">
          <Card className="graph">
            {GraphView}
            {GraphDataView}
          </Card>
          <Card className="filters">
            {FiltersView}
            {LogarithmicOption}
          </Card>
        </div>
      </>
    );
  }
}

export default InSituView;
