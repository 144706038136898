import React from 'react';

import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';

const View404 = () => (
  <Loader>
    <h2>404 Page</h2>
    Maybe you can wait here for a long time...
    <br />
    Because what you're searching is not here !... <br />
    <br />
    Please go to the <Link to="/">home</Link> page.
  </Loader>
);

export default View404;
