import React from 'react';
import './GenericView.scss';

class GenericView extends React.Component {
  componentDidMount() {
    const {
      urlActiveFilters,
      urlActiveSyncFilters,
      activeFilters,
      optionsData,
      graphOnly,
      setSyncFilters,
      getGraphData,
      setFilters,
    } = this.props;

    if (urlActiveSyncFilters) {
      setSyncFilters(urlActiveSyncFilters);
      getGraphData(urlActiveFilters, optionsData);
    }

    if (urlActiveFilters) {
      !graphOnly && setFilters(urlActiveFilters);
      getGraphData(urlActiveFilters || {}, optionsData);
    } else if (activeFilters.length > 0) {
      getGraphData(activeFilters, optionsData);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeFilters: prevActiveFilters,
      urlActiveFilters: prevUrlActiveFilters,
      optionsData: prevOptionsData,
    } = prevProps;

    const {
      urlActiveFilters,
      activeFilters,
      optionsData,
      setFilters,
      getGraphData,
    } = this.props;

    if (
      JSON.stringify(prevUrlActiveFilters) !== JSON.stringify(urlActiveFilters)
    ) {
      if (urlActiveFilters) {
        setFilters(urlActiveFilters);
        getGraphData(urlActiveFilters, optionsData);
      }
    }

    if (JSON.stringify(prevActiveFilters) !== JSON.stringify(activeFilters)) {
      if (activeFilters) {
        getGraphData(activeFilters, optionsData);
      }
    }

    if (JSON.stringify(prevOptionsData) !== JSON.stringify(optionsData)) {
      if (optionsData) {
        getGraphData(activeFilters, optionsData);
      }
    }
  }

  /**
   * Update active filters in route params
   *
   * @memberof GenericView
   */
  updateFilters = (index, values, fieldName) => {
    const { activeFilters, activeSyncFilters, updateRoute, location } =
      this.props;
    let newFilters = [...activeFilters];
    newFilters[index] = values;
    if (activeSyncFilters && activeSyncFilters[fieldName] === true) {
      newFilters = newFilters.map(filter => ({
        ...filter,
        [fieldName]: newFilters[index][fieldName],
      }));
    }
    updateRoute(location, { activeFilters: newFilters });
  };

  addVisualization = () => {
    const { activeFilters, updateRoute, location } = this.props;
    const filters = [...activeFilters];
    filters.push(filters[filters.length - 1]);
    updateRoute(location, { activeFilters: filters });
  };

  /**
   * Remove a serie of the current filters
   * and update the route with the new filters
   *
   * @param {number} index the index of the filter to be removed
   *
   * @memberOf GenericView
   */
  removeVisualization = index => {
    const { activeFilters, updateRoute, location } = this.props;
    const filters = [...activeFilters];
    filters.splice(index, 1);
    updateRoute(location, { activeFilters: filters });
  };
}

export default GenericView;
