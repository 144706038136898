import React from 'react';
import ReactTooltip from 'react-tooltip';

import './ModalButton.scss';
import WarningModal from './WarningModal';

class WarningButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalWarningIsOpen: false,
    };
    this.validation = this.validation.bind(this);
  }

  toggleWarningModal = () => {
    this.setState(state => ({
      modalWarningIsOpen: !state.modalWarningIsOpen,
    }));
  };

  validation(updatedAlert) {
    const { AlerteBulletin } = this.props;
    AlerteBulletin(updatedAlert);
  }

  render() {
    const {
      loadingAlertComment,
      getAlertComment,
      bulletin,
      stateBulletinIsValidated,
      comment,
    } = this.props;

    const { modalWarningIsOpen } = this.state;

    const bulletinIsValidated =
      bulletin.isValidated || stateBulletinIsValidated;

    const cannotValidateMsg =
      "The bulletin has already been validated today. You can't send an alert if the bulletin is validated.";
    return (
      <div className="validationBlock">
        <div
          className="modal-zone"
          data-tip={bulletinIsValidated ? cannotValidateMsg : ''}
          data-for="cannot_validate"
        >
          <button
            type="button"
            className="modal-button danger"
            data-cy="warning-button"
            onClick={() => this.toggleWarningModal()}
            disabled={bulletinIsValidated}
          >
            Warning
          </button>
          <ReactTooltip
            id="cannot_validate"
            effect="solid"
            delayHide={1500}
            className="smalltooltip"
          />
          <WarningModal
            show={modalWarningIsOpen}
            closeAction={this.toggleWarningModal}
            getAlertComment={getAlertComment}
            bulletin={bulletin}
            loadingAlertComment={loadingAlertComment}
            warningAlert={this.validation}
            comment={comment}
          />
        </div>
      </div>
    );
  }
}

export default WarningButton;
