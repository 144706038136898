import React from 'react';
import DatePicker from 'react-datepicker';
import { useParams, useNavigate } from 'react-router-dom';
import { EnvContext } from '../../context/Env';
import { useBulletinDateListQuery } from '../../services/api';
import './BulletinDatePicker.scss';

const BulletinDatePicker = ({ endDate }) => {
  const { bulletinDate, reportId, variableId = reportId } = useParams();
  const navigate = useNavigate();
  const {
    env: { BULLETIN_DEFAULT_DATE },
  } = EnvContext();

  const { data: includeDates } = useBulletinDateListQuery();

  const handleChange = nextDate => {
    const code = reportId ? 'r' : 'v';
    const formatedDate = nextDate.toISOString().split('T')[0];
    navigate(`/bulletin/${formatedDate}/${code}/${variableId}`);
  };
  if (!endDate) {
    return null;
  }
  return (
    <div className="bulletinDatePicker">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="bulletinDateInput" className="bulletinDatePicker-label">
        Change the bulletin date
      </label>
      <DatePicker
        id="bulletinDateInput"
        className="date-picker bulletinDatePicker-input"
        selected={new Date(bulletinDate)}
        dateFormat="yyyy/MM/dd"
        selectsStart
        showMonthDropdown
        showYearDropdown
        maxDate={new Date(endDate.replace(' at ', ' '))}
        dropdownMode="select"
        onChange={handleChange}
        popperPlacement="bottom-center"
        includeDates={includeDates}
      >
        {bulletinDate !== BULLETIN_DEFAULT_DATE && (
          <div>
            <button
              type="button"
              className="Button-begin-end"
              onClick={() => handleChange(new Date(BULLETIN_DEFAULT_DATE))}
            >
              Go to latest bulletin
            </button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

export default BulletinDatePicker;
