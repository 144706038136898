const getInSituFilters = state => {
  if (
    state.availableFilters.data === null ||
    state.availableFilters.data.systems === undefined
  ) {
    return null;
  }
  const dateRange = [
    state.availableFilters.data.begin,
    state.availableFilters.data.end,
  ];
  const data = {};
  data.systems = state.availableFilters.data.systems.map(system => {
    let filters = system.filters.slice();
    filters = filters.filter(filter => filter.name !== 'level');
    filters = filters.map(filter => {
      const newfilter = { ...filter };
      if (filter.name === 'source') {
        newfilter.values = newfilter.values.filter(value => value.in_situ);
      }
      return newfilter;
    });
    filters.push({ name: 'date', values: dateRange });
    return {
      pk: system.pk,
      name: system.name,
      filters,
      default: system.default,
      stattype: system.stattype,
    };
  });
  data.stattypes = state.availableFilters.data.stattypes;
  return data;
};

export default getInSituFilters;
