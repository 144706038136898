import React, { useState, useEffect, useCallback, useRef } from 'react';
import Plotly from 'plotly.js/lib/core';
import plotlyScatter from 'plotly.js/lib/scatter';
import plotlyBar from 'plotly.js/lib/bar';
import ChartExportModal from './ChartExportModal';
import { setAlphaToRGBColor, slugify } from './helpers';
import usePrevious from '../../../hooks/usePrevious';

import Loader from '../../Loader/Loader';
import './GenericGraph.scss';

const UNCERTAINTIES_LABEL = 'Uncertainties';

Plotly.register(plotlyScatter);
Plotly.register(plotlyBar);

const defaultLayout = {
  margin: {
    t: 30,
    r: 60,
    b: 100,
    l: 60,
    pad: 0,
  },
  xaxis: {
    type: 'date',
    fixedrange: true,
  },
  yaxis: {
    fixedrange: true,
    overlaying: 'y2',
  },
  hovermode: 'closest',
  legend: {
    orientation: 'h',
  },
};

const defaultOption = {
  displayModeBar: false,
  scrollZoom: false,
  showLink: false,
  displaylogo: false,
};

const isData = (availableFilters, activeFilters, index) => {
  let result = false;
  availableFilters &&
    availableFilters.systems.forEach(system => {
      if (system.pk === parseInt(activeFilters[index].system, 10)) {
        system.filters.forEach(filter => {
          if (filter.name === 'stat') {
            filter.values.forEach(value => {
              if (
                value.pk === parseInt(activeFilters[index].stat, 10) &&
                value.name === 'DATA'
              ) {
                result = true;
              }
            });
          }
        });
      }
    });
  return result;
};

// if it's matching with previous Graph we want only one AxisY for all of them
const indexFromMatchingPreviousGraph = (filters, index) => {
  const currentActiveFilter = filters[index];
  return filters
    .slice(0, index)
    .findIndex(
      ({ StatType, Property }) =>
        currentActiveFilter.StatType === StatType ||
        currentActiveFilter.Property === Property,
    );
};

const buildYlayout = ({
  YSeriesCount,
  YAxisUnit,
  legends,
  showMultipleYAxis,
  colors,
  fixedrange,
  xAxisDomain,
  yAxisWidthPercentage,
  isOMI,
}) =>
  Array.from({ length: YSeriesCount }).reduce((list, _, index) => {
    const unitIndex = YAxisUnit.findIndex(item => item === legends[index]);
    const side = unitIndex % 2 === 0 ? 'left' : 'right';
    const yaxisIndex = `yaxis${index > 0 ? index + 1 : ''}`;
    const font = isOMI ? '"Lato", "sans-serif"' : '"Arial", "sans-serif"';
    if (showMultipleYAxis) {
      // eslint-disable-next-line no-param-reassign
      list[yaxisIndex] = {
        title: YAxisUnit[unitIndex] ?? '',
        titlefont: {
          family: font,
          color: colors[index % colors.length],
        },
        tickfont: {
          family: font,
          color: colors[index % colors.length],
        },
        side,
        showgrid: false,
        anchor: 'x',
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      list[yaxisIndex] = {
        visible: false,
        showgrid: false,
        fixedrange,
      };
    }
    if (index > 0) {
      // eslint-disable-next-line no-param-reassign
      list[yaxisIndex].overlaying = 'y';
    }
    if (unitIndex > 1) {
      // eslint-disable-next-line no-param-reassign
      list[yaxisIndex].anchor = 'free';
      // eslint-disable-next-line no-param-reassign
      list[yaxisIndex].position =
        side === 'left'
          ? xAxisDomain[0] - Math.floor(unitIndex / 2) * yAxisWidthPercentage
          : xAxisDomain[1] + Math.floor(unitIndex / 2) * yAxisWidthPercentage;
    }
    return list;
  }, {});

const getValuesToYAxis = (count, width) => {
  const yAxisWidthPx = 60; // ~ Width of the axis and its title
  const yAxisWidthPercentage = yAxisWidthPx / width;

  // With one axe we give space on the left first
  // With two axes we give spave on the left + right
  // With three axes we give spave on the left x 2 + right
  // and so on

  // For the first legends on each side (1st left, 1st right),
  // it is already taken care of be Poltly. We do not need to take space
  const startIndexAxis = 3;
  let leftSpacesToYAxis = 0;
  let rightSpacesToYAxis = 0;

  if (count >= startIndexAxis) {
    const index = count + 1 - startIndexAxis;
    leftSpacesToYAxis = yAxisWidthPercentage * Math.round(index / 2);
    rightSpacesToYAxis = yAxisWidthPercentage * Math.floor(index / 2);
  }

  return {
    xAxisDomain: [leftSpacesToYAxis, 1 - rightSpacesToYAxis],
    yAxisWidthPercentage,
  };
};

const getUncertaintiesToDisplay = (
  filters,
  {
    continuous_uncertainties: continuousUncertainties,
    dates,
    uncertainties = [],
  },
  colors,
) => {
  const uncertaintiesToDisplay = {
    continuousUncertainties: [],
    unContinuousUncertainties: [],
  };
  filters.forEach(({ DisplayUncertainties, hidden }, index) => {
    const currentUncertainties = uncertainties[index];
    if (!DisplayUncertainties || !currentUncertainties) {
      return;
    }
    if (currentUncertainties.filter(Boolean).length === 0) {
      return;
    }
    const x = [...dates, ...dates.slice().reverse()].filter(
      (_, i) => currentUncertainties[i] !== null,
    );
    const y = currentUncertainties.filter(Number);
    if (continuousUncertainties[index]) {
      const previousIndex = indexFromMatchingPreviousGraph(filters, index);
      const yaxisIndex = previousIndex > -1 ? previousIndex : index;
      const yaxis = `y${yaxisIndex > 0 ? yaxisIndex + 1 : ''}`;
      uncertaintiesToDisplay.continuousUncertainties.push({
        x,
        y,
        fill: 'toself',
        fillcolor: setAlphaToRGBColor(colors[index], 0.2),
        line: { color: 'transparent' },
        showlegend: true,
        name: UNCERTAINTIES_LABEL,
        type: 'scatter',
        yaxis,
        // comment to ungroup legend by yaxis (effect is legend items are stacked vertically)
        legendgroup: yaxis,
        visible: hidden ? 'legendonly' : true,
        hoverinfo: 'skip',
      });
      return;
    }
    uncertaintiesToDisplay.unContinuousUncertainties.push({
      index,
      error_y: {
        type: 'data',
        array: y,
        visible: true,
      },
    });
  });
  return uncertaintiesToDisplay;
};

const RangeGraph = ({
  data,
  layout,
  options,
  activeFilters,
  availableFilters,
  color,
  statisticDisplay,
  dateRange,
  isExportable,
  handleCSVExport,
  loading,
  showMultipleYAxis = true,
  graphOnly = false,
  statsStyles,
  isOMI = false,
  updateActiveFilters = () => {},
}) => {
  const [currentLayout, setLayout] = useState({});
  const [currentData, setData] = useState([]);
  const [currentOptions, setOptions] = useState({});

  const [chartExportModalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(boolean => !boolean);
  };

  const hiddenGraphElement = useRef(null);
  const graphElement = useRef(null);

  const initGraph = useCallback(() => {
    const { dates, labels, legends = [], stats, title, values } = data;

    const fixedrange = !graphOnly;

    const colors = activeFilters.map(
      ({ Color }, index) => Color || color[index],
    );

    const { continuousUncertainties, unContinuousUncertainties } =
      getUncertaintiesToDisplay(activeFilters, data, colors);
    const nextData = values.map((set, index) => {
      const previousIndex = indexFromMatchingPreviousGraph(
        activeFilters,
        index,
      );
      const yaxisIndex = previousIndex > -1 ? previousIndex : index;
      const yaxis = `y${yaxisIndex > 0 ? yaxisIndex + 1 : ''}`;
      const newSet = {
        type: 'scatter',
        yaxis,
        // comment to ungroup legend by yaxis (effect is legend items are stacked vertically)
        legendgroup: yaxis,
        x: dates.filter((_, i) => set[i] !== null),
        y: set.filter(Number),
        name: labels[index] || title,
        marker: {
          color: colors[index % colors.length],
        },
        showline: false,
        visible: activeFilters[index].hidden ? 'legendonly' : true,
      };

      const unContinuousUncertainty = unContinuousUncertainties.find(
        item => item.index === index,
      );

      if (unContinuousUncertainty) {
        newSet.error_y = unContinuousUncertainty.error_y;
      }

      // If stat is DATA
      if (isData(availableFilters, activeFilters, index)) {
        newSet.type = 'bar';
        newSet.yaxis = 'y2';
        newSet.marker = {
          color: `rgba(0, 0, 0, ${0.2 * (index + 1)})`,
        };
      }

      return newSet;
    });

    if (continuousUncertainties.length) {
      continuousUncertainties.forEach(uncertainty =>
        nextData.push(uncertainty),
      );
    }

    statisticDisplay &&
      stats &&
      stats.forEach((stat, index) => {
        Object.keys(stat).forEach(key => {
          statisticDisplay[key] === true &&
            nextData.push({
              type: 'scatter',
              mode: 'lines',
              yaxis: isData(availableFilters, activeFilters, index)
                ? 'y2'
                : 'y',
              x: [dates[0], dates[dates.length - 1]],
              y: [stat[key], stat[key]],
              name: `${labels[index]} (${key})`,
              line: {
                dash: statsStyles[key].dash,
                color: colors[index % colors.length],
                width: statsStyles[key].width,
              },
            });
        });
      });

    const dateRangeNoData = dateRange
      ? {
          xaxis: {
            range: [dateRange.start, dateRange.end],
            type: 'date',
            fixedrange,
          },
        }
      : {};

    // Filter to remove `statisticDisplay` and `continuousUncertainties`
    // and determine the real count of Y axis
    const YSeriesCount = nextData.filter(
      ({ line, fill }) => !line && !fill,
    ).length;

    // check if units of data series are the same or not
    // if yes, they will share the same scale
    const YAxisUnit = [...new Set(legends)];
    const YAxisCount = YAxisUnit.length;

    const { xAxisDomain, yAxisWidthPercentage } = getValuesToYAxis(
      YAxisCount,
      graphElement.current.clientWidth,
    );

    const YLayout = buildYlayout({
      YSeriesCount,
      YAxisUnit,
      legends,
      showMultipleYAxis,
      colors,
      fixedrange,
      xAxisDomain,
      yAxisWidthPercentage,
      isOMI,
    });

    const nextLayout = {
      ...(isOMI && graphOnly && { font: { family: 'Lato', size: 15 } }),
      ...(!(isOMI && graphOnly) && { title }),
      ...defaultLayout,
      ...dateRangeNoData,
      ...layout,
      ...YLayout,
      titleGraph: title,
    };

    // Decrease the width of the graph to let some spaces to Y axis
    // (it is not taken into account if added in nextLayout before (to investigate)
    nextLayout.xaxis.domain = xAxisDomain;

    const nextOptions = {
      ...defaultOption,
      ...options,
    };

    Plotly.newPlot(graphElement.current, nextData, nextLayout, nextOptions);

    graphElement.current.on(
      'plotly_legendclick',
      ({ data: legendData, curveNumber }) => {
        const { visible, legendgroup, name } = legendData[curveNumber];
        const update = {
          visible: visible === 'legendonly' ? true : 'legendonly',
        };
        let relatedUncertaintiesIndex = -1;
        if (name !== UNCERTAINTIES_LABEL) {
          relatedUncertaintiesIndex = legendData.findIndex(
            item =>
              item.legendgroup === legendgroup &&
              item.name === UNCERTAINTIES_LABEL,
          );
        }
        const indexes = [curveNumber, relatedUncertaintiesIndex].filter(
          num => num > -1,
        );
        Plotly.restyle(graphElement.current, update, indexes);
        if (legendData[curveNumber].name !== UNCERTAINTIES_LABEL) {
          updateActiveFilters(
            {
              ...activeFilters[curveNumber],
              hidden: visible !== 'legendonly',
            },
            curveNumber,
          );
        }
        return false;
      },
    );

    setData(nextData);
    setLayout(nextLayout);
    setOptions(nextOptions);
  }, [
    data,
    graphOnly,
    activeFilters,
    statisticDisplay,
    dateRange,
    showMultipleYAxis,
    isOMI,
    layout,
    options,
    color,
    availableFilters,
    statsStyles,
    updateActiveFilters,
  ]);

  const prevData = usePrevious(data);
  const prevDateRange = usePrevious(dateRange);
  const prevLayout = usePrevious(layout);
  const prevOptions = usePrevious(options);

  const noData = !loading && (!data || (Array.isArray(data) && !data.length));

  const resizeListener = useCallback(() => {
    initGraph();
  }, [initGraph]);

  useEffect(() => {
    global.addEventListener('resize', resizeListener);
    return () => {
      global.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener]);

  useEffect(() => {
    if (
      data?.values?.length &&
      (prevData !== data ||
        prevLayout !== layout ||
        prevDateRange !== dateRange ||
        prevOptions !== options)
    ) {
      initGraph();
    }
  }, [
    data,
    dateRange,
    initGraph,
    layout,
    options,
    prevData,
    prevDateRange,
    prevLayout,
    prevOptions,
    statisticDisplay,
  ]);

  // delete previous chart if there is no data available
  useEffect(() => {
    if (noData && graphElement.current) {
      Plotly.purge(graphElement.current);
    }
  }, [noData]);

  const exportPNG = useCallback(
    title => {
      const nextTitle = title ?? currentLayout.titleGraph;
      const hasSeaLevel = (nextTitle || data.labels.join()).includes(
        'Sea Level',
      );
      const hasArcticsOrAntarcticOceans = (
        nextTitle || data.labels.join()
      ).includes('rctic Ocean');

      const hasOneCurve = data?.values?.length === 1;

      const nextData = currentData.map(item => {
        if (item.name.length > (hasOneCurve ? 300 : 90)) {
          return {
            ...item,
            name: item.name.split(' - ').join('<br />'),
          };
        }
        return item;
      });

      const imagesForExports = [
        {
          x: 1.05,
          y: hasOneCurve ? -0.3 : -0.15,
          sizex: 0.6,
          sizey: 0.6,
          source: '/exports/cartouche-EU-2021-color.png',
          xanchor: 'right',
          yanchor: 'bottom',
          xref: 0,
          yref: 900,
        },
        {
          x: hasOneCurve ? 0.42 : 1.04,
          y: hasOneCurve ? -0.3 : -0.275,
          sizex: 0.12,
          sizey: 0.12,
          source: '/exports/PoissonCopernicus_blueWEBTransparent.png',
          xanchor: 'right',
          yanchor: 'bottom',
          xref: 0,
          yref: 900,
        },
      ];
      if (hasSeaLevel) {
        imagesForExports.push({
          x: hasOneCurve ? 0.29 : 0.82,
          y: hasOneCurve ? -0.33 : -0.31,
          sizex: 0.16,
          sizey: 0.16,
          source: '/exports/C3S–POS–LINE.png',
          xanchor: 'right',
          yanchor: 'bottom',
          xref: 0,
          yref: 900,
        });
      }
      if (hasArcticsOrAntarcticOceans) {
        imagesForExports.push({
          x: hasOneCurve ? 0.1 : 0.455,
          y: hasOneCurve ? -0.33 : -0.31,
          sizex: 0.16,
          sizey: 0.16,
          source: '/exports/osisaf.png',
          xanchor: 'left',
          yanchor: 'bottom',
          xref: 0,
          yref: 900,
        });
      }
      const nextLayout = {
        ...(title && {
          title: {
            text: title,
            x: 0.02,
          },
        }),
        ...currentLayout,
        font: {
          ...currentLayout.font,
          // Issue using custom font for chart export https://github.com/plotly/plotly.js/issues/4885
          // family: isOMI && graphOnly ? 'Lato' : 'Roboto',
          family: 'sans-serif',
          size: 15,
        },
        margin: {
          ...currentLayout.margin,
          t: 100,
          b: isOMI && graphOnly ? 190 : 30,
        },
        // Add images at export (for isOMI && graphOnly)
        ...(isOMI &&
          graphOnly && {
            images: imagesForExports,
            legend: {
              orientation: 'v',
              x: -0.1,
              y: -0.05,
              yanchor: 'top',
            },
          }),
      };

      Plotly.newPlot(
        hiddenGraphElement.current,
        nextData,
        nextLayout,
        currentOptions,
      ).then(plotlyElement => {
        const plotlyElementWithCustomTitle = Object.assign(plotlyElement, {
          layout: {
            ...plotlyElement.layout,
            title: { text: nextTitle },
          },
        });
        return Plotly.downloadImage(plotlyElementWithCustomTitle, {
          filename:
            slugify(`moniqua ${nextTitle || data.labels.join()}`) ??
            'moniqua-time-serie',
          format: 'png', // also can use 'jpeg', 'webp', 'svg'
          height: 900,
          width: 1400,
        });
      });
    },
    [
      currentData,
      currentLayout,
      currentOptions,
      data?.labels,
      data?.values,
      graphOnly,
      isOMI,
    ],
  );

  const exportCSV = useCallback(async () => {
    if (typeof handleCSVExport === 'function') {
      const CSV = await handleCSVExport(activeFilters);
      if (CSV) {
        const file = new Blob([CSV], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = `${currentLayout?.title?.text || 'CSVExport'}.csv`;
        link.href = global.URL.createObjectURL(file);
        link.click();
      }
    }
  }, [activeFilters, currentLayout, handleCSVExport]);

  const triggerExport = () => {
    if (!graphOnly) {
      toggleModal();
    } else {
      exportPNG();
    }
  };

  return (
    <div className="graph-container">
      <div ref={graphElement} className="graph">
        {(isExportable || handleCSVExport) && (
          <div className="export-data">
            {isExportable && (
              <button
                type="button"
                className="filters-button"
                onClick={triggerExport}
              >
                Export PNG
              </button>
            )}
            {handleCSVExport && (
              <button
                type="button"
                className="filters-button"
                onClick={exportCSV}
              >
                Export CSV
              </button>
            )}
          </div>
        )}
      </div>
      <div ref={hiddenGraphElement} className="hiddenGraph" />
      {loading && <Loader />}
      {noData && <div>No data</div>}
      {chartExportModalIsOpen && (
        <ChartExportModal
          closeAction={toggleModal}
          exportPNG={exportPNG}
          title={data.title}
        />
      )}
    </div>
  );
};

export default RangeGraph;
