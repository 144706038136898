import {
  LOAD_INSITU_GRAPH,
  RECEIVE_INSITU_GRAPH,
  RECEIVE_INSITU_GRAPH_DATA,
} from './actions';

export const defaultState = {
  data: null,
  data2: null,
  loading: false,
};

export const inSituGraphData = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_INSITU_GRAPH:
      return {
        ...state,
        data: null,
        loading: true,
      };

    case RECEIVE_INSITU_GRAPH:
      return {
        ...state,
        data: action.data,
        loading: false,
      };

    case RECEIVE_INSITU_GRAPH_DATA:
      return {
        ...state,
        data2: action.data,
        loading: false,
      };

    default:
      return state;
  }
};
