export const defaultState = {
  // each marker is a double color, first for main graph, other for stats graph
  markers: [
    ['#000', '#666'],
    ['#F00', '#B00'],
    ['#0000FF', '#0000FF'],
    ['#FF00FF', '#FF00FF'],
    ['#00FF00', '#00FF00'],
    ['#FF5733', '#FF5733'],
    ['#00FFFF', '#00FFFF'],
    ['#808080', '#808080'],
    ['#800000', '#800000'],
    ['#800080', '#800080'],
    ['#008080', '#008080'],
  ],
  scale: {
    blueRed_greycentered: [
      ['0.0', 'rgb(49,54,149)'],
      ['0.09', 'rgb(69,117,180)'],
      ['0.18', 'rgb(116,173,209)'],
      ['0.27', 'rgb(171,217,233)'],
      ['0.36', 'rgb(224,243,248)'],
      ['0.45', 'rgb(201,201,201)'],
      ['0.54', 'rgb(201,201,201)'],
      ['0.63', 'rgb(255,251,115)'],
      ['0.72', 'rgb(255,128,0)'],
      ['0.81', 'rgb(244,109,67)'],
      ['0.90', 'rgb(215,48,39)'],
      ['1.0', 'rgb(165,0,38)'],
    ],
    redBlue: [
      ['0.0', 'rgb(165,0,38)'],
      ['0.111111111111', 'rgb(215,48,39)'],
      ['0.222222222222', 'rgb(244,109,67)'],
      ['0.333333333333', 'rgb(253,174,97)'],
      ['0.444444444444', 'rgb(254,224,144)'],
      ['0.555555555556', 'rgb(224,243,248)'],
      ['0.666666666667', 'rgb(171,217,233)'],
      ['0.777777777778', 'rgb(116,173,209)'],
      ['0.888888888889', 'rgb(69,117,180)'],
      ['1.0', 'rgb(49,54,149)'],
    ],
    std: [
      ['0.0', 'rgb(219,219,255)'],
      ['0.09', 'rgb(169,169,255)'],
      ['0.18', 'rgb(104,104,255)'],
      ['0.27', 'rgb(102,178,255)'],
      ['0.36', 'rgb(51,255,255)'],
      ['0.45', 'rgb(153,255,255)'],
      ['0.54', 'rgb(153,255,204)'],
      ['0.63', 'rgb(102,255,102)'],
      ['0.72', 'rgb(255,255,153)'],
      ['0.81', 'rgb(255,255,51)'],
      ['0.90', 'rgb(255,128,0)'],
      ['1.0', 'rgb(165,0,38)'],
    ],
    blueRed: [
      ['0.0', 'rgb(49,54,149)'],
      ['0.111111111111', 'rgb(69,117,180)'],
      ['0.222222222222', 'rgb(116,173,209)'],
      ['0.333333333333', 'rgb(171,217,233)'],
      ['0.444444444444', 'rgb(224,243,248)'],
      ['0.555555555556', 'rgb(254,224,144)'],
      ['0.666666666667', 'rgb(253,174,97)'],
      ['0.777777777778', 'rgb(244,109,67)'],
      ['0.888888888889', 'rgb(215,48,39)'],
      ['1.0', 'rgb(165,0,38)'],
    ],
  },
  minMax: {
    temp: [-0.25, 0.25, 0.025, 0, 1.0, 0.05],
    sal: [-0.1, 0.1, 0.005, 0, 0.3, 0.01],
  },
  // https://plot.ly/javascript/reference/#scatter-line-dash
  stats: {
    highPercentile: { dash: 'dot', width: 1 },
    lowPercentile: { dash: 'dot', width: 1 },
    median: { dash: 'solid', width: 2 },
    mean: { dash: 'dash', width: 1 },
    lowTukey: { dash: 'dash', width: 1 },
    highTukey: { dash: 'dash', width: 1 },
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
