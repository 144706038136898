import APIService from '../../services/api';
import { setError } from '../error/actions';
import { logoutUser } from '../connection/actions';

export const SET_VARIABLE_ID_TO_DISPLAY = 'SET_VARIABLE_ID_TO_DISPLAY';
export const SET_REPORT_ID_TO_DISPLAY = 'SET_REPORT_ID_TO_DISPLAY';
export const LOAD_SYSTEMS = 'LOAD_SYSTEMS';
export const RECEIVED_DATA = 'RECEIVED_DATA';
export const NO_DATA = 'NO_DATA';
export const LOADING = 'LOADING';
export const LOADING_COMMENT = 'LOADING_COMMENT';

export const loadBulletin = () => ({
  type: LOAD_SYSTEMS,
});

export const receivedData = data => ({
  type: RECEIVED_DATA,
  data,
});

export const noData = data => ({
  type: NO_DATA,
  data,
});

export const setVariableIdToDisplay = id => ({
  type: SET_VARIABLE_ID_TO_DISPLAY,
  id,
});

export const setReportIdToDisplay = id => ({
  type: SET_REPORT_ID_TO_DISPLAY,
  id,
});

export const loading = () => ({
  type: LOADING,
});

export const loadingComment = () => ({
  type: LOADING_COMMENT,
});

const handleTokenRefreshFail = (err, dispatch) => {
  if (
    err?.message.includes('Unable to refresh token') ||
    err?.message.includes('Please login again')
  ) {
    dispatch(logoutUser());
  }
};

export const getBulletin = date => (dispatch, getState) => {
  dispatch(loadBulletin());
  return APIService.getBulletin(date)
    .then(({ systems, bulletin }) => {
      const { variableIdToDisplay, reportIdToDisplay } = getState().bulletin;
      dispatch(
        receivedData({
          systems,
          bulletin,
        }),
      );
      dispatch(setVariableIdToDisplay(variableIdToDisplay));
      dispatch(setReportIdToDisplay(reportIdToDisplay));
    })
    .catch(err => {
      if (err.status === 404) {
        dispatch(noData());
      } else {
        dispatch(setError(`Issue while fetching data: ${err}`));
      }
    });
};

export const getBulletinComment = date => dispatch => {
  dispatch(loadingComment());
  return APIService.getBulletinGeneratedComment(date)
    .then(({ comment }) => {
      dispatch(
        receivedData({
          bulletinComment: comment,
        }),
      );
    })
    .catch(err => dispatch(setError(`Issue while fetching data: ${err}`)));
};

export const getVariable = (id, date) => (dispatch, getState) => {
  dispatch(loading());
  return APIService.getVariable(id, date)
    .then(variableToDisplay => {
      if (variableToDisplay === null) {
        throw new Error(
          'No data for this selection. Maybe you should contact your administrator.',
        );
      }
      const { variableIdToDisplay } = getState().bulletin;
      dispatch(
        receivedData({
          variableToDisplay,
        }),
      );
      dispatch(setVariableIdToDisplay(variableIdToDisplay));
    })
    .catch(err => dispatch(setError(`Issue while fetching data ! ${err}`)));
};

export const getReport = id => (dispatch, getState) => {
  dispatch(loading());
  return APIService.getReport(id)
    .then(reportToDisplay => {
      const { reportIdToDisplay } = getState().bulletin;
      dispatch(
        receivedData({
          reportToDisplay,
        }),
      );
      dispatch(setVariableIdToDisplay(reportIdToDisplay));
    })
    .catch(err => dispatch(setError(`Issue while fetching data: ${err}`)));
};
const postError = 'Issue while sending data to the server: ';

export const setMapStatus = mapStatus => dispatch =>
  APIService.postMapStatus(mapStatus).catch(err => {
    handleTokenRefreshFail(err, dispatch);
    dispatch(setError(`${postError}${err}`));
  });

export const setGraphStatus = graphStatus => dispatch =>
  APIService.postGraphStatus(graphStatus).catch(err => {
    handleTokenRefreshFail(err, dispatch);
    dispatch(setError(`${postError}${err}`));
  });

export const validateBulletin = bulletin => dispatch =>
  APIService.postBulletin(bulletin).catch(err => {
    handleTokenRefreshFail(err, dispatch);
    dispatch(setError(`${postError}${err}`));
  });

export const AlerteBulletin = comment => dispatch => {
  dispatch(loadingComment());
  return APIService.postAlertBulletin(comment)
    .then(({ comment: alertComment }) => {
      dispatch(
        receivedData({
          alertComment,
        }),
      );
    })
    .catch(err => {
      handleTokenRefreshFail(err, dispatch);
      dispatch(setError(`Issue while fetching data: ${err}`));
    });
};
