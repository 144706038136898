import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import createRootReducer from './store/createRootReducer';

import App from './App';

import './config.scss';
import './index.scss';
import EnvProvider from './context/Env';
import ApiProvider from './services/ApiProvider';
import { getEnv } from './services/env';

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))),
);

(async () => {
  const env = await getEnv();
  global.env = env;

  if (env !== null && env.INSTANCE_NAME !== 'local') {
    Sentry.init({
      dsn: 'https://9feee9c515c04aadba1424ae404f7ca4@sentry.makina-corpus.net//49',
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: env.INSTANCE_NAME,
      release: `moniqua-front@${env.APP_VERSION}`,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      ...(env.SENTRY_SET_HEADERS_FOR && {
        tracePropagationTargets: [`${env.SENTRY_SET_HEADERS_FOR}`],
      }),
    });
  }

  ReactDOM.render(
    <ApiProvider host={env.API_HOST}>
      <EnvProvider env={env}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {/* place ConnectedRouter under Provider */}
            <App store={store} />
          </ConnectedRouter>
        </Provider>
      </EnvProvider>
    </ApiProvider>,
    document.getElementById('root'),
  );
})();
