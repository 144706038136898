import React from 'react';
import ReactTooltip from 'react-tooltip';

import './ModalButton.scss';
import ValidationModal from './ValidationModal';

class ValidationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalValidationIsOpen: false,
    };
    this.validation = this.validation.bind(this);
  }

  toggleValidationModal = () => {
    this.setState(state => ({
      modalValidationIsOpen: !state.modalValidationIsOpen,
    }));
  };

  isBulletinReadyToSend() {
    // A bulletin is ready to send when all the entries has been seen
    const { entriesStatus } = this.props;
    if (entriesStatus === undefined) {
      return false;
    }

    const someEntriesUnseen = Object.values(entriesStatus).some(
      entry => entry.isUnseen,
    );
    return !someEntriesUnseen;
  }

  validation(updatedBulletin) {
    const { validateBulletin, setStateBulletinIsValidated } = this.props;
    validateBulletin(updatedBulletin);
    setStateBulletinIsValidated(true);
  }

  render() {
    const {
      entriesStatus,
      bulletin,
      getBulletinComment,
      loadingBulletinComment,
      bulletinComment,
      stateBulletinIsValidated,
    } = this.props;

    const { modalValidationIsOpen } = this.state;
    const isBulletinReadyToSend = this.isBulletinReadyToSend();
    const cannotValidateBulletin =
      !bulletin.isValidated && !isBulletinReadyToSend;
    const canValidateBulletin = !bulletin.isValidated && isBulletinReadyToSend;
    // the same button is used to validate the bulletin
    // or access the daily report once the bulletin has been validated
    const isValidationOrReportButtonActive =
      bulletin.isValidated || canValidateBulletin;

    const cannotValidateMsg =
      'You should see all maps before validate the bulletin';

    return (
      <div className="validationBlock">
        <div
          className="modal-zone"
          data-tip={cannotValidateBulletin ? cannotValidateMsg : ''}
          data-for="cannot_validate"
        >
          <button
            type="button"
            data-cy="validation-button"
            className="modal-button"
            onClick={() => this.toggleValidationModal()}
            disabled={!isValidationOrReportButtonActive}
          >
            {stateBulletinIsValidated ? 'Daily report' : 'Validation'}
          </button>
        </div>
        <ReactTooltip
          id="cannot_validate"
          effect="solid"
          delayHide={1500}
          className="smalltooltip"
        />

        {entriesStatus && isBulletinReadyToSend ? (
          <ValidationModal
            show={modalValidationIsOpen}
            closeAction={this.toggleValidationModal}
            canValidate={isBulletinReadyToSend}
            validate={this.validation}
            bulletin={bulletin}
            getBulletinComment={getBulletinComment}
            loadingBulletinComment={loadingBulletinComment}
            isValidated={stateBulletinIsValidated}
            bulletinComment={bulletinComment}
          />
        ) : null}
      </div>
    );
  }
}

export default ValidationButton;
