import React from 'react';

const formatText = text => text.replace(/(?:\r\n|\r|\n)/g, '<br />');

const MapLegendModal = ({
  legend,
  legend_warn: legendWarn,
  legendImage,
  mainImageInLegend,
  showHelpModal,
  toggleHelpModal,
}) => {
  if (!showHelpModal) {
    return null;
  }

  return (
    <div className="modal-container" data-cy="map-legend-modal">
      <div
        className="modal-backdrop"
        role="button"
        tabIndex={0}
        onClick={() => toggleHelpModal()}
        onKeyPress={() => toggleHelpModal()}
        aria-label="Close modal"
      />
      <section className="frontModal legend modal-own-content">
        <p>
          <b>Description: </b>
          {formatText(legend)}
        </p>
        <p>
          <b>What to check: </b>
          {formatText(legendWarn)}
        </p>
        <div className="grid legend map">
          <div data-cy="map-legend-image">
            <b>Image </b>
            {mainImageInLegend}
          </div>
          <div cy="map-legend-reference-image">
            <b>Reference image </b>
            {legendImage}
          </div>
        </div>
        <div className="buttonZone">
          <button
            type="button"
            className="modal-button button-right"
            data-cy="map-legend-modal-close-button"
            onClick={() => toggleHelpModal()}
          >
            Close
          </button>
        </div>
      </section>
    </div>
  );
};

export default MapLegendModal;
