import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import { useOmiExistingFiltersQuery } from '../../../services/api';
import usePrevious from '../../../hooks/usePrevious';

import './OmiFilters.scss';

const getFilters = (
  { child_title: title, child_values: values },
  activeFilters,
) => {
  const id = activeFilters[title];

  let arr = [];

  if (id === undefined && !values) {
    // The crawl of graph filters ended
    // Add `DisplayUncertainties` and `Color` filters
    arr.push(
      {
        title: 'DisplayUncertainties',
        prop: 'displayUncertainties',
        label: 'uncertainties',
        id: activeFilters.DisplayUncertainties || false,
        type: 'checkbox',
      },
      {
        title: 'Color',
        id: activeFilters.Color,
        type: 'color',
      },
    );

    return arr.flat(Infinity);
  }

  const nextValues = values.find(({ pk }) => pk === id) || values[0];

  arr = [
    {
      title,
      id: nextValues.pk,
      values: values.sort((a, b) => a.name.localeCompare(b.name)),
      type: 'select',
    },
  ];

  if (nextValues) {
    arr.push(getFilters(nextValues, activeFilters));
  }

  return arr.flat(Infinity);
};

const OmiFilters = ({
  activeFilters,
  updateActiveFilters,
  hasMoreThanOneFilter,
  onClickRemoveButton,
  indexFilter,
  color: colorFromGraph,
}) => {
  // The use of useRef is due to the fact that we don't have
  // immediately the value of the color graph to set it at initialization
  const colorInputElement = useRef(null);

  const { data: existingFilters } = useOmiExistingFiltersQuery();

  const validateFilter = useCallback(
    (filters = activeFilters) => {
      updateActiveFilters(filters, indexFilter);
    },
    [activeFilters, indexFilter, updateActiveFilters],
  );

  const updateActiveFilterHandler = useCallback(
    ({ target: { checked, name, type, value } }) => {
      let nextValue = value;
      if (type === 'select-one') {
        nextValue = Number(value);
      }
      if (type === 'checkbox') {
        nextValue = checked;
      }
      const nextActiveFilters = {
        ...activeFilters,
        [name]: nextValue,
      };

      validateFilter(nextActiveFilters);
    },
    [activeFilters, validateFilter],
  );

  const color = useMemo(() => {
    const nextColor = activeFilters.Color || colorFromGraph;
    if (colorInputElement.current && nextColor) {
      colorInputElement.current.value = nextColor;
    }
    return nextColor;
  }, [activeFilters, colorFromGraph]);

  const handleResetColor = useCallback(() => {
    validateFilter({
      ...activeFilters,
      Color: colorFromGraph,
    });
  }, [activeFilters, colorFromGraph, validateFilter]);

  const filters = useMemo(() => {
    if (existingFilters) {
      return getFilters({ ...existingFilters, Color: color }, activeFilters);
    }
    return [];
  }, [activeFilters, color, existingFilters]);

  const prevFilters = usePrevious(filters);

  useEffect(() => {
    if (
      JSON.stringify(prevFilters) !== JSON.stringify(filters) &&
      filters.length
    ) {
      const newFilters = filters.reduce(
        (obj, { title, id }) => ({ ...obj, [title]: id }),
        {},
      );
      if (activeFilters.hidden) {
        newFilters.hidden = true;
      }
      validateFilter(newFilters);
    }
  }, [activeFilters, filters, prevFilters, validateFilter]);

  return (
    <tr className="Filters">
      <td>
        {hasMoreThanOneFilter && (
          <button
            type="button"
            onClick={onClickRemoveButton}
            className="filters2-button remove-button2"
          >
            -
          </button>
        )}
      </td>
      {filters.map(filter => (
        <td className="moniqua-select" key={filter.title}>
          {filter.type === 'select' && (
            <select
              name={filter.title}
              value={filter.id}
              onChange={updateActiveFilterHandler}
            >
              {filter.values.map(({ pk, name }) => (
                <option key={pk} value={pk}>
                  {name}
                </option>
              ))}
            </select>
          )}
          {filter.type === 'checkbox' && (
            <span className="Button-checkbox">
              {!existingFilters[filter.prop]?.[activeFilters.Property] ===
              true ? (
                <>
                  <span
                    data-tip=""
                    data-for={`${filter.title}-deactivate-${indexFilter}`}
                    className="icon-info-circle"
                  />
                  <ReactTooltip
                    id={`${filter.title}-deactivate-${indexFilter}`}
                    place="top"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    No {filter.label} available
                  </ReactTooltip>
                </>
              ) : (
                <div className="checkbox">
                  <input
                    id={`${filter.title}-${indexFilter}`}
                    name={filter.title}
                    type="checkbox"
                    defaultChecked={filter.id}
                    onChange={updateActiveFilterHandler}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    htmlFor={`${filter.title}-${indexFilter}`}
                    aria-label={`Activate ${filter.label}`}
                  />
                </div>
              )}
            </span>
          )}
          {filter.type === 'color' && (
            <>
              <input
                type="color"
                id={`${filter.title}-${indexFilter}`}
                name={filter.title}
                defaultValue={filter.id}
                onChange={updateActiveFilterHandler}
                ref={colorInputElement}
              />
              {activeFilters.Color !== colorFromGraph && (
                <>
                  <button
                    data-tip=""
                    data-for={`${filter.title}-deactivate-${indexFilter}`}
                    className="filters-button button-reset-color"
                    type="button"
                    onClick={handleResetColor}
                  >
                    <span
                      role="img"
                      aria-label="Reset to color values set in the back office"
                    >
                      ❌
                    </span>
                  </button>
                  <ReactTooltip
                    id={`${filter.title}-deactivate-${indexFilter}`}
                    place="top"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    Reset to color values set in the back office
                  </ReactTooltip>
                </>
              )}
            </>
          )}
        </td>
      ))}
    </tr>
  );
};

export default memo(OmiFilters);
