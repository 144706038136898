import React from 'react';

import { Link } from 'react-router-dom';

import Card from '../Card/Card';

import './HomePage.scss';

const Homepage = ({ appLinks = [] }) => (
  <div className="main container">
    <div className="homeContent">
      <div className="homegrid">
        {appLinks.map(({ name, description, image, to }) => (
          <Card
            data-cy={`home-card-to-${to.slice(1)}`}
            className="marginLessCard"
            key={name}
          >
            <Link className="unstyledLink" to={to}>
              <div className="picture-container">
                <img
                  src={image}
                  alt={name}
                  role="presentation"
                  className="home-img"
                />
              </div>
              <div className="info-container">
                <h2 className="title">{name?.toUpperCase()}</h2>
                <div className="description">{description}</div>
              </div>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  </div>
);

export default Homepage;
