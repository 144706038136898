import React from 'react';
import Helmet from 'react-helmet';
import { EnvContext } from '../../context/Env';

const ViewTitle = ({ pageTitle = null }) => {
  const {
    env: { INSTANCE_NAME },
  } = EnvContext();

  const instance = INSTANCE_NAME || 'local';
  // do not display instance name if instance is production
  const displayInstanceName = instance.startsWith('prod') ? '' : instance;

  const title = [displayInstanceName, 'MoniQua', pageTitle]
    .filter(Boolean)
    .join(' - ');
  return <Helmet title={title} />;
};

export default ViewTitle;
