import React from 'react';
import Loader from '../Loader/Loader';
import Graph3D from './Graph/3DGraph';
import Filter3D from './Filter/3DFilter';

import FiltersSet from '../FiltersSet/FiltersSet';
import Card from '../Card/Card';
import GenericView from './GenericView';
import ViewTitle from '../ViewTitle/ViewTitle';

class View3D extends GenericView {
  update3DDisplay = (label, value) => {
    this.props.updateRoute(this.props.location, {
      display3D: {
        ...this.props.display3D,
        [label]: value,
      },
    });
  };

  render() {
    const {
      loadedFilters,
      loadingGraphData,
      activeFilters,
      availableFilters,
      graphData,
      // 3D props
      graphTypes,
      colorscale,
      minMax,
      isCentered,
      display3D,
      userMinMax,
      graphOnly,
    } = this.props;

    if (!loadedFilters) return <Loader>Loading filters...</Loader>;

    let FiltersView = <Loader />;
    let GraphView = <Loader />;
    const TZFilterView = (
      <Filter3D
        graphTypes={graphTypes}
        update3DDisplay={this.update3DDisplay}
        display3D={display3D}
      />
    );

    GraphView = (
      <Graph3D
        data={graphData}
        loading={loadingGraphData}
        colorscale={colorscale}
        options={userMinMax}
        minMax={minMax}
        type={display3D.type}
        isExportable
        isCentered={isCentered}
        refString="3d-graph"
      />
    );

    if (loadedFilters) {
      // on 3D view, only one serie is allowed
      // but we keep all filters in URL to keep user selection
      // when going to another page
      const firstActiveFilters = activeFilters?.slice(0, 1);

      FiltersView = (
        <FiltersSet
          filters={firstActiveFilters}
          availableFilters={availableFilters}
          isSyncable={false}
          updateFilters={this.updateFilters}
          // 3D properties
          showDate={false}
          onlyOneFilter
        />
      );
    }

    if (graphOnly) {
      return <div className="GraphView 3d">{GraphView}</div>;
    }

    return (
      <>
        <ViewTitle pageTitle="depth vs time" />
        <div className="GraphView 3d">
          <Card className="graph">{GraphView}</Card>
          <Card className="filters">
            {FiltersView}
            {TZFilterView}
          </Card>
        </div>
      </>
    );
  }
}

export default View3D;
