import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import './Filters.scss';

const Filters = ({
  activeFilters,
  availableFilters,
  onChange,
  activeSyncFilters,
  firstFilter,
  hasMoreThanOneFilter,
  onClickRemoveButton,
  isFirstRow,
  showDate,
  onlyWednesday,
}) => {
  const updateSystem = useCallback(
    evt => {
      const field = evt.target;
      const newFilters = { ...activeFilters };
      newFilters[field.name] = field.value;

      let indexActiveSystem = null;
      let availableSystems = null;

      if (field.name === 'stattype') {
        // The stattype has been updated
        availableSystems = availableFilters.systems.filter(
          system => system.stattype === field.value,
        );
        // then the default system affiliated to the stattype is displayed
        availableSystems.forEach((system, index) => {
          if (system.default) {
            indexActiveSystem = index;
          }
        });
        // if no default system we dislpay the first one in the array
        if (indexActiveSystem === null) {
          indexActiveSystem = 0;
        }
        newFilters.system = availableSystems[indexActiveSystem].pk;
      } else {
        // The system has been updated
        availableSystems = availableFilters.systems;
        availableSystems.forEach((system, index) => {
          if (system.pk === Number(field.value)) {
            indexActiveSystem = index;
          }
        });
      }

      let inSitu = false;
      availableSystems[indexActiveSystem].filters.forEach(filter => {
        let defaultFilterPk;
        if (filter.name === 'date') {
          return;
        }
        filter.values.forEach(value => {
          if (value.default) {
            defaultFilterPk = value.pk;
            if (value.in_situ && value.in_situ === true) {
              inSitu = true;
            }
          }
        });
        if (defaultFilterPk === undefined && filter.values.length > 0) {
          defaultFilterPk = filter.values[0].pk;
        }
        newFilters[filter.name] = defaultFilterPk;
      });
      if (!inSitu) {
        newFilters.level = null;
      }
      // TODO: delete 'level' if not in situ
      onChange(newFilters, field.name);
    },
    [activeFilters, availableFilters.systems, onChange],
  );

  const isWednesday = date => onlyWednesday === true && date.getDay() === 3;

  /* Return true if the selected value for this filter is an in_situ */
  const filterSelectedValueIsInSitu = useCallback(
    ({ name, values }) => {
      if (name === 'date') {
        return false;
      }

      const filterActiveValue = activeFilters[name];
      return values.some(
        ({ in_situ: inSitu, pk }) => inSitu && Number(filterActiveValue) === pk,
      );
    },
    [activeFilters],
  );

  const updateFilter = useCallback(
    ({ target: field }) => {
      const availableSystems = availableFilters.systems.filter(
        system => system.stattype === activeFilters.stattype,
      );

      let indexActiveSystem = null;
      availableSystems &&
        availableSystems.forEach((system, index) => {
          if (system.pk === Number(activeFilters.system)) {
            indexActiveSystem = index;
          }
        });
      if (indexActiveSystem === null) return;

      const newFilters = {
        ...activeFilters,
        [field.name]: Number(field.value),
      };

      const inSitu = availableSystems[indexActiveSystem].filters.reduce(
        (acc, filter) => acc || filterSelectedValueIsInSitu(filter),
        false,
      );

      if (inSitu && newFilters.level === null) {
        availableSystems[indexActiveSystem].filters.forEach(filter => {
          if (filter.name === 'level') {
            filter.values.forEach(value => {
              if (value.default) {
                newFilters.level = value.pk;
              }
            });
            if (newFilters.level === null) {
              newFilters.level = filter.values[0].pk;
            }
          }
        });
      }

      if (!inSitu) {
        newFilters.level = null;
      }

      onChange(newFilters, field.name);
    },
    [
      activeFilters,
      availableFilters.systems,
      filterSelectedValueIsInSitu,
      onChange,
    ],
  );

  /**
   * Only for InSitu Graph ?
   *
   * @memberof Filters
   */
  const updateDate = useCallback(
    date => {
      const newFilters = {
        ...activeFilters,
        date: date.toISOString().substring(0, 10),
      };
      onChange(newFilters, 'date');
    },
    [activeFilters, onChange],
  );

  let availableSystems = null;
  availableFilters.systems &&
    (availableSystems = availableFilters.systems.filter(
      system => system.stattype === activeFilters.stattype,
    ));

  let indexActiveSystem = null;
  const activeSystem = parseInt(activeFilters.system, 10);
  availableSystems &&
    (indexActiveSystem = availableSystems.findIndex(
      system => system.pk === activeSystem,
    ));

  if (indexActiveSystem === null)
    return (
      <tr>
        <td>No current system available</td>
      </tr>
    );

  const inSitu = availableSystems[indexActiveSystem]?.filters.reduce(
    (acc, filter) => acc || filterSelectedValueIsInSitu(filter),
    false,
  );

  return (
    <tr className="Filters">
      <td>
        {hasMoreThanOneFilter && (
          <button
            type="button"
            onClick={onClickRemoveButton}
            className="filters2-button remove-button2"
          >
            -
          </button>
        )}
      </td>
      <td className="moniqua-select" key="stattype">
        <select
          name="stattype"
          value={activeFilters.stattype}
          onChange={updateSystem}
          disabled={activeSyncFilters.stattype && !isFirstRow}
        >
          {availableFilters.stattypes.map(stattype => (
            <option key={stattype.name} value={stattype.name}>
              {stattype.name}
            </option>
          ))}
        </select>
      </td>
      <td className="moniqua-select" key="system">
        <select
          name="system"
          value={activeFilters.system}
          onChange={updateSystem}
          disabled={activeSyncFilters.system && !isFirstRow}
        >
          {availableSystems.map(system => (
            <option key={system.pk} value={system.pk}>
              {system.name}
            </option>
          ))}
        </select>
      </td>
      {availableSystems[indexActiveSystem]?.filters.map(filter => {
        const filterSynced = activeSyncFilters[filter.name];
        const filterValue = activeFilters[filter.name];
        if (filter.name === 'level' && !inSitu) return null;
        if (filter.name === 'system') return null;
        if (filter.name === 'date' && showDate === false) return null;
        if (filter.name === 'date') {
          return (
            <td key={filter.name}>
              <DatePicker
                className="moniqua-select date"
                selected={new Date(filterValue)}
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={filterSynced && !isFirstRow}
                minDate={new Date(filter.values[0])}
                maxDate={new Date(filter.values[1])}
                filterDate={isWednesday}
                onChange={updateDate}
              />
            </td>
          );
        }
        let displaySyncErrorMessage = false;
        if (filterSynced && !isFirstRow) {
          const filterItemName = filter.values.find(
            currentValue => currentValue.pk === filterValue,
          ).name;
          try {
            const firstFilterItemName = availableSystems
              .find(s => s.pk === firstFilter.system)
              .filters.find(f => f.name === filter.name)
              .values.find(v => v.pk === firstFilter[filter.name]).name;
            displaySyncErrorMessage = filterItemName !== firstFilterItemName;
          } catch (e) {
            displaySyncErrorMessage = true;
          }
        }
        return (
          <td className="moniqua-select" key={filter.name}>
            <select
              name={filter.name}
              value={filterValue}
              disabled={filterSynced && !isFirstRow}
              onChange={updateFilter}
            >
              {filter.values.map(value => (
                <option key={value.pk} value={value.pk}>
                  {value.name}
                </option>
              ))}
            </select>
            {displaySyncErrorMessage && <span className="error">Sync nok</span>}
          </td>
        );
      })}
      {
        // missing column if in_situ
        inSitu !== true && <td />
      }
    </tr>
  );
};

export default Filters;
