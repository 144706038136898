import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOmiExistingFiltersQuery } from '../../../services/api';
import OmiFilters from './OmiFilters';

import getNewRoute from '../../../containers/helpers';

import 'bootstrap/dist/css/bootstrap.css';

const getTHColumnsLabel = ({ child_title: title, child_values: values }) => {
  const labels = [title];
  const childValues = values?.[0].child_title;
  if (childValues) {
    labels.push(getTHColumnsLabel(values[0]));
  }
  return labels.flat(Infinity);
};

const OmiFiltersSet = ({ colors, updateActiveFilters }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: existingFilters, isLoading: isExistingFiltersLoading } =
    useOmiExistingFiltersQuery();

  const filters = useMemo(
    () => JSON.parse(new URLSearchParams(location.search).get('activeFilters')),
    [location.search],
  );

  const generateFirstFilter = useCallback(() => {
    const defaultFilter = {};
    defaultFilter[existingFilters.child_title] =
      existingFilters.child_values[0].pk;
    let next = existingFilters.child_values[0];
    while (next.child_title) {
      // browse recursively the first branch of the tree
      defaultFilter[next.child_title] = next.child_values[0].pk;
      [next] = next.child_values;
    }
    return defaultFilter;
  }, [existingFilters]);

  const addNewFilter = useCallback(() => {
    const nextFilters = filters !== null ? [...filters] : [];
    const newFilter = nextFilters.length
      ? nextFilters.slice(-1)[0]
      : generateFirstFilter();
    nextFilters.push(newFilter);
    navigate(getNewRoute(location, { activeFilters: nextFilters }));
  }, [filters, generateFirstFilter, location, navigate]);

  const removeActiveFilter = useCallback(
    filterOrder => {
      const nextFilters = [...filters];
      nextFilters.splice(filterOrder, 1);
      navigate(getNewRoute(location, { activeFilters: nextFilters }));
    },
    [filters, location, navigate],
  );

  useEffect(() => {
    if (existingFilters && filters === null) {
      addNewFilter();
    }
  }, [addNewFilter, existingFilters, filters]);

  const labels = useMemo(() => {
    if (!existingFilters) {
      return [];
    }
    return getTHColumnsLabel(existingFilters);
  }, [existingFilters]);

  if (isExistingFiltersLoading) {
    // todo loading
    return null;
  }

  if (!existingFilters || filters === null)
    return <div>No filters available</div>;

  return (
    <div className="table-responsive">
      <h2>Parameters</h2>
      <table className="table borderless">
        <thead>
          <tr>
            <th label="button" />
            {labels.map(label => (
              <th key={label}>{label}</th>
            ))}
            <th className="moniqua-select-title">Display uncertainties</th>
            <th className="moniqua-select-title">Colors</th>
          </tr>
        </thead>
        <tbody>
          {filters.map((filter, index) => (
            <OmiFilters
              // eslint-disable-next-line react/no-array-index-key
              key={`filters-omi-${index}`}
              indexFilter={index}
              activeFilters={filter}
              onClickRemoveButton={() => removeActiveFilter(index)}
              hasMoreThanOneFilter={filters.length > 1}
              updateActiveFilters={updateActiveFilters}
              color={colors?.[index]}
            />
          ))}
          <tr>
            <td>
              <button
                className="filters2-button add-button2"
                onClick={addNewFilter}
                title="Add new filter"
                type="button"
              >
                +
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OmiFiltersSet;
