// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const jwtToken = 'jwtToken';
const tokenExpiredMsgErrorText = 'Token expired';

class APIHelper {
  static async checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (response.status === 401) {
      const data = await response.json();
      if (data.code === 'token_not_valid') {
        throw new Error(tokenExpiredMsgErrorText);
      } else {
        throw new Error(response.statusText);
      }
    }
    if (response.status === 403) {
      throw new Error(
        "You don't have the access rights to modify the resource",
      );
    }
    if (response.status === 404) {
      throw response;
    }
    throw new Error(response.statusText);
  }

  static parseJSON(response) {
    return response.json();
  }

  static parseText(response) {
    return response.text();
  }

  static getJwtToken = () => JSON.parse(localStorage.getItem(jwtToken));

  static setJwtToken = jwtTokenContent =>
    localStorage.setItem(jwtToken, JSON.stringify(jwtTokenContent));

  static getAccessToken = () => this.getJwtToken()?.access;

  static getRefreshToken = () => this.getJwtToken()?.refresh;

  static removeJwtToken = () => localStorage.removeItem(jwtToken);

  static decodeToken = accessToken => jwt_decode(accessToken);

  static isTokenExpired = accessToken => {
    if (!accessToken) {
      return null;
    }
    const decodedToken = jwt_decode(accessToken);
    if (!decodedToken?.exp) {
      return null;
    }
    return Date.now() >= decodedToken?.exp * 1000;
  };

  static errorIsTokenExpired = message =>
    message === tokenExpiredMsgErrorText ||
    message === `Error: ${tokenExpiredMsgErrorText}`;

  static getUserInfoFromToken = accessToken => {
    const decodedToken = APIHelper.decodeToken(accessToken);
    return {
      username: decodedToken?.username,
      hasBulletinPermission: decodedToken?.permissions?.can_change_bulletin,
    };
  };
}

export default APIHelper;
