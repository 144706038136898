import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Card from '../Card/Card';
import OmiFiltersSet from './Filters/OmiFilterSet';
import Loader from '../Loader/Loader';
import DateSelector from '../DateSelector/DateSelector';
import Trends from '../Trends/Trends';
import { useOmiGraphDataQuery, getOMICSV } from '../../services/api';
import getNewRoute from '../../containers/helpers';

import RangeGraph from '../GraphView/Graph/RangeGraph';
import ViewTitle from '../ViewTitle/ViewTitle';

const OmiView = ({ availableFilters, url }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(url || location.search),
    [location.search, url],
  );

  const activeFilters = useMemo(
    () => JSON.parse(searchParams.get('activeFilters')),
    [searchParams],
  );

  const graphOnly = useMemo(
    () => ['true', ''].includes(searchParams?.get('graphOnly')) || Boolean(url),
    [searchParams, url],
  );

  const { data: graphs, isLoading } = useOmiGraphDataQuery(activeFilters);

  const dateRange = useMemo(() => {
    const { dates } = graphs || {};
    const {
      start = null,
      end = null,
      min = null,
      max = null,
    } = JSON.parse(searchParams.get('dateRange')) || {};
    const nextMin = dates?.[0] || min;
    const nextMax = dates?.[dates.length - 1] || max;
    const nextDateRange = {
      min: nextMin,
      max: nextMax,
      start: start || nextMin,
      end: end || nextMax,
    };

    return nextDateRange;
  }, [graphs, searchParams]);

  const updateDateRange = useCallback(
    (start, end) => {
      const { min, max } = dateRange;
      navigate(
        getNewRoute(location, {
          dateRange: {
            start: start ?? min,
            end: end ?? max,
          },
        }),
      );
    },
    [dateRange, location, navigate],
  );

  const updateActiveFilters = useCallback(
    (filter, filterOrder) => {
      const nextFilters = [...activeFilters];
      nextFilters[filterOrder] = filter;
      navigate(getNewRoute(location, { activeFilters: nextFilters }));
    },
    [activeFilters, location, navigate],
  );

  let GraphView = <Loader>Loading graph data...</Loader>;
  let DateSelectorView = <Loader />;

  if (graphs) {
    GraphView = (
      <RangeGraph
        availableFilters={availableFilters}
        activeFilters={activeFilters}
        dateRange={dateRange}
        data={graphs}
        loading={isLoading}
        color={graphs.colors}
        type="scatter"
        isExportable
        graphOnly={graphOnly}
        handleCSVExport={getOMICSV}
        updateActiveFilters={updateActiveFilters}
        isOMI
      />
    );
    DateSelectorView = (
      <DateSelector
        availableFilters={availableFilters}
        activeFilters={activeFilters}
        graphData={graphs}
        displayGraph
        dateRange={dateRange}
        onChange={updateDateRange}
        color={graphs.colors}
        graphOnly={graphOnly}
        isOMI
      />
    );
  }

  return (
    <>
      <ViewTitle pageTitle="OMI" />
      <div className="GraphView">
        <Card className="graph">
          {GraphView}
          {DateSelectorView}
        </Card>
        {!graphOnly && graphs?.trends && (
          <Card className="trends">
            <Trends graphs={graphs} dateRange={dateRange} />
          </Card>
        )}
        {!graphOnly && (
          <Card className="filters">
            <OmiFiltersSet
              filters={activeFilters}
              updateActiveFilters={updateActiveFilters}
              colors={graphs?.colors}
            />
          </Card>
        )}
      </div>
    </>
  );
};

export default OmiView;
