import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import bulletin from './bulletin/reducer';
import connection from './connection/reducer';
import { error } from './error/reducer';
import {
  availableFilters,
  rangeActiveFilters,
  rangeActiveSyncFilters,
  inSituActiveFilters,
} from './filter/reducer';
import { rangeGraphData } from './graph/range.reducer';
import { inSituGraphData } from './graph/insitu.reducer';
import { ViewGraphData3D } from './graph/3d.reducer';
import { dateRange } from './date/reducer';
import style from './style/reducer';
import type from './type/reducer';

const createRootReducer = history =>
  combineReducers({
    bulletin,
    connection,
    error,
    availableFilters,
    range: combineReducers({
      activeFilters: rangeActiveFilters,
      activeSyncFilters: rangeActiveSyncFilters,
      graphData: rangeGraphData,
    }),
    inSitu: combineReducers({
      activeFilters: inSituActiveFilters,
      graphData: inSituGraphData,
    }),
    View3D: combineReducers({
      activeFilters: inSituActiveFilters,
      graphData: ViewGraphData3D,
    }),
    dateRange,
    router: connectRouter(history),
    style,
    type,
  });

export default createRootReducer;
