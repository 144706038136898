import React from 'react';
import ErrorCard from '../ErrorCard/ErrorCard';
import './ErrorPopup.scss';

const ErrorPopup = ({ errorMessage, dismissError }) => (
  <div className={`error-popup ${errorMessage ? 'active' : null}`}>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div
      className="overlay"
      role="button"
      tabIndex={0}
      onClick={dismissError}
      aria-label="Dismiss error"
    />
    <ErrorCard errorMessage={errorMessage} dismissError={dismissError} />
  </div>
);

export default ErrorPopup;
