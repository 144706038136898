import { LOAD_RANGE_GRAPH, RECEIVE_RANGE_GRAPH } from './actions';

export const defaultState = {
  data: null,
  loading: false,
};

export const rangeGraphData = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_RANGE_GRAPH:
      return {
        data: null,
        loading: true,
      };

    case RECEIVE_RANGE_GRAPH:
      return {
        data: action.data,
        loading: false,
      };

    default:
      return state;
  }
};
