import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import withRouter from '../hooks/withRouter';

import {
  getBulletin,
  getBulletinComment,
  setVariableIdToDisplay,
  setReportIdToDisplay,
  setMapStatus,
  setGraphStatus,
  validateBulletin,
  AlerteBulletin,
  getVariable,
  getReport,
} from '../store/bulletin/actions';
import { getNewRoute } from './helpers';

import BulletinView from '../components/BulletinView/BulletinView';

const mapStateToProps = (state, ownProps) => {
  const { bulletinDate, variableId, reportId } = ownProps.params;
  // If the user doesn't have the required access rights he's considered logout for the bulletin
  const userIsAuthenticated =
    state.connection.userIsAuthenticated &&
    state.connection.hasBulletinPermission;

  return {
    bulletinDate,
    systems: state.bulletin.systems,
    dateRange: state.bulletin.dateRange,
    variableToDisplay: state.bulletin.variableToDisplay,
    variableIdToDisplay: variableId ? Number(variableId) : null,
    noData: state.bulletin.noData,
    bulletin: state.bulletin.bulletin,
    comment: state.bulletin.comment,
    bulletinComment: state.bulletin.bulletinComment,
    alertComment: state.bulletin.alertComment,
    loading: state.bulletin.loading,
    loadingContent: state.bulletin.loadingContent,
    loadingBulletinComment: state.bulletin.loadingBulletinComment,
    reportToDisplay: state.bulletin.reportToDisplay,
    reportIdToDisplay: reportId ? Number(reportId) : null,
    userIsAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  getBulletin: date => dispatch(getBulletin(date)),
  getBulletinComment: date => dispatch(getBulletinComment(date)),
  getVariable: (id, date) => dispatch(getVariable(id, date)),
  getReport: id => dispatch(getReport(id)),
  setVariableIdToDisplay: id => dispatch(setVariableIdToDisplay(id)),
  setReportIdToDisplay: id => dispatch(setReportIdToDisplay(id)),
  setMapStatus: mapStatus => dispatch(setMapStatus(mapStatus)),
  setGraphStatus: graphStatus => dispatch(setGraphStatus(graphStatus)),
  validateBulletin: bulletin => dispatch(validateBulletin(bulletin)),
  AlerteBulletin: comment => dispatch(AlerteBulletin(comment)),
  updateRoute(location, queryName) {
    dispatch(push(getNewRoute(location, queryName)));
  },
});

const BulletinPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulletinView),
);

export default BulletinPage;
