import { useQuery, useQueries } from 'react-query';
import APIHelper from './api.helper';

class APIService {
  BASE_URL = 'http://localhost:8000/api/';

  unableToRefreshTokenMsg = 'Unable to refresh token';

  login(credentials) {
    return this.postValue('token/', credentials, false)
      .then(APIHelper.parseJSON)
      .then(res => {
        const accessToken = res?.access;
        if (accessToken) {
          APIHelper.setJwtToken(res);
          const decodedToken = APIHelper.decodeToken(accessToken);
          return {
            status: 'success',
            username: credentials?.username,
            hasBulletinPermission:
              decodedToken?.permissions?.can_change_bulletin,
          };
        }
        return {
          status: 'ko',
          username: null,
          hasBulletinPermission: false,
        };
      });
  }

  async isUserConnected() {
    const accessToken = APIHelper.getAccessToken();
    const tokenExpired = APIHelper.isTokenExpired(accessToken);
    const isUserConnected = Boolean(accessToken);
    const notConnectedUser = {
      username: null,
      hasBulletinPermission: null,
    };

    if (isUserConnected && !tokenExpired) {
      return APIHelper.getUserInfoFromToken(accessToken);
    }
    if (isUserConnected && tokenExpired) {
      // Try to refresh token once
      try {
        await this.refreshToken();
        return APIHelper.getUserInfoFromToken(accessToken);
      } catch (err) {
        // we do not throw error here, user will be logout in this case
        return notConnectedUser;
      }
    }

    return notConnectedUser;
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    APIHelper.removeJwtToken();
  }

  refreshToken() {
    const url = `${this.BASE_URL}token/refresh/`;
    const data = {
      refresh: APIHelper.getRefreshToken(),
    };

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON)
      .then(res => {
        if (res?.access) {
          const token = APIHelper.getJwtToken();
          const newToken = { ...token, access: res.access };
          APIHelper.setJwtToken(newToken);
        } else {
          throw new Error(this.unableToRefreshTokenMsg);
        }
      })
      .catch(() => {
        throw new Error(this.unableToRefreshTokenMsg);
      });
  }

  postValue(ressourceName, data, withToken = true) {
    const url = `${this.BASE_URL}${ressourceName}`;

    const fetchPost = () =>
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...(withToken && {
            'X-Access-Token': `Bearer ${APIHelper.getAccessToken()}`,
          }),
        },
        body: JSON.stringify(data),
      });

    return fetchPost()
      .then(APIHelper.checkStatus)
      .catch(async err => {
        const tokenIsExpired = APIHelper.errorIsTokenExpired(err?.message);

        // if error is anything but token expired, raise it
        if (!tokenIsExpired) {
          throw new Error(err);
        }

        await this.refreshToken().catch(error => {
          // if the token is expired, try to refresh it
          // Several cases can occur
          // - 401 => the refresh token itself is expired -> ask for reconnection
          // - other error : the user might not be authorized anymore
          // => logout the user for all the cases

          const refreshTokenIsExpired = error?.message.includes(
            this.unableToRefreshTokenMsg,
          );
          const errorMessage = refreshTokenIsExpired
            ? 'Please login again'
            : error?.message;

          throw new Error(errorMessage);
        });

        return fetchPost()
          .then(APIHelper.checkStatus)
          .then(APIHelper.parseJSON);
      });
  }

  getRangeGraphData(filters, optionsData = { percentile: 20 }) {
    const url = `${this.BASE_URL}graph/range?${new URLSearchParams({
      query: JSON.stringify(filters),
      percentile: optionsData.percentile,
    })}`;

    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON)
      .then(res => {
        if (!res || res?.dates?.length < 0 || res?.values?.length < 0) {
          throw new Error('No data available');
        }

        return res;
      });
  }

  getInSituGraphData(params) {
    const url = `${this.BASE_URL}graph/in-situ?${new URLSearchParams({
      query: JSON.stringify(params),
    })}`;

    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  get3DViewGraphData(params) {
    const url = `${this.BASE_URL}graph/3d?${new URLSearchParams({
      query: JSON.stringify(params),
    })}`;

    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON)
      .then(res => {
        if (!res.depths.length || !res.values.length) {
          throw new Error('No data available');
        }

        return res;
      });
  }

  getFiltersData() {
    const url = `${this.BASE_URL}filters/`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  getBulletin(date) {
    const url = `${this.BASE_URL}bulletins/view/${date}`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  getBulletinGeneratedComment(date) {
    const url = `${this.BASE_URL}bulletins/${date}/comment/`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  getVariable(variableId, date) {
    const url = `${this.BASE_URL}maps/v/${variableId}/${date}/`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  getReport(reportId) {
    const url = `${this.BASE_URL}maps/r/${reportId}`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }

  postMapStatus(mapStatus) {
    try {
      return this.postValue('maps/v/', mapStatus);
    } catch (err) {
      throw new Error(err);
    }
  }

  postGraphStatus(graphStatus) {
    try {
      return this.postValue('maps/r/', graphStatus);
    } catch (err) {
      throw new Error(err);
    }
  }

  postBulletin(bulletin) {
    try {
      return this.postValue('bulletins/', bulletin);
    } catch (err) {
      throw new Error(err);
    }
  }

  postAlertBulletin(comment) {
    try {
      return this.postValue('warning/', comment);
    } catch (err) {
      throw new Error(err);
    }
  }

  getAreas(systemId) {
    const url = `${this.BASE_URL}areas/${systemId}/`;
    return fetch(url, { credentials: 'include' })
      .then(APIHelper.checkStatus)
      .then(APIHelper.parseJSON);
  }
}

const getOMIExistingFiltersQuery = () =>
  fetch(`${global.env.API_HOST}omi/filters`, { credentials: 'include' }).then(
    APIHelper.parseJSON,
  );

export const getOMIGraphDataQuery = filters =>
  fetch(
    `${global.env.API_HOST}graph/omi?${new URLSearchParams({
      query: JSON.stringify(filters),
    })}`,
    { credentials: 'include' },
  ).then(APIHelper.parseJSON);

export const getOMICSV = filters =>
  fetch(
    `${global.env.API_HOST}graph/omi?${new URLSearchParams({
      query: JSON.stringify(filters),
      csv: true,
    })}`,
    { credentials: 'include' },
  )
    .then(APIHelper.checkStatus)
    .then(APIHelper.parseText);

export const getBulletinDateListQuery = () =>
  fetch(`${global.env.API_HOST}bulletins/list`, {
    credentials: 'include',
  })
    .then(APIHelper.parseJSON)
    .then(list => list.map(({ date }) => new Date(date)));

export const getBulletinPercentageValidation = date =>
  fetch(`${global.env.API_HOST}bulletins/view/${date}`, {
    credentials: 'include',
  })
    .then(APIHelper.parseJSON)
    .then(({ progress_percent: precentageValidation }) => precentageValidation);

export const useOmiExistingFiltersQuery = () =>
  useQuery('omiExistingFiltersQuery', getOMIExistingFiltersQuery);

export const useOmiGraphDataQuery = filters =>
  useQuery(['omiGraphDataQuery', filters], () => getOMIGraphDataQuery(filters));

export const useBulletinDateListQuery = filters =>
  useQuery(['bulletinDateListQuery'], () => getBulletinDateListQuery(filters));

export const useBulletinPercentageValidation = ({ date, id = '', ...rest }) =>
  useQuery(
    ['bulletinPercentageValidationQuery', id],
    () => getBulletinPercentageValidation(date),
    rest,
  );

const getMapExistingFilters = date => {
  let url = null;
  if (date === null) {
    url = `${global.env.API_HOST}lonlat/filters/`;
  } else {
    url = `${global.env.API_HOST}lonlat/filters/${date}/`;
  }
  return fetch(url, { credentials: 'include' })
    .then(APIHelper.checkStatus)
    .then(APIHelper.parseJSON);
};
export const useMapExistingFiltersQuery = (date = null) =>
  useQuery(['mapExistingFilter', date], () => getMapExistingFilters(date));

export const useMapExistingFiltersQueries = filters =>
  useQueries(
    filters.map(({ date = null }) => ({
      queryKey: ['mapExistingFilters', date],
      queryFn: () => getMapExistingFilters(date),
    })),
  );

const getPicture = pictureAttributes => {
  // extract all param except system
  const { system, ...queryParams } = pictureAttributes;
  const url = `${global.env.API_HOST}picture/${system}?${new URLSearchParams({
    query: JSON.stringify(queryParams),
  })}`;
  return fetch(url, { credentials: 'include' })
    .then(APIHelper.checkStatus)
    .then(APIHelper.parseJSON);
};

export const usePictureQueries = filters =>
  useQueries(
    filters.map(filter => ({
      queryKey: ['mapPictures', filter],
      queryFn: () => getPicture(filter),
      enabled: filter !== undefined,
    })),
  );

const getRangeGraphData = (filters, optionsData = { percentile: 20 }) => {
  const url = `${global.env.API_HOST}graph/range?${new URLSearchParams({
    query: JSON.stringify(filters),
    percentile: optionsData.percentile,
  })}`;

  return fetch(url, { credentials: 'include' })
    .then(APIHelper.checkStatus)
    .then(APIHelper.parseJSON)
    .then(res => {
      if (!res || res?.dates?.length < 0 || res?.values?.length < 0) {
        throw new Error('No data available');
      }

      return res;
    });
};

export const useRangeGraphDataQuery = (
  filters,
  optionsData = { percentile: 20 },
) =>
  useQuery(
    ['rangeGraphDateListQuery', ...filters],
    () => getRangeGraphData(filters, optionsData),
    { enabled: filters.length > 0 },
  );

export default new APIService();
