import React from 'react';
import { connect } from 'react-redux';
import { unsetError } from '../store/error/actions';
import ErrorPopup from '../components/ErrorPopup/ErrorPopup';

const ErrorHandlerView = ({ error, dismissError }) => {
  if (!error) return null;
  return <ErrorPopup errorMessage={error} dismissError={dismissError} />;
};

const mapStateToProps = state => ({
  error: state.error,
});

const mapDispatchToProps = dispatch => ({
  dismissError: () => {
    dispatch(unsetError());
  },
});

const ErrorHandler = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorHandlerView);

export default ErrorHandler;
