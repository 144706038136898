import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import RangeGraph from './Graph/RangeGraph';
import usePrevious from '../../hooks/usePrevious';

import FiltersSet from '../FiltersSet/FiltersSet';
import DateSelector from '../DateSelector/DateSelector';
import Card from '../Card/Card';
import StatFilter from './Filter/StatFilter';
import ViewTitle from '../ViewTitle/ViewTitle';
import getNewRoute from '../../containers/helpers';
import { useRangeGraphDataQuery } from '../../services/api';
import getDataInRange from '../../selectors/graph';

const RangeView = ({
  dateRange,
  defaultActiveFilters,
  activeSyncFilters,
  availableFilters,
  colors,
  loadedFilters,
  setSyncFilters,
  statsStyles,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const activeFilters = useMemo(
    () =>
      JSON.parse(searchParams?.get('activeFilters')) ?? defaultActiveFilters,
    [defaultActiveFilters, searchParams],
  );

  const urlActiveSyncFilters = useMemo(
    () =>
      JSON.parse(searchParams?.get('activeSyncFilters')) ?? activeSyncFilters,
    [activeSyncFilters, searchParams],
  );

  const statisticDisplay = useMemo(
    () =>
      JSON.parse(searchParams?.get('statisticDisplay')) || {
        percentile: 20,
      },
    [searchParams],
  );

  const graphOnly = useMemo(
    () => ['true', ''].includes(searchParams?.get('graphOnly')),
    [searchParams],
  );

  const prevUrlActiveSyncFilters = usePrevious(urlActiveSyncFilters);

  const optionsData = useMemo(
    () => ({ percentile: statisticDisplay.percentile }),
    [statisticDisplay.percentile],
  );

  const isUrlActiveSyncFiltersUpdated =
    urlActiveSyncFilters &&
    JSON.stringify(urlActiveSyncFilters) !==
      JSON.stringify(prevUrlActiveSyncFilters);

  const { data: graphData, isLoading: loadingGraphData } =
    useRangeGraphDataQuery(activeFilters, optionsData);

  const nextDateRange = useMemo(() => {
    const defaultValues = {
      start: 'begin',
      end: 'end',
      min: graphData?.dates[0],
      max: graphData?.dates[graphData?.dates.length - 1],
    };
    const urlDateRange =
      dateRange ?? JSON.parse(searchParams?.get('dateRange'));
    return urlDateRange ? { ...defaultValues, ...urlDateRange } : defaultValues;
  }, [dateRange, graphData?.dates, searchParams]);

  useEffect(() => {
    if (isUrlActiveSyncFiltersUpdated) {
      setSyncFilters(urlActiveSyncFilters);
    }
  }, [
    isUrlActiveSyncFiltersUpdated,
    optionsData,
    setSyncFilters,
    activeFilters,
    urlActiveSyncFilters,
  ]);

  const addVisualization = useCallback(() => {
    const filters = [...activeFilters];
    filters.push(filters[filters.length - 1]);
    navigate(getNewRoute(location, { activeFilters: filters }));
  }, [activeFilters, location, navigate]);

  /**
   * Remove a serie of the current filters
   * and update the route with the new filters
   *
   * @param {number} index the index of the filter to be removed
   *
   * @memberOf GenericView
   */
  const removeVisualization = useCallback(
    index => {
      const filters = [...activeFilters];
      filters.splice(index, 1);
      navigate(getNewRoute(location, { activeFilters: filters }));
    },
    [activeFilters, location, navigate],
  );

  /**
   * Find the item in an 'availableFilters' array
   * matching system + sub-filter of the currentFilter object
   *
   * @param {Object} currentFilter filter containing system & other data
   * @param {string} filterName name of the sub-filter to match
   * @return {Object} item
   *
   * @memberOf RangeView
   */
  const findItemFilter = useCallback(
    (filterSystem, filterName, filterValue, filterColumn = 'pk') => {
      if (filterName === 'system') {
        return availableFilters.systems.find(
          currentSystem => currentSystem.pk === filterSystem,
        );
      }
      return availableFilters.systems
        .find(currentSystem => currentSystem.pk === filterSystem)
        .filters.find(filter => filter.name === filterName)
        .values.find(item => item[filterColumn] === filterValue);
    },
    [availableFilters],
  );

  /**
   * Update active filters in route params
   *
   * @memberof RangeView
   */
  const updateFilters = useCallback(
    (index, values, fieldName) => {
      let newFilters = [...activeFilters];
      newFilters[index] = values;

      if (activeSyncFilters && activeSyncFilters[fieldName] === true) {
        const originalFilterItem = findItemFilter(
          Number(values.system),
          fieldName,
          Number(values[fieldName]),
        );
        newFilters = newFilters.map(filter => {
          const newFilter = { ...filter };
          const itemFilter = findItemFilter(
            Number(filter.system),
            fieldName,
            originalFilterItem.name,
            'name',
          );
          if (itemFilter !== null && itemFilter !== undefined) {
            newFilter[fieldName] = itemFilter.pk;
          }
          return newFilter;
        });
      }
      navigate(getNewRoute(location, { activeFilters: newFilters }));
    },
    [activeFilters, activeSyncFilters, findItemFilter, location, navigate],
  );

  /**
   * Update sync filters and updates active filters if sync is on
   *
   * @param {string} filterName represent the filter which will be sync on or off
   * @param {bool} sync specify if we have to sync or not the filter between all series
   *
   * @memberOf RangeView
   */
  const updateSyncFilters = useCallback(
    (filterName, sync) => {
      const syncFilters = { ...activeSyncFilters };
      const filters = [...activeFilters];
      syncFilters[filterName] = sync;
      if (sync === true) {
        const originalFilterItem = findItemFilter(
          Number(filters[0].system),
          filterName,
          Number(filters[0][filterName]),
        );
        filters.forEach(filter => {
          const itemFilter = findItemFilter(
            Number(filter.system),
            filterName,
            originalFilterItem.name,
            'name',
          );
          if (itemFilter !== null && itemFilter !== undefined) {
            // eslint-disable-next-line no-param-reassign
            filter[filterName] = itemFilter.pk;
          }
        });
      }

      navigate(
        getNewRoute(location, {
          activeFilters: filters,
          activeSyncFilters: syncFilters,
        }),
      );
    },
    [activeFilters, activeSyncFilters, findItemFilter, location, navigate],
  );

  const updateDateRange = useCallback(
    (start, end) => {
      navigate(
        getNewRoute(location, {
          dateRange: {
            start: start ?? nextDateRange.start,
            end: end ?? nextDateRange.end,
          },
        }),
      );
    },
    [nextDateRange, location, navigate],
  );

  const updateStatDisplay = useCallback(
    (statisticName, value) => {
      const newStatistics = {
        ...statisticDisplay,
        [statisticName]: value,
      };
      navigate(getNewRoute(location, { statisticDisplay: newStatistics }));
    },
    [location, navigate, statisticDisplay],
  );

  if (!loadedFilters && !activeFilters.length)
    return <Loader>Loading filters...</Loader>;

  let FiltersView = <Loader />;
  let RangeGraphView = <Loader />;
  let GraphDateSelectorView = <Loader />;
  let StatsFiltersView = <Loader />;

  if (!loadingGraphData) {
    RangeGraphView = (
      <RangeGraph
        availableFilters={availableFilters}
        activeFilters={activeFilters}
        dateRange={nextDateRange}
        data={getDataInRange(nextDateRange, graphData)}
        loading={loadingGraphData}
        color={colors}
        statsStyles={statsStyles}
        type="scatter"
        isExportable
        graphOnly={graphOnly}
        displayStat
        statisticDisplay={statisticDisplay}
      />
    );
    GraphDateSelectorView = (
      <DateSelector
        availableFilters={availableFilters}
        activeFilters={activeFilters}
        graphData={graphData}
        loading={loadingGraphData}
        dateRange={nextDateRange}
        onChange={updateDateRange}
        color={colors}
        graphOnly={graphOnly}
      />
    );
  }

  if (loadedFilters) {
    FiltersView = (
      <FiltersSet
        filters={activeFilters}
        availableFilters={availableFilters}
        activeSyncFilters={activeSyncFilters}
        isSyncable
        addVisualization={addVisualization}
        removeVisualization={removeVisualization}
        updateFilters={updateFilters}
        updateSyncFilters={updateSyncFilters}
        allowStatChoice
      />
    );
    StatsFiltersView = (
      <StatFilter
        statisticDisplay={statisticDisplay}
        updateStatDisplay={updateStatDisplay}
      />
    );
  }
  if (graphOnly || location.pathname.startsWith('/bulletin')) {
    return <div className="GraphView range">{RangeGraphView}</div>;
  }

  return (
    <>
      <ViewTitle pageTitle="Time Series" />
      <div className="GraphView range">
        <Card className="graph">
          {RangeGraphView}
          {GraphDateSelectorView}
        </Card>
        <Card className="filters">
          {FiltersView}
          {StatsFiltersView}
        </Card>
      </div>
    </>
  );
};

export default RangeView;
