import React from 'react';
import ReactTooltip from 'react-tooltip';

import overwritePeriodInQueryParams from '../ReportView/ReportView.helper';
import RangePage from '../../containers/RangeRoute';
import InSituPage from '../../containers/InSituRoute';
import ViewPage3D from '../../containers/3DViewRoute';
import OmiPage from '../../containers/OmiRoute';
import FlagModal from './FlagModal';
import GenericBox from './GenericBox';

const hasNoData = inputData =>
  !inputData || inputData.length <= 0 || inputData[0]?.length <= 0;

class GraphBox extends GenericBox {
  updateFlagValue(comment) {
    const { content, FLAG_CODE, SEEN_CODE, updateFlagMenu } = this.props;
    const { status } = this.state;
    if (status === FLAG_CODE) {
      updateFlagMenu(content, comment, false);
      this.setState({ status: SEEN_CODE });
    } else {
      updateFlagMenu(content, comment, true);
      this.setState({ status: FLAG_CODE });
    }
  }

  static addDateRangeToURL(url) {
    const beginDate = new Date();
    beginDate.setFullYear(beginDate.getFullYear() - 1);
    const endDate = new Date();
    return overwritePeriodInQueryParams(
      { start: beginDate, end: endDate },
      url,
    );
  }

  displayGraph(graph) {
    const { data } = this.props;
    if (hasNoData(data)) {
      return 'No data available for this graph';
    }

    const graphType = graph.url.split('?')[0].split('/').slice(-1)[0];

    let fullUrl = graph.url.match(/dateRange/)
      ? graph.url
      : GraphBox.addDateRangeToURL(graph.url);
    [, fullUrl] = fullUrl.split('?');

    switch (graphType) {
      case 'range':
        return <RangePage url={fullUrl} data={data[0]} />;
      case 'in-situ':
        return <InSituPage url={fullUrl} data={data[0]} data2={data[1]} />;
      case 'tz-view':
        return <ViewPage3D url={fullUrl} data={data[0]} />;
      case 'omi':
        return <OmiPage url={fullUrl} data={data[0]} />;
      default:
        throw new Error(
          'Error with one of the given url to display. Please correct the configuration of the report.',
        );
    }
  }

  render() {
    const { data, content, disableFlag, FLAG_CODE, userIsAuthenticated } =
      this.props;
    const { flagModalIsOpen, comment, status } = this.state;

    const graphHasNoData = hasNoData(data);

    return (
      <div
        className={`reSize col-${content.col} row-${content.row}`}
        key={content.id}
      >
        <div className="BoxContent">
          <div
            className={`card graph-card ${
              status === FLAG_CODE && userIsAuthenticated
                ? 'graph-card-error'
                : ''
            }`}
          >
            {this.displayGraph(content)}
            <div className="button-bottom">
              <div className="button-left">
                {userIsAuthenticated && (
                  <button
                    className="filters-button error-button"
                    type="button"
                    disabled={disableFlag || graphHasNoData}
                    onClick={() =>
                      status === FLAG_CODE
                        ? this.updateFlagValue('')
                        : this.toggleFlagModal()
                    }
                  >
                    {status === FLAG_CODE ? 'Unflag' : 'Flag'}
                  </button>
                )}
              </div>
              <div className="button-right">
                <button
                  className="filters-button"
                  type="button"
                  disabled={content.help_text.length === 0}
                  data-tip={content.help_text.replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br />',
                  )}
                  data-event="click"
                  data-for={`help-tooltip${content.id}`}
                >
                  Help
                </button>
                <ReactTooltip
                  id={`help-tooltip${content.id}`}
                  globalEventOff="click"
                  className="tooltip"
                  effect="solid"
                  multiline
                />
                <a
                  href={GraphBox.addDateRangeToURL(content.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="filters-button"
                    type="button"
                    disabled={graphHasNoData}
                  >
                    Open interactive navigation
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {flagModalIsOpen ? (
          <FlagModal
            show={flagModalIsOpen}
            closeAction={this.toggleFlagModal}
            updateFlag={this.updateFlagValue}
            updateComment={this.updateComment}
            comment={comment}
          />
        ) : null}
      </div>
    );
  }
}

export default GraphBox;
