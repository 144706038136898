import { connect } from 'react-redux';

import { setSyncFilters } from '../store/filter/actions';
import { setDates } from '../store/date/actions';
import RangeView from '../components/GraphView/RangeView';

const mapStateToProps = (state, props) => {
  const params = props.url && new URLSearchParams(props.url);
  return {
    loadedFilters: state.availableFilters.loaded,
    defaultActiveFilters: props.url
      ? JSON.parse(params.get('activeFilters'))
      : state.range.activeFilters,
    activeSyncFilters: props.url
      ? JSON.parse(params.get('activeSyncFilters'))
      : state.range.activeSyncFilters,
    availableFilters: state.availableFilters.data,
    dateRange: props.url
      ? JSON.parse(params.get('dateRange'))
      : state.dateRange,
    colors: state.style.markers,
    statsStyles: state.style.stats,
  };
};

const mapDispatchToProps = dispatch => ({
  setSyncFilters: syncFilters => {
    dispatch(setSyncFilters(syncFilters));
  },
  setDates: dates => dispatch(setDates(dates)),
});

const RangePage = connect(mapStateToProps, mapDispatchToProps)(RangeView);

export default RangePage;
