import { connect } from 'react-redux';

import { login, checkIfConnected, logout } from '../store/connection/actions';

import ConnectionView from '../components/ConnectionView/ConnectionView';

const mapStateToProps = state => ({
  userIsAuthenticated: state.connection.userIsAuthenticated,
  user: state.connection.user,
});

const mapDispatchToProps = dispatch => ({
  login: user => dispatch(login(user)),
  checkIfConnected: () => dispatch(checkIfConnected()),
  logout: () => dispatch(logout()),
});

const ConnectionTool = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectionView);

export default ConnectionTool;
