import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import withRouter from '../hooks/withRouter';

import { setInSituFilters } from '../store/filter/actions';
import { get3DViewGraphData } from '../store/graph/3d.actions';
import getInSituFilters from '../selectors/filters';
import View3D from '../components/GraphView/3DView';
import getNewRoute from './helpers';

const mapStateToProps = (state, ownProps) => {
  const searchToParse = ownProps.url
    ? ownProps.url
    : state.router.location.search;
  const parsedSearch = new URLSearchParams(searchToParse);
  const urlActiveFilters = JSON.parse(parsedSearch.get('activeFilters'));
  const display3D = JSON.parse(parsedSearch.get('display3D')) || {
    type: state.type['3d'][0].type,
  };
  let isCentered = false;
  const availableFilters = getInSituFilters(state);
  const { activeFilters } = state.View3D;
  if (availableFilters?.systems && activeFilters.length > 0) {
    isCentered = availableFilters.systems
      .find(
        currentSystem =>
          currentSystem.pk === parseInt(activeFilters[0].system, 10),
      )
      .filters.find(currentFilter => currentFilter.name === 'stat')
      .values.find(
        currentValue => currentValue.pk === parseInt(activeFilters[0].stat, 10),
      ).centered;
  }

  return {
    loadedFilters: state.availableFilters.loaded,
    loadingGraphData: state.View3D.graphData.loading,
    activeFilters,
    availableFilters,
    urlActiveFilters,
    display3D,
    graphData: ownProps.data ? ownProps.data : state.View3D.graphData.data,
    graphTypes: state.type['3d'],
    colorscale: state.style.scale,
    minMax: state.style.minMax,
    userMinMax: { min: display3D.min, max: display3D.max },
    isCentered,
    graphOnly: !!ownProps.url,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilters: filters => {
    dispatch(setInSituFilters(filters));
  },
  getGraphData: filters => {
    dispatch(get3DViewGraphData(filters));
  },
  updateRoute(location, queryName) {
    dispatch(push(getNewRoute(location, queryName)));
  },
});

const ViewPage3D = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(View3D),
);

export default ViewPage3D;
