import React from 'react';
import { NavLink } from 'react-router-dom';

import { getEntryLink } from './Bulletin.helper';

import './VariableListItem.scss';

const VariableListItem = ({
  bulletinDate,
  entry,
  status,
  userIsAuthenticated,
}) => (
  <li className="entries-item">
    <NavLink
      className={({ isActive }) => (isActive ? 'active' : '')}
      to={getEntryLink(bulletinDate, entry)}
    >
      {entry.name}
    </NavLink>
    {entry.outdated === true && (
      <span className="icon icon-outdated" data-cy="variable-is-outdated" />
    )}
    {userIsAuthenticated && status.isUnseen && (
      <span className="icon icon-eye-close" data-cy="variable-is-unseen" />
    )}
    {userIsAuthenticated && status.isFlagged && (
      <span className="icon icon-flag" data-cy="variable-is-flagged" />
    )}
  </li>
);

export default VariableListItem;
