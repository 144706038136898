export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';

export const setError = error => ({
  type: SET_ERROR,
  error,
});

export const unsetError = () => ({
  type: UNSET_ERROR,
});
