const formatBulletinDate = date => {
  try {
    return new Date(date).toISOString().split('T')[0];
  } catch (e) {
    // Returns TODAY if the date is invalid
    return new Date().toISOString().split('T')[0];
  }
};

export async function fetchEnv() {
  const rawEnv = await fetch('/env.json');
  return rawEnv.json();
}

export const getEnv = () =>
  fetchEnv()
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error(
        'env.json is invalid. Please create a public/env.json from public/env.dist.json',
      );
      return null;
    })
    .then(env => ({
      ...env,
      BULLETIN_DEFAULT_DATE: formatBulletinDate(env.BULLETIN_DEFAULT_DATE),
    }));

export default { getEnv, fetchEnv };
