import { LOAD_3DVIEW_GRAPH, RECEIVE_3DVIEW_GRAPH } from './actions';

export const defaultState = {
  data: null,
  loading: false,
};

export const ViewGraphData3D = (state = defaultState, { data, type }) => {
  switch (type) {
    case LOAD_3DVIEW_GRAPH:
      return {
        data: null,
        loading: true,
      };

    case RECEIVE_3DVIEW_GRAPH:
      return {
        data,
        loading: false,
      };

    default:
      return state;
  }
};
