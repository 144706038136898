import React from 'react';
import './Card.scss';

const Card = ({ className, children, title, close, ...rest }) => {
  const cardTitle = title ? <div className="card-title">{title}</div> : null;
  const closeButton = close ? (
    <button type="button" className="card-close" onClick={close}>
      x
    </button>
  ) : null;
  return (
    <div className={`card ${className}`} {...rest}>
      {cardTitle}
      {closeButton}
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;
