export const getEntryLink = (date, entry) => {
  if (!entry) {
    return null;
  }
  const code = entry.type === 'variable' ? 'v' : 'r';
  return `/bulletin/${date}/${code}/${entry?.id}`;
};

export const sortOrdersFunction = (a, b) => a.order - b.order;

export const getOrderedEntries = systems =>
  systems
    .sort(sortOrdersFunction)
    .map(system =>
      system.categories
        .sort(sortOrdersFunction)
        .map(category => category?.entries.sort(sortOrdersFunction))
        .flat(),
    )
    .flat();

export const getPrevNextEntry = (
  orderedEntries,
  currentEntryId,
  entryType = 'variable',
  type = 'next',
) => {
  const currentIndex = orderedEntries.findIndex(
    entry => entry?.id === currentEntryId && entry?.type === entryType,
  );
  if (currentIndex === -1) {
    return null;
  }

  const entryIndex = type === 'next' ? currentIndex + 1 : currentIndex - 1;
  const noEntryToReturn =
    (type === 'next' && entryIndex > orderedEntries.length) ||
    (type === 'prev' && entryIndex < 0);

  if (noEntryToReturn) {
    return null;
  }
  return orderedEntries[entryIndex];
};

const matchEntry = (entry, entryID, entryType) =>
  entry?.id === entryID && entry?.type === entryType;

export const getCategoryToCollapse = (
  formatedSystems = [],
  entryID,
  entryType = 'variable',
  type = 'previous',
) => {
  let targetCategory = null;
  let categorySystemId = null;
  let categoryName = null;

  const categoriesFlat = formatedSystems
    // orderedCategories might not be defined yet
    .map(({ id, categories, orderedCategories = categories }) =>
      orderedCategories.map(c => ({
        ...c,
        systemId: id,
      })),
    )
    .flat();

  // get current category
  const currentCat = categoriesFlat.find(c =>
    c.entries.some(e => matchEntry(e, entryID, entryType)),
  );

  if (!currentCat) {
    return null;
  }
  const entityIndex = currentCat.entries.findIndex(e =>
    matchEntry(e, entryID, entryType),
  );

  // we want to collapse prev/next category only if the variable
  // was the first/last of its own category
  const shouldCollapse =
    (type === 'previous' && entityIndex === 0) ||
    (type === 'next' && entityIndex === currentCat.entries.length - 1);

  if (shouldCollapse) {
    const categoryIndex = categoriesFlat.findIndex(c => c.id === currentCat.id);
    if (type === 'previous' && categoryIndex > 0) {
      targetCategory = categoriesFlat[categoryIndex - 1];
    }
    if (type === 'next' && categoryIndex < categoriesFlat.length) {
      targetCategory = categoriesFlat[categoryIndex + 1];
    }
    categorySystemId = targetCategory?.systemId;
    categoryName = targetCategory?.name;
  }

  if (!shouldCollapse || (!categorySystemId && !categoryName)) {
    return null;
  }

  return {
    categorySystemId,
    categoryName,
  };
};
