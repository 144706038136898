import React, { useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';

import usePrevious from '../../hooks/usePrevious';

import './MapFilters.scss';

const arrayFiltersToObjectFilters = filters =>
  filters.reduce((list, { label, selected }) => {
    // eslint-disable-next-line no-param-reassign
    list[label] = selected;
    return list;
  }, {});

const MapFilters = ({
  activeFilters,
  existingFilters,
  updateActiveFilters,
  indexFilter,
  hasMoreThanOneFilter,
  onClickRemoveButton,
}) => {
  const recursiveFilterGeneration = useCallback(
    (filter, nextFilters = activeFilters) => {
      if (!filter) {
        return null;
      }
      const label = filter.title ?? filter.child_title;
      const values = (filter.values ?? filter.child_values).sort((a, b) =>
        a.name ? a.name.localeCompare(b.name) : a - b,
      );
      const selectedValue = nextFilters[label];
      const nextFilter =
        values.find(value => selectedValue === (value.pk ?? value)) ??
        values[0];

      if (label === 'date') {
        return [
          {
            type: 'datePicker',
            label,
            selected: selectedValue,
            values: filter.values,
          },
        ];
      }

      return [
        {
          type: 'select',
          label,
          selected: nextFilter.pk ?? nextFilter,
          values: values.map(value => ({
            value: value.pk ?? value,
            label: value.name ?? value,
          })),
        },
        recursiveFilterGeneration(nextFilter, nextFilters),
      ].flat();
    },
    [activeFilters],
  );

  const filters = useMemo(
    () =>
      existingFilters.flatMap(filter =>
        recursiveFilterGeneration(filter).filter(Boolean),
      ),
    [existingFilters, recursiveFilterGeneration],
  );

  const generatedFilters = useMemo(
    () => arrayFiltersToObjectFilters(filters),
    [filters],
  );

  const prevGeneratedFilters = usePrevious(generatedFilters);

  const validateFilter = useCallback(
    (nextFilters = generatedFilters) => {
      updateActiveFilters(nextFilters, indexFilter);
    },
    [generatedFilters, indexFilter, updateActiveFilters],
  );

  const updateActiveFilter = useCallback(
    (name, value) => {
      const nextRawFilters = {
        ...generatedFilters,
        [name]: name === 'date' ? value : Number(value),
      };
      const nextFilters = existingFilters.flatMap(filter =>
        recursiveFilterGeneration(filter, nextRawFilters).filter(Boolean),
      );

      validateFilter(arrayFiltersToObjectFilters(nextFilters));
    },
    [
      generatedFilters,
      existingFilters,
      validateFilter,
      recursiveFilterGeneration,
    ],
  );

  const updateDate = useCallback(
    date => {
      updateActiveFilter('date', date.toISOString().substring(0, 10));
    },
    [updateActiveFilter],
  );

  useEffect(() => {
    if (
      JSON.stringify(prevGeneratedFilters) !== JSON.stringify(generatedFilters)
    ) {
      validateFilter(generatedFilters);
    }
  }, [generatedFilters, indexFilter, prevGeneratedFilters, validateFilter]);

  if (Object.keys(existingFilters).length === 0) {
    return <p>No current system available</p>;
  }

  return (
    <tr className="Filters">
      <td className="filters2">
        {hasMoreThanOneFilter && (
          <button
            type="button"
            onClick={onClickRemoveButton}
            className="filters2-button remove-button2"
            aria-label="Remove filter row"
          >
            -
          </button>
        )}
      </td>
      {filters.map(filter => {
        if (filter.type === 'datePicker') {
          return (
            <td key={filter.label}>
              <DatePicker
                name="date"
                className="moniqua-select date"
                selected={new Date(filter.selected)}
                dateFormat="yyyy-MM-dd"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={new Date(filter.values[0])}
                maxDate={new Date(filter.values[1])}
                onChange={updateDate}
              />
            </td>
          );
        }
        return (
          <td className="moniqua-select" key={filter.label}>
            <select
              name={filter.label}
              value={filter.selected}
              onChange={({ target }) => {
                updateActiveFilter(target.name, target.value);
              }}
            >
              {filter.values.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </td>
        );
      })}
    </tr>
  );
};

export default MapFilters;
