/**
 * Overwrite in a query string a period
 * Search a regexp pattern and replace it with the new period
 * Return the new query string
 *
 * @param {Object{start:moment|'begin', end:moment|'end'}} period
 * Period to be integrated in the queryString
 *
 * @param {String} queryString
 * Query params used to display a graph
 */
const overwritePeriodInQueryParams = (period, queryString) => {
  const dateRangeQueryString = JSON.stringify({
    start: period.start,
    end: period.end,
  });
  let newQueryString = queryString;
  const dateRangeMatch = queryString.match(/dateRange.*}/);
  if (dateRangeMatch && dateRangeMatch[0]) {
    newQueryString = queryString.replace(
      dateRangeMatch[0],
      `dateRange=${dateRangeQueryString}`,
    );
  } else {
    newQueryString += `&dateRange=${dateRangeQueryString}`;
  }
  return newQueryString;
};

export default overwritePeriodInQueryParams;
