import {
  SET_MIN_MAX_DATES,
  SET_START_DATE,
  SET_END_DATE,
  SET_DATES,
} from './actions';

export const defaultState = {
  start: 'begin',
  end: 'end',
  min: null,
  max: null,
};

export const dateRange = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MIN_MAX_DATES:
      return {
        ...state,
        min: action.onlyIfNull !== true ? action.dates.begin : state.min,
        max: action.onlyIfNull !== true ? action.dates.end : state.max,
      };

    case SET_START_DATE:
      return {
        ...state,
        start: action.date,
      };

    case SET_END_DATE:
      return {
        ...state,
        end: action.date,
      };

    case SET_DATES:
      return {
        ...state,
        start: action.dates.start,
        end: action.dates.end,
      };

    default:
      return state;
  }
};
