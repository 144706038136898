import React from 'react';
import ReactTooltip from 'react-tooltip';

import '../BulletinView/Modal/ValidationModal.scss';

class FlagModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: props.comment,
    };
  }

  render() {
    const { show, closeAction, updateFlag, updateComment } = this.props;

    const { comment } = this.state;

    const helpText = 'Comment the irregularity spotted on the content';

    const showHideClassName = show ? 'display-block' : 'display-none';

    return (
      <div className={`${showHideClassName} bulletinModal`}>
        <section className="frontModal flag">
          <h2>
            Explaination of the anomaly
            <span
              data-tip={helpText}
              data-for="help"
              className="icon-info-circle"
            />
          </h2>
          <ReactTooltip
            id="help"
            place="top"
            type="dark"
            effect="solid"
            delayHide={1500}
            className="tooltip"
            multiline
          />
          <textarea
            placeholder="Optional comment about the irregularity of the content"
            className="commentZone flag"
            value={comment}
            onChange={e => this.setState({ comment: e.target.value })}
          />
          <div className="buttonZone">
            <div className="button-left">
              <button
                type="button"
                className="modal-button closing"
                onClick={() => {
                  updateComment(comment);
                  closeAction();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="button-right">
              <button
                type="button"
                className="modal-button"
                onClick={() => {
                  updateComment(comment);
                  updateFlag(comment);
                  closeAction();
                }}
              >
                Flag
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default FlagModal;
