import APIService from '../../services/api';
import { setError } from '../error/actions';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const loginUser = data => ({
  type: LOGIN_USER,
  data,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const login = user => dispatch =>
  APIService.login(user)
    .then(({ status, username, hasBulletinPermission }) => {
      if (status === 'success') {
        dispatch(
          loginUser({
            user: username,
            hasBulletinPermission,
          }),
        );
      } else {
        dispatch(setError('Invalid credentials'));
      }
    })
    .catch(err =>
      dispatch(setError(`Issue while sending data to the server: ${err}`)),
    );

export const checkIfConnected = () => dispatch => {
  APIService.isUserConnected()
    .then(({ username, hasBulletinPermission }) => {
      if (username === null) {
        APIService.logout();
        dispatch(logoutUser());
      } else {
        dispatch(
          loginUser({
            user: username,
            hasBulletinPermission,
          }),
        );
      }
    })
    .catch(err =>
      dispatch(setError(`Issue while sending data to the server: ${err}`)),
    );
};

export const logout = () => dispatch => {
  APIService.logout();
  dispatch(logoutUser());
};
