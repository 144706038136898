import { LOGIN_USER, LOGOUT_USER } from './actions';

const connectionDefaultState = {
  userIsAuthenticated: false,
  user: '',
  hasBulletinPermission: false,
};

export default (state = connectionDefaultState, { type, data }) => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        ...data,
        userIsAuthenticated: true,
      };

    case LOGOUT_USER:
      return {
        ...state,
        userIsAuthenticated: false,
        user: '',
        hasBulletinPermission: false,
      };

    default:
      return state;
  }
};
