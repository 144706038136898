import APIService from '../../services/api';
import { setError } from '../error/actions';

import {
  LOAD_INSITU_GRAPH,
  RECEIVE_INSITU_GRAPH,
  RECEIVE_INSITU_GRAPH_DATA,
} from './actions';

export const loadInSituGraph = () => ({
  type: LOAD_INSITU_GRAPH,
});

export const receiveInSituGraph = data => ({
  type: RECEIVE_INSITU_GRAPH,
  data,
});

export const receiveInSituGraphData = data => ({
  type: RECEIVE_INSITU_GRAPH_DATA,
  data,
});

export const getInSituGraphData = (filters, filtersData) => async dispatch => {
  dispatch(loadInSituGraph());
  try {
    const res = await APIService.getInSituGraphData(filters);
    dispatch(receiveInSituGraph(res));
  } catch (err) {
    dispatch(setError(`Issue while fetching graph data: ${err}`));
  }

  try {
    const res = await APIService.getInSituGraphData(filtersData);
    dispatch(receiveInSituGraphData(res));
  } catch (err) {
    dispatch(setError(`Issue while fetching graph data: ${err}`));
  }
};
