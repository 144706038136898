import React from 'react';
import Card from '../Card/Card';
import './ErrorCard.scss';

const ErrorCard = ({ errorMessage, dismissError }) => {
  const defaultMessage = 'An unknown error has occurred';
  const message = errorMessage || defaultMessage;
  const onClose = dismissError;

  return (
    <Card className="error" title="Ooops" close={onClose}>
      {message}
    </Card>
  );
};

export default ErrorCard;
