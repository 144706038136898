import React from 'react';
import Filters from '../Filters/Filters';
import './FiltersSet.scss';

const FiltersSet = ({
  filters,
  isSyncable,
  updateFilters,
  availableFilters,
  showDate,
  onlyOneFilter,
  onlyWednesday,
  activeSyncFilters = {},
  removeVisualization = () => {},
  addVisualization = () => {},
  updateSyncFilters = () => {},
}) => {
  if (!availableFilters) return <div>No filters available</div>;

  return (
    <div className="table-responsive">
      <h2 className="h2TitleFilter">Parameters</h2>
      <table className="table borderless">
        <tbody>
          {isSyncable === true && (
            <tr>
              {/* skip sync icon for add filter button */}
              <td />
              {/* skip sync icon for stattype because it is already linked to system */}
              <td />
              <td key="system" className="text-center">
                {activeSyncFilters.system === true && (
                  <button
                    onClick={() => {
                      updateSyncFilters('system', false);
                    }}
                    className="icon-link button-not-button"
                    type="button"
                    aria-label="Link parameters"
                  />
                )}
                {activeSyncFilters.system !== true && (
                  <button
                    onClick={() => {
                      updateSyncFilters('system', true);
                    }}
                    className="icon-unlink button-not-button"
                    type="button"
                    aria-label="Unlink parameters"
                  />
                )}
              </td>
              {availableFilters.systems[0].filters.map(filter => (
                <td key={filter.name} className="text-center">
                  {activeSyncFilters[filter.name] === true && (
                    <button
                      onClick={() => {
                        updateSyncFilters(filter.name, false);
                      }}
                      className="icon-link button-not-button"
                      type="button"
                      aria-label="Link parameters"
                    />
                  )}
                  {activeSyncFilters[filter.name] !== true && (
                    <button
                      onClick={() => {
                        updateSyncFilters(filter.name, true);
                      }}
                      className="icon-unlink button-not-button"
                      type="button"
                      aria-label="Unlink parameters"
                    />
                  )}
                </td>
              ))}
            </tr>
          )}
          {filters.map((filter, index) => (
            <Filters
              // eslint-disable-next-line react/no-array-index-key
              key={`filter-${index}`}
              indexFilter={index}
              activeFilters={filter}
              firstFilter={filters[0]}
              availableFilters={availableFilters}
              activeSyncFilters={activeSyncFilters}
              onClickRemoveButton={() => removeVisualization(index)}
              hasMoreThanOneFilter={filters.length > 1}
              onChange={(values, fieldName) =>
                updateFilters(index, values, fieldName)
              }
              isFirstRow={index === 0}
              showDate={showDate}
              onlyWednesday={onlyWednesday}
            />
          ))}
          <tr>
            <td>
              {!onlyOneFilter && (
                <button
                  className="filters-button add-button"
                  onClick={addVisualization}
                  type="button"
                >
                  +
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FiltersSet;
