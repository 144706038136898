import React, { useState } from 'react';

const ChartExportModal = ({ exportPNG, closeAction, title = '' }) => {
  const [chartTitle, setChartTitle] = useState(title);

  const validate = () => {
    exportPNG(chartTitle);
    closeAction();
  };

  return (
    <div className="modal-container">
      <div
        className="modal-backdrop"
        role="button"
        tabIndex={0}
        onClick={closeAction}
        onKeyPress={closeAction}
        aria-label="Close modal"
      />
      <section className="front-modal modal-own-content">
        <h4>Change the chart title for the PNG export</h4>
        <input
          placeholder="Chart title"
          className="input-credential"
          value={chartTitle}
          onChange={e => setChartTitle(e.target.value)}
        />
        <div className="button-zone">
          <div className="button-left">
            <button
              type="button"
              className="modal-button close"
              onClick={closeAction}
            >
              Cancel
            </button>
          </div>
          <div className="button-right">
            <button type="button" className="modal-button" onClick={validate}>
              Export chart
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChartExportModal;
