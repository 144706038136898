/**
 * Type of graph for plotly.js
 *
 * According to the configuration of the graph,
 * a set of types will be available for the user.
 */

export const defaultState = {
  insitu: [{ type: 'scatter', label: 'Scatter plot' }],
  range: [{ type: 'scatter', label: 'Scatter plot' }],
  '3d': [
    { type: 'heatmap', label: 'Heatmap' },
    { type: 'contour', label: 'Contour plot' },
  ],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
