import {
  SET_VARIABLE_ID_TO_DISPLAY,
  SET_REPORT_ID_TO_DISPLAY,
  LOAD_SYSTEMS,
  RECEIVED_DATA,
  LOADING,
  LOADING_COMMENT,
  NO_DATA,
} from './actions';

const bulletinDefaultState = {
  loading: false,
  loadingContent: false,
  loadingBulletinComment: false,
  systems: [],
  variableToDisplay: null,
  variableIdToDisplay: null,
  reportToDisplay: null,
  reportIdToDisplay: null,
  bulletin: [],
  bulletinComment: null,
  alertComment: null,
  noData: null,
};

export default (state = bulletinDefaultState, { type, data, id }) => {
  switch (type) {
    case SET_VARIABLE_ID_TO_DISPLAY:
      return {
        ...state,
        variableIdToDisplay: id,
      };

    case SET_REPORT_ID_TO_DISPLAY:
      return {
        ...state,
        reportIdToDisplay: id,
      };

    case LOAD_SYSTEMS:
      return {
        ...state,
        systems: [],
        variableToDisplay: null,
        variableIdToDisplay: null,
        reportToDisplay: null,
        reportIdToDisplay: null,
        bulletin: [],
        loading: true,
      };

    case RECEIVED_DATA:
      return {
        ...state,
        ...data,
        loading: false,
        loadingContent: false,
        loadingBulletinComment: false,
        loadingAlertComment: false,
      };

    case LOADING:
      return {
        ...state,
        loadingContent: true,
      };

    case NO_DATA:
      return {
        ...state,
        noData: true,
      };

    case LOADING_COMMENT:
      return {
        ...state,
        loadingAlertComment: true,
      };

    default:
      return state;
  }
};
