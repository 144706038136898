import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import {
  BrowserRouter,
  NavLink,
  Route,
  Routes as ReactRouterDomRoutes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';
import { getAvailableFilters } from './store/filter/actions';

import ConnectionTool from './containers/ConnectionRoute';
import ErrorHandler from './containers/ErrorHandler';
import RangePage from './containers/RangeRoute';
import InSituPage from './containers/InSituRoute';
import ViewPage3D from './containers/3DViewRoute';
import BulletinPage from './containers/BulletinRoute';
import MapPage from './containers/MapRoute';
import { EnvContext } from './context/Env';
import OmiPage from './containers/OmiRoute';
import { Page404 } from './containers/404Route';
import ViewTitle from './components/ViewTitle/ViewTitle';
import HomePage from './components/HomePage/HomePage';

import './App.scss';

const appLinks = [
  {
    name: 'BULLETIN',
    to: '/bulletin',
    image: 'homeImages/moniqua_bulletin.png',
    description:
      'Access the bulletin for weekly and daily validation of physical and biogeochemical analyses and forecasts',
  },
  {
    name: 'LON/LAT maps',
    to: '/map-view',
    image: 'homeImages/moniqua_lonlat_maps.png',
    description:
      'Interactively access the complete database of static bulletin images and complementary graphics',
  },
  {
    name: 'TIME SERIES',
    to: '/range',
    image: 'homeImages/moniqua_timeserie.png',
    description:
      'Interactive access to the database of statistics comparing the operational model outputs with observations',
  },
  {
    name: 'DEPTH VS TIME',
    to: '/tz-view',
    image: 'homeImages/moniqua_tz-view.png',
    description:
      'Visualise the depth vs time evolution of different scores with respect with insitu temperature and salinity observations',
  },
  {
    name: 'VERTICAL PROFILE',
    to: '/in-situ',
    image: 'homeImages/moniqua_vprof.png',
    description:
      'Visualise the average difference profiles to in situ observations of temperature and salinity as a fonction of depth',
  },
  {
    name: 'OMI',
    to: '/omi',
    image: 'homeImages/moniqua_omi.png',
    description:
      'View a selection of integrated time-dependent indicators, including the Copernicus Marine Service Monitoring Indicators',
  },
];

export default function App({ store }) {
  const { env } = EnvContext();

  useEffect(() => {
    // load filters once for all
    store.dispatch(getAvailableFilters());
  });

  if (env === null) {
    return (
      <div>
        env.json is invalid. Please create a public/env.json from
        public/env.dist.json
      </div>
    );
  }

  const graphOnly = ['true', ''].includes(
    new URLSearchParams(window.location.search).get('graphOnly'),
  );
  let header = null;

  const Routes =
    env.INSTANCE_NAME !== 'local'
      ? Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes)
      : ReactRouterDomRoutes;

  if (!graphOnly) {
    header = (
      <div className="App-header shadow">
        <NavLink className="unstyledLink" to="/">
          <h2 className="App-title">
            <img
              className="logo"
              src="/favicon-70x70-1-white.png"
              alt="Mercator logo"
            />
            <span>oniqua</span>
          </h2>
        </NavLink>
        <div className="App-nav">
          {appLinks.map(({ name, to }) => (
            <NavLink
              className={({ isActive }) => (isActive ? 'active' : '')}
              key={to}
              to={to}
            >
              {name}
            </NavLink>
          ))}
          <ConnectionTool />
        </div>
      </div>
    );
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ViewTitle />
        <div className={`App${graphOnly ? ' App--isGraphOnly' : ''}`}>
          {header}
          <div className="App-content">
            <Routes>
              <Route path="range" element={<RangePage />} />
              <Route path="in-situ" element={<InSituPage />} />
              <Route path="tz-view" element={<ViewPage3D />} />
              <Route path="bulletin" element={<BulletinPage />}>
                <Route path=":bulletinDate" element={<BulletinPage />}>
                  <Route path="v/:variableId" element={<BulletinPage />} />
                  <Route path="r/:reportId" element={<BulletinPage />} />
                </Route>
                <Route path="v/:variableId" element={<BulletinPage />} />
                <Route path="r/:reportId" element={<BulletinPage />} />
              </Route>
              <Route path="map-view" element={<MapPage />} />
              <Route path="omi" element={<OmiPage />} />
              <Route path="/" element={<HomePage appLinks={appLinks} />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
            <ErrorHandler />
          </div>
        </div>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}
