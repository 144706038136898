import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ValidationButton from './Modal/ValidationButton';
import WarningButton from './Modal/WarningButton';

import { EnvContext } from '../../context/Env';

const ValidationBloc = ({
  AlerteBulletin,
  bulletin,
  bulletinComment,
  comment,
  entriesStatus,
  getBulletinComment,
  handleValidateBulletin,
  loadingBulletinComment,
  userIsAuthenticated,
}) => {
  const [stateBulletinIsValidated, setStateBulletinIsValidated] = useState(
    bulletin.isValidated,
  );

  const { bulletinDate } = useParams();
  const {
    env: { BULLETIN_DEFAULT_DATE },
  } = EnvContext();

  if (BULLETIN_DEFAULT_DATE !== bulletinDate) {
    return null;
  }

  if (!userIsAuthenticated) {
    return (
      <div className="bulletin-info validation-bloc">
        You should be connected to validate a bulletin
      </div>
    );
  }

  return (
    <div className="validation-bloc">
      <WarningButton
        AlerteBulletin={AlerteBulletin}
        comment={comment}
        bulletin={bulletin}
        userIsAuthenticated={userIsAuthenticated}
        stateBulletinIsValidated={stateBulletinIsValidated}
      />
      <ValidationButton
        entriesStatus={entriesStatus}
        bulletin={bulletin}
        getBulletinComment={getBulletinComment}
        validateBulletin={handleValidateBulletin}
        loadingBulletinComment={loadingBulletinComment}
        bulletinComment={bulletinComment}
        stateBulletinIsValidated={stateBulletinIsValidated}
        setStateBulletinIsValidated={setStateBulletinIsValidated}
      />
    </div>
  );
};

export default ValidationBloc;
