import React, { Component } from 'react';
import Plotly from 'plotly.js/lib/core';

import Loader from '../../Loader/Loader';
import './GenericGraph.scss';

class GenericGraph extends Component {
  loadingTimer = null;

  resizeTimeout = null;

  hiddenGraph = React.createRef();

  graphElement = React.createRef();

  defaultOptions = {
    displayModeBar: false,
    scrollZoom: false,
    showLink: false,
    displaylogo: false,
  };

  // TO BE REDEFINED
  defaultLayout = {};

  componentDidMount() {
    const {
      type,
      data,
      layout,
      options,
      activeFilters,
      availableFilters,
      color,
      statisticDisplay,
      dateRange,
    } = this.props;

    if (data?.values?.length) {
      this.initGraph(
        type,
        data,
        layout,
        options,
        activeFilters,
        availableFilters,
        color,
        this.graphElement,
        statisticDisplay,
        dateRange,
      );
      window.addEventListener('resize', this.resizeListener);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      type,
      layout,
      statisticDisplay,
      options,
      activeFilters,
      availableFilters,
      color,
      dateRange,
    } = this.props;
    if (
      (prevProps.data !== data ||
        prevProps.type !== type ||
        prevProps.layout !== layout ||
        prevProps.statisticDisplay !== statisticDisplay ||
        prevProps.dateRange !== dateRange ||
        prevProps.options !== options) &&
      data?.values?.length
    ) {
      this.initGraph(
        type,
        data,
        layout,
        options,
        activeFilters,
        availableFilters,
        color,
        this.graphElement,
        statisticDisplay,
        dateRange,
      );
    }
    this.purgeChart();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  hasNoData = () => {
    const { data, loading } = this.props;
    return !loading && (!data || (Array.isArray(data) && !data.length));
  };

  resizeListener = () => {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    const {
      type,
      data,
      layout,
      options,
      activeFilters,
      availableFilters,
      color,
      statisticDisplay,
      dateRange,
    } = this.props;
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.initGraph(
          type,
          data,
          layout,
          options,
          activeFilters,
          availableFilters,
          color,
          this.graphElement,
          statisticDisplay,
          dateRange,
        );
      }, 200);
    }
  };

  exportPNG = () => {
    this.layout.font = {
      family: 'Roboto',
      size: 20,
    };
    this.layout.margin.t = 60;
    this.layout.margin.b = 60;
    Plotly.newPlot(
      this.hiddenGraph.current,
      this.data,
      this.layout,
      this.options,
    ).then(gd =>
      Plotly.downloadImage(gd, {
        filename: 'moniqua-time-serie',
        format: 'png', // also can use 'jpeg', 'webp', 'svg'
        height: 900,
        width: 1400,
      }),
    );
  };

  exportCSV = async () => {
    const { handleCSVExport } = this.props;
    if (typeof handleCSVExport === 'function') {
      const CSV = await handleCSVExport();
      if (CSV) {
        const file = new Blob([CSV], { type: 'text/plain' });
        const link = document.createElement('a');
        link.download = `${this.layout?.title.text || 'CSVExport'}.csv`;
        link.href = global.URL.createObjectURL(file);
        link.click();
      }
    }
  };

  getClassName = () => 'graph-container';

  purgeChart() {
    const noData = this.hasNoData();
    if (noData && this.graphElement.current) {
      Plotly.purge(this.graphElement.current);
    }
  }

  render() {
    const { isExportable, handleCSVExport, loading } = this.props;
    const className = this.getClassName();

    // empty data is [] sent from the backend
    // regular response is an object
    const noData = this.hasNoData();

    return (
      <div className={className}>
        <div ref={this.graphElement} className="graph ">
          {!noData && (isExportable || handleCSVExport) && (
            <div className="export-data">
              {isExportable && (
                <button
                  type="button"
                  className="filters-button"
                  onClick={this.exportPNG}
                >
                  Export PNG
                </button>
              )}
              {handleCSVExport && (
                <button
                  type="button"
                  className="filters-button"
                  onClick={this.exportCSV}
                >
                  Export CSV
                </button>
              )}
            </div>
          )}
        </div>
        <div ref={this.hiddenGraph} className="hiddenGraph" />
        {loading && <Loader />}
        {noData && <div>No data</div>}
      </div>
    );
  }
}

export default GenericGraph;
