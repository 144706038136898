import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import withRouter from '../hooks/withRouter';

import { setInSituFilters } from '../store/filter/actions';
import { getInSituGraphData } from '../store/graph/insitu.actions';
import getInSituFilters from '../selectors/filters';
import InSituView from '../components/GraphView/InSituView';
import getNewRoute from './helpers';

const mapStateToProps = (state, ownProps) => {
  let logarithmicDisplay = 'off';
  const searchToParse = ownProps.url
    ? ownProps.url
    : state.router.location.search;
  const parsedSearch = new URLSearchParams(searchToParse);
  const urlActiveFilters = JSON.parse(parsedSearch.get('activeFilters'));
  const layoutDisplay = JSON.parse(parsedSearch.get('layoutDisplay'));
  if (layoutDisplay) {
    logarithmicDisplay = layoutDisplay.log === 'on' ? 'on' : 'off';
  }
  return {
    loadedFilters: state.availableFilters.loaded,
    loadingGraphData: state.inSitu.graphData.loading,
    activeFilters: state.inSitu.activeFilters,
    activeSyncFilters: {},
    availableFilters: getInSituFilters(state),
    urlActiveFilters,
    graphData: ownProps.data ? ownProps.data : state.inSitu.graphData.data,
    graphData2: ownProps.data2 ? ownProps.data2 : state.inSitu.graphData.data2,
    graphTypes: [],
    colors: state.style.markers,
    viewType: 'insitu',
    onlyOneFilter: false,
    layoutDisplay,
    logarithmicDisplay,
    graphOnly: !!ownProps.url,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilters: filters => {
    dispatch(setInSituFilters(filters));
  },
  getGraphData: filters => {
    // we build filtersData with stat = 1 = DATA
    const filtersData = filters.map(currentFilter => ({
      ...currentFilter,
      stat: 1,
    }));
    dispatch(getInSituGraphData(filters, filtersData));
  },
  updateRoute(location, queryName) {
    dispatch(push(getNewRoute(location, queryName)));
  },
});

const InSituPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InSituView),
);

export default InSituPage;
