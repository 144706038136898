import Plotly from 'plotly.js/lib/core';
import plotlyHeatmap from 'plotly.js/lib/heatmap';
import plotlyContour from 'plotly.js/lib/contour';
import GenericGraph from './GenericGraph';
import { getMinMaxColormap } from './3DGraph.helper';

Plotly.register(plotlyHeatmap);
Plotly.register(plotlyContour);

class Graph3D extends GenericGraph {
  defaultLayout = {
    margin: {
      t: 30,
      r: 60,
      b: 30,
      l: 60,
      pad: 0,
    },
    yaxis: {
      title: 'depth',
      autorange: 'reversed',
      type: 'log',
    },
    hovermode: 'closest',
    legend: {
      yanchor: 'bottom',
      y: -0.3,
      xanchor: 'center',
      x: 0.5,
      orientation: 'h',
    },
  };

  initGraph(
    type,
    datasets,
    customLayout,
    customOptions,
    activeFilters,
    availableFilters,
    colors,
    ref,
  ) {
    const colorbarParams = getMinMaxColormap(
      datasets,
      this.props.colorscale,
      this.props.minMax,
      this.props.isCentered,
      customOptions,
    );
    const { colorbar, minvalue, maxvalue, size } = colorbarParams;
    this.data = datasets.values.map((set, index) => ({
      x: datasets.dates,
      z: set,
      y: datasets.depths,
      title: datasets.title,
      type,
      colorscale: colorbar,
      zmin: minvalue,
      zmax: maxvalue,
      autocontour: false,
      line: {
        smoothing: 0.85,
      },
      contours: {
        showlines: false,
        start: minvalue,
        end: maxvalue,
        size,
      },
      name: datasets.labels[index],
    }));
    this.layout = {
      ...this.defaultLayout,
      title: datasets.title,
      ...customLayout,
    };
    this.options = { ...this.defaultOptions };
    this.graph = Plotly.newPlot(
      ref.current,
      this.data,
      this.layout,
      this.options,
    );
  }
}

export default Graph3D;
