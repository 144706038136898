import React, { useMemo } from 'react';
import SimpleLinearRegression from 'ml-regression-simple-linear';

const round = (num, decimals = 0) => {
  const numRounded = Number(`${Math.round(`${num}e${decimals}`)}e-${decimals}`);
  if (num !== 0 && numRounded === 0) {
    return round(num, decimals + 1);
  }
  return numRounded;
};

const getDateFromValues = (date, values, start = true) => {
  const index = values.findIndex(item => item > date);
  if (start) {
    return index === -1 ? 0 : index;
  }
  return index === -1 ? values.length : index - 1;
};

export const Trends = ({
  graphs: { labels, values, dates, title, trends } = {},
  dateRange,
}) => {
  const xy = useMemo(() => {
    if (!dates) return [];
    const { min, max, start = min, end = max } = dateRange;
    if (!start && !end) {
      return [];
    }
    const startIndex = getDateFromValues(start, dates);
    const endIndex = getDateFromValues(end, dates, false);
    const x = dates.slice(startIndex, endIndex);
    const y = values.map(value => value.slice(startIndex, endIndex));
    return [x, y];
  }, [dateRange, dates, values]);

  const simulatedTrends = useMemo(() => {
    const [x, y] = xy;

    if (!x && !y) {
      return [];
    }

    return y.map(yItem => {
      const nextXFiltered = x.filter((_, index) => yItem[index] !== null);
      if (nextXFiltered.length <= 1) {
        return [];
      }
      const firstDate = new Date(x[0]);
      const nextX = nextXFiltered.map(date => {
        const currentDate = new Date(date);
        const timeDiff = Math.abs(
          currentDate.getTime() - (firstDate.getTime() + 1),
        );
        return Math.ceil(timeDiff / (1000 * 3600 * 24 * 365.25));
      });
      const nextY = yItem.filter(Boolean);

      const { slope } = new SimpleLinearRegression(nextX, nextY);
      if (Number.isNaN(slope)) {
        return 'Not enough data';
      }

      return `${round(slope, 3)}`;
    });
  }, [xy]);

  if (!trends) {
    return null;
  }

  return (
    <div>
      <h2>Trends</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Curves</th>
            <th>Trends</th>
            <th>Simulated trends</th>
          </tr>
        </thead>
        <tbody>
          {labels.map((label, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              <td>{label || title}</td>
              <td>{trends[index]}</td>
              <td>{simulatedTrends[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Trends;
