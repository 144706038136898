import Plotly from 'plotly.js/lib/core';
import plotlyScatter from 'plotly.js/lib/scatter';
import plotlyBar from 'plotly.js/lib/bar';
import GenericGraph from './GenericGraph';

Plotly.register(plotlyScatter);
Plotly.register(plotlyBar);

class InSituGraph extends GenericGraph {
  defaultLayout = {
    margin: {
      t: 30,
      r: 60,
      b: 30,
      l: 60,
      pad: 0,
    },
    hovermode: 'closest',
    legend: {
      yanchor: 'bottom',
      y: -0.3,
      xanchor: 'center',
      x: 0.5,
      orientation: 'h',
    },
  };

  initGraph(
    type,
    datasets,
    customLayout,
    customOptions,
    activeFilters,
    availableFilters,
    colors,
    ref,
  ) {
    this.data = datasets?.values?.map((set, index) => ({
      x: set,
      y: datasets.depth,
      type,
      orientation: 'h',
      name: datasets.labels[index],
      marker: {
        color: colors[index % colors.length][0],
      },
    }));

    this.layout = {
      ...this.defaultLayout,
      title: datasets.title,
      ...customLayout,
    };
    this.options = { ...this.defaultOptions, ...customOptions };
    this.graph = Plotly.newPlot(
      ref.current,
      this.data,
      this.layout,
      this.options,
    );
  }
}

export default InSituGraph;
