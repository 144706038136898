import React from 'react';
import ReactTooltip from 'react-tooltip';
import { context } from '../../../context/Env';

import Loader from '../../Loader/Loader';

import './ValidationModal.scss';

class ValidationModal extends React.Component {
  static contextType = context;

  constructor(props) {
    super(props);
    const { bulletin } = this.props;
    this.state = {
      comment:
        (bulletin.isValidated ? bulletin.comment : props.bulletinComment) ?? '',
    };
  }

  componentDidUpdate(prevProps) {
    const { show, getBulletinComment, bulletin, bulletinComment } = this.props;
    const {
      env: { BULLETIN_DEFAULT_DATE },
    } = this.context;
    if (prevProps.show !== show && !bulletin.isValidated) {
      getBulletinComment(BULLETIN_DEFAULT_DATE);
    }
    if (prevProps.bulletinComment !== bulletinComment) {
      this.updateComment(bulletinComment);
    }
  }

  updateComment = comment => {
    this.setState({ comment });
  };

  validation() {
    const { bulletin, validate, closeAction } = this.props;
    const { comment } = this.state;
    const updatedBulletin = {
      id: bulletin.id,
    };
    if (comment) {
      updatedBulletin.comment = comment;
    }
    validate(updatedBulletin);
    closeAction();
  }

  render() {
    const {
      show,
      closeAction,
      canValidate,
      isValidated,
      loadingBulletinComment,
    } = this.props;

    const { comment } = this.state;

    const helpTextValidation =
      'Before sending the confirmation you can add a comment to specify details about flagged maps or any other relevant information.<br/>Once confirmed, the bulletin cannot be edit edited anymore.<br/>If you cancel all your additional comments will be lost.';

    const helpTextConsultation =
      "The daily report has already been validated. You can consult the report here but can't modify it anymore.";

    const showHideClassName = show ? 'display-block' : 'display-none';

    return (
      <div className={`${showHideClassName} bulletinModal`}>
        <section className="frontModal">
          {!isValidated ? (
            <h2>
              Validation of the daily bulletin
              <span
                data-tip={helpTextValidation}
                data-for="help"
                className="icon-info-circle"
              />
            </h2>
          ) : (
            <h2>
              Report of the daily bulletin
              <span
                data-tip={helpTextConsultation}
                data-for="help"
                className="icon-info-circle"
              />
            </h2>
          )}
          <ReactTooltip
            id="help"
            place="top"
            type="dark"
            effect="solid"
            delayHide={1500}
            className="tooltip"
            multiline
          />
          {loadingBulletinComment ? (
            <Loader>Loading bulletin comment...</Loader>
          ) : (
            <textarea
              placeholder="Optional comment sent on validation"
              className="commentZone"
              value={comment}
              disabled={isValidated}
              onChange={e => this.setState({ comment: e.target.value })}
            />
          )}
          <div className="buttonZone">
            <div className="button-left">
              <button
                type="button"
                className="modal-button closing"
                data-cy="validate-bulletin-discard-button"
                onClick={() => closeAction()}
              >
                Discard
              </button>
            </div>
            <div className="button-right">
              {!isValidated ? (
                <button
                  type="button"
                  disabled={!canValidate && loadingBulletinComment}
                  className="modal-button"
                  data-cy="validate-bulletin-button"
                  onClick={() => this.validation()}
                >
                  Confirm validation
                </button>
              ) : (
                <p>The bulletin has been validated today !</p>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ValidationModal;
