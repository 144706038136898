import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
import { scaleTime } from 'd3-scale';
import { select } from 'd3-selection';

import RangeGraph from '../GraphView/Graph/RangeGraph';
import './DateSelector.scss';

class DateSelector extends Component {
  resizeTimer = null;

  graphOptions = {
    displayModeBar: false,
  };

  graphLayout = {
    margin: {
      t: 0,
      r: 0,
      b: 20,
      l: 0,
    },
    height: 100,
    xaxis: {
      type: 'date',
      fixedrange: true,
      showgrid: false,
    },
    yaxis: {
      showgrid: false,
      fixedrange: true,
      overlaying: 'y2',
    },
    yaxis2: {
      title: null,
      showgrid: false,
      fixedrange: true,
    },
    hovermode: false,
    showlegend: false,
  };

  state = {
    selection: {
      start: null,
      end: null,
    },
  };

  timeScale = null;

  selectionGraph = React.createRef();

  componentDidMount() {
    const { displayGraph } = this.props;
    if (displayGraph === true) {
      window.addEventListener('resize', this.resizeListener);
      this.resizeListener();
    }
  }

  componentDidUpdate({ dateRange: prevDateRange }) {
    const { dateRange, displayGraph, graphData } = this.props;
    if (!dateRange.min || !dateRange.max) {
      return;
    }
    if (displayGraph === true) {
      if (JSON.stringify(dateRange) !== JSON.stringify(prevDateRange)) {
        const range = this.getUsableDateRange(dateRange);
        const { min, max } = range;

        let { start, end } = this.getUsableDateRange(prevDateRange);
        range.start && (start = range.start);
        range.end && (end = range.end);
        // We wait the new graph get data before selecting
        const refreshSelection = setInterval(() => {
          if (this.selectionGraph.current !== null && graphData) {
            clearInterval(refreshSelection);
            this.refreshSelection(start, end, min, max);
          }
        }, 200);
      }
    }
  }

  componentWillUnmount() {
    const { displayGraph, graphData } = this.props;
    if (displayGraph === true && graphData) {
      window.removeEventListener('resize', this.resizeListener);
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      this.removeSelectionEvents();
    }
  }

  /**
   * Function able to determine x & y mouse position on the graph
   */
  getUsableDateRange = dateRange => {
    const min = dateRange.min ? new Date(dateRange.min) : null;
    const max = dateRange.max ? new Date(dateRange.max) : null;
    const start = dateRange.start ? new Date(dateRange.start) : null;
    const end = dateRange.end ? new Date(dateRange.end) : null;
    return {
      min,
      max,
      start: dateRange.start === 'begin' ? min : start,
      end: dateRange.end === 'end' ? max : end,
    };
  };

  resizeListener = () => {
    const { dateRange, graphData } = this.props;
    const refreshSelection = setInterval(() => {
      if (this.selectionGraph.current !== null && graphData) {
        clearInterval(refreshSelection);
        const { start, end, min, max } = this.getUsableDateRange(dateRange);
        this.refreshSelection(start, end, min, max);
      }
    }, 200);
  };

  getMouseXInGraph = mouseX => {
    const container = this.selectionGraph.current.querySelector(
      '.graph-container .graph',
    );
    const containerX = container.getBoundingClientRect().left;
    const containerWidth = container.getBoundingClientRect().width;

    let simulatedPosition = null;
    if (mouseX <= containerX) {
      simulatedPosition = 0;
    } else if (mouseX >= containerX + containerWidth) {
      simulatedPosition = containerWidth;
    } else {
      simulatedPosition = mouseX - containerX;
    }

    return simulatedPosition;
  };

  /**
   * Calc the new scale based on min / max
   * Then draw the blue selection on graph with start / end
   * And attach event for the RangeGraph
   */
  refreshSelection = (start, end, min, max) => {
    min && max && (this.timeScale = this.computeTimeScale(min, max));
    start && end && this.drawSelection(start, end);
    this.initSelectionEvents();
  };

  endSelection = ev => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const {
      selection: { start },
    } = this.state;
    const { onChange } = this.props;
    window.removeEventListener('mousemove', this.updateSelection);
    window.removeEventListener('mouseup', this.endSelection);
    const end = new Date(
      this.timeScale.invert(this.getMouseXInGraph(ev.clientX)),
    );
    this.setState(
      ({ selection }) => ({
        selection: { ...selection, end },
      }),
      () => {
        if (end < start) {
          onChange(format(end, 'yyyy-MM-dd'), format(start, 'yyyy-MM-dd'));
          this.drawSelection(end, start);
        } else {
          onChange(format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd'));
          this.drawSelection(start, end);
        }
      },
    );
  };

  updateSelection = ev => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const {
      selection: { start },
    } = this.state;
    if (!this.timeScale) return;
    const end = new Date(
      this.timeScale.invert(this.getMouseXInGraph(ev.clientX)),
    );
    this.setState(
      ({ selection }) => ({
        selection: { ...selection, end },
      }),
      () => {
        if (end < start) {
          this.drawSelection(end, start);
        } else {
          this.drawSelection(start, end);
        }
      },
    );
  };

  resetSelection = ev => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const { onChange } = this.props;
    if (!this.timeScale) return;
    const start = new Date(this.timeScale.domain()[0]);
    const end = new Date(this.timeScale.domain()[1]);
    this.drawSelection(start, end);
    onChange('begin', 'end');
    this.setState({
      selection: {
        start,
        end,
      },
    });
  };

  startSelection = ev => {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    if (!this.timeScale) return;
    const start = new Date(
      this.timeScale.invert(this.getMouseXInGraph(ev.clientX)),
    );
    this.setState(
      ({ selection }) => ({
        selection: { ...selection, start },
      }),
      () => {
        window.addEventListener('mousemove', this.updateSelection);
        window.addEventListener('mouseup', this.endSelection);
      },
    );
  };

  initSelectionEvents = () => {
    const container = this.selectionGraph.current.querySelector(
      '.graph-container .graph',
    );

    this.removeSelectionEvents();
    container && container.addEventListener('dblclick', this.resetSelection);
    container && container.addEventListener('mousedown', this.startSelection);
  };

  removeSelectionEvents = () => {
    const container = this.selectionGraph.current.querySelector(
      '.graph-container .graph',
    );

    window.removeEventListener('mousemove', this.updateSelection);
    window.removeEventListener('mouseup', this.endSelection);
    container && container.removeEventListener('dblclick', this.resetSelection);
    container &&
      container.removeEventListener('mousedown', this.startSelection);
  };

  updateStartDate = date => {
    const { dateRange, onChange } = this.props;
    onChange(format(date, 'yyyy-MM-dd'), dateRange.end);
  };

  updateEndDate = date => {
    const { dateRange, onChange } = this.props;
    onChange(dateRange.start, format(date, 'yyyy-MM-dd'));
  };

  setStartDateToBegin = () => {
    const { dateRange, onChange } = this.props;
    onChange('begin', dateRange.end);
  };

  setStartDateToEnd = () => {
    const { dateRange, onChange } = this.props;
    onChange(dateRange.start, 'end');
  };

  computeTimeScale = (start, end) => {
    const container = this.selectionGraph.current.querySelector(
      '.graph-container .graph',
    );

    return scaleTime()
      .domain([start, end])
      .range([0, container.getBoundingClientRect().width]);
  };

  drawSelection(start, end) {
    if (!this.timeScale) return;
    const selection = document.querySelector('#selection');
    const startPoint = this.timeScale(start);
    const endPoint = this.timeScale(end);

    if (selection) {
      select(this.selectionGraph.current)
        .select('#selection')
        .attr('x', startPoint)
        .attr('width', endPoint - startPoint);
    } else {
      select(this.selectionGraph.current)
        .select('svg')
        .insert('rect')
        .attr('id', 'selection')
        .attr('x', startPoint)
        .attr('y', 0)
        .attr('width', endPoint - startPoint)
        .attr('height', 100)
        .attr('style', 'fill: rgb(0,94,147); opacity: .3;');
    }
  }

  render() {
    const {
      dateRange,
      activeFilters,
      availableFilters,
      color,
      graphData,
      displayGraph,
      isOMI,
      graphOnly,
    } = this.props;
    const { min, max, start, end } = this.getUsableDateRange(dateRange);
    const { graphOptions } = this;
    const { graphLayout } = this;
    graphLayout.xaxis.range = [min, max];
    const startDate = start || new Date();
    const endDate = end || new Date();
    return (
      <div className="date-selector">
        <div className="input-container">
          FROM
          <DatePicker
            className="date-picker"
            selected={startDate}
            dateFormat="yyyy-MM-dd"
            placeholderText="From the beginning"
            selectsStart
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            startDate={start}
            endDate={end}
            minDate={min}
            maxDate={max}
            onChange={this.updateStartDate}
          >
            <div>
              <button
                type="button"
                className="Button-begin-end"
                onClick={this.setStartDateToBegin}
              >
                Set at the beginning
              </button>
            </div>
          </DatePicker>
          {dateRange.start === 'begin' && (
            <span className="note">(first data)</span>
          )}
          TO
          <DatePicker
            selected={endDate}
            dateFormat="yyyy-MM-dd"
            placeholderText="To the last data"
            selectsEnd
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            startDate={start}
            endDate={end}
            minDate={min}
            maxDate={max}
            onChange={this.updateEndDate}
          >
            <div>
              <button
                type="button"
                className="Button-begin-end"
                onClick={this.setStartDateToEnd}
              >
                Set at the end
              </button>
            </div>
          </DatePicker>
          {dateRange.end === 'end' && <span className="note">(last data)</span>}
        </div>
        {displayGraph === true && (
          <div className="selection-graph" ref={this.selectionGraph}>
            <RangeGraph
              availableFilters={availableFilters}
              activeFilters={activeFilters}
              data={graphData}
              layout={graphLayout}
              options={graphOptions}
              showTitle={false}
              color={color}
              showMultipleYAxis={false}
              isOMI={isOMI}
              graphOnly={graphOnly}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DateSelector;
