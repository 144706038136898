import React from 'react';

import { useNavigate } from 'react-router-dom';

import Card from '../../Card/Card';
import './ParameterFilter.scss';

class ParameterFilter extends React.Component {
  static generateOptions(variable, filterParameters, param) {
    // generate dynamically the value of options for right filters
    let options = variable.graphs;
    if (param === 'domain') {
      return variable.filters[param];
    }
    if (filterParameters.domain) {
      options = options.filter(
        graph => graph.domain === filterParameters.domain,
      );
    }
    if (param === 'depth' && filterParameters.diagnostic_type) {
      options = options.filter(
        graph => graph.diagnostic_type === filterParameters.diagnostic_type,
      );
    }
    return [...new Set(options.map(graph => graph[param]))].sort(
      (a, b) =>
        variable.filters[param].indexOf(a) - variable.filters[param].indexOf(b),
    );
  }

  state = {
    parameters: null,
  };

  componentDidMount() {
    const { filterParameters } = this.props;
    this.updateParameter(this.generateState(filterParameters));
  }

  componentDidUpdate({
    filterParameters: prevFilterParameters = {},
    variableId: prevVariableId,
  }) {
    const { filterParameters = {}, variableId } = this.props;

    if (
      variableId !== prevVariableId ||
      (Object.entries(filterParameters).length === 0 &&
        Object.entries(prevFilterParameters).length !== 0)
    ) {
      this.updateParameter(this.generateState(filterParameters));
    }
  }

  updateParameter = parameters => {
    this.setState({ parameters });
  };

  generateState(filterParameters) {
    const MAP_SORT = {
      domain: 1,
      diagnostic_type: 2,
      depth: 3,
    };
    const { variable } = this.props;
    const filters = [];
    let options = [];
    let currentOption = '';
    Object.keys(variable?.filters || {})
      .sort((a, b) => MAP_SORT[a] - MAP_SORT[b])
      .forEach(param => {
        if (variable.filters[param].length > 1) {
          options = ParameterFilter.generateOptions(
            variable,
            filterParameters,
            param,
          );
          if (options.indexOf('clim-RMSDobs') > -1) {
            options.splice(options.indexOf('clim-RMSDobs'), 1);
          }
          currentOption = filterParameters[param]
            ? filterParameters[param]
            : options[0];
          filters.push({
            options,
            currentOption,
            name: param,
          });
          // FIXME: avoid mutation
          // eslint-disable-next-line no-param-reassign
          filterParameters[param] = currentOption;
        }
      });
    const parameters = filters.sort(
      (a, b) => MAP_SORT[a.name] - MAP_SORT[b.name],
    );
    this.sendValues(parameters);
    return parameters;
  }

  addParam(name, value) {
    const { filterParameters } = this.props;
    // eslint-disable-next-line no-restricted-globals
    filterParameters[name] = isNaN(value) ? value : Number(value);
    // reinit value of right filters
    if (name !== 'depth') {
      delete filterParameters.depth;
    }
    if (name === 'domain') {
      delete filterParameters.diagnostic_type;
    }
    const parameters = this.generateState(filterParameters);
    this.setState({ parameters });
    return parameters;
  }

  formatValuesForUpdate(parameters) {
    const values = {};
    parameters.forEach(filter => {
      values[filter.name] = filter.currentOption;
    });
    this.values = values;
  }

  sendValues(parametersToFormat) {
    this.formatValuesForUpdate(parametersToFormat);
    const { update } = this.props;
    update?.(this.values);
  }

  prevOption() {
    let { parameters } = this.state;
    let indexParameter = parameters.length - 1;
    let selectedParameter = {};
    let indexCurrentOption = 0;
    do {
      // find the parameter to decrease
      selectedParameter = parameters[indexParameter];
      indexParameter -= 1;
      indexCurrentOption = selectedParameter.options.indexOf(
        selectedParameter.currentOption,
      );
    } while (indexParameter >= 0 && indexCurrentOption === 0);
    parameters = this.addParam(
      // decrease the parameter
      selectedParameter.name,
      selectedParameter.options[indexCurrentOption - 1],
    );
    // update all the next parameters to be set at the last option
    indexParameter += 2;
    while (indexParameter < parameters.length) {
      selectedParameter = parameters[indexParameter];
      parameters = this.addParam(
        selectedParameter.name,
        selectedParameter.options.slice(-1)[0],
      );
      indexParameter += 1;
    }
  }

  nextOption() {
    // Terminology: parameters are domain / diagnostic_type / depth ...
    // and can contains different options
    const { parameters } = this.state;
    let indexParameter = parameters.length - 1;
    let selectedParameter = {};
    let indexCurrentOption = 0;
    let indexLastOption = 0;
    do {
      // find the parameter to increase
      selectedParameter = parameters[indexParameter];
      indexParameter -= 1;
      indexCurrentOption = selectedParameter.options.indexOf(
        selectedParameter.currentOption,
      );
      indexLastOption = selectedParameter.options.length - 1;
    } while (indexParameter >= 0 && indexCurrentOption === indexLastOption);
    this.addParam(
      // increase the parameter option
      selectedParameter.name,
      selectedParameter.options[indexCurrentOption + 1],
    );
  }

  isDisabled(direction) {
    const { parameters } = this.state;
    if (!parameters) {
      return 'disabled';
    }
    let isDisabled = true;
    let index = 0;
    parameters.forEach(param => {
      index = direction === 'next' ? param.options.length - 1 : 0;
      if (param.options.indexOf(param.currentOption) !== index) {
        isDisabled = false;
      }
    });
    return isDisabled ? 'disabled' : '';
  }

  buildPrevNextButton({
    entryLink,
    icon = 'icon-arrow-left',
    buttonType = 'previous',
    ariaLabel = 'Previous',
    rentryButtonLabel = 'Previous variable',
  }) {
    const { navigate } = this.props;
    // hasNextPrevEntry : the current button is a link to the next/prev entry in the bulletin menu
    // otherwise, it's a button to go throught the parameter of the current entity
    const hasNextPrevEntry =
      this.isDisabled(buttonType) === 'disabled' && Boolean(entryLink);
    const buttonClassName = hasNextPrevEntry
      ? ''
      : `arrow icon ${icon} ${this.isDisabled(buttonType)}`;

    const clicAction = () => {
      if (hasNextPrevEntry) {
        // if we click on previous button, we might need to collapse the next category
        // if we click on next button, we might need to collapse the previous category
        const categoryToCollapse =
          buttonType === 'previous' ? 'next' : 'previous';
        navigate({ pathname: entryLink, state: categoryToCollapse });
      } else if (buttonType === 'previous') {
        this.prevOption();
      } else if (buttonType === 'next') {
        this.nextOption();
      }
    };

    const buttonContent = hasNextPrevEntry ? (
      <div className="prevNextButton">
        {buttonType === 'previous' && (
          <span className={`icon ${icon} left-arrow`} />
        )}
        <div>{rentryButtonLabel}</div>
        {buttonType === 'next' && (
          <span className={`icon ${icon} right-arrow`} />
        )}
      </div>
    ) : null;

    return (
      <button
        type="button"
        className={buttonClassName}
        onClick={() => clicAction()}
        onKeyPress={() => clicAction()}
        tabIndex="0"
        aria-label={ariaLabel}
      >
        {buttonContent}
      </button>
    );
  }

  render() {
    const { prevEntryLink, nextEntryLink } = this.props;
    const { parameters } = this.state;

    const prevButton = this.buildPrevNextButton({
      entryLink: prevEntryLink,
      icon: 'icon-arrow-left',
      buttonType: 'previous',
      ariaLabel: 'Previous',
      rentryButtonLabel: 'Previous variable',
    });
    const nextButton = this.buildPrevNextButton({
      entryLink: nextEntryLink,
      icon: 'icon-arrow-right',
      buttonType: 'next',
      ariaLabel: 'Next',
      rentryButtonLabel: 'Next variable',
    });

    return parameters ? (
      <Card className="ParameterFilter">
        <div className="filters-items">
          {prevButton}
          {parameters.map(filter => (
            <div className="moniqua-select" key={`filter-${filter.name}`}>
              {filter.name}
              <select
                name="type"
                value={filter.currentOption}
                onChange={e => {
                  this.addParam(filter.name, e.target.value);
                }}
              >
                {filter.options.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          ))}
          {nextButton}
        </div>
      </Card>
    ) : null;
  }
}

export default function ParameterFilterWrapper(props) {
  const navigate = useNavigate();
  return <ParameterFilter navigate={navigate} {...props} />;
}
