import React from 'react';
import InputText from './InputText';

import './3DFilter.scss';

const Filter3D = ({ display3D, update3DDisplay, graphTypes }) => (
  <div className="_3DFilter">
    <h2 className="h2TitleFilter">TZ Options</h2>
    <div className="_3DFilter-Items">
      <div className="moniqua-select">
        Type of graph
        <select
          name="type"
          value={display3D.type}
          onChange={e => {
            update3DDisplay('type', e.target.value);
          }}
        >
          {graphTypes.map(value => (
            <option key={value.type} value={value.type}>
              {value.label}
            </option>
          ))}
        </select>
      </div>

      <InputText
        id="min"
        label="Min colorscale"
        onChange={update3DDisplay}
        value={display3D && display3D.min}
      />

      <InputText
        id="max"
        label="Max colorscale"
        onChange={update3DDisplay}
        value={display3D && display3D.max}
      />
    </div>
  </div>
);

export default Filter3D;
