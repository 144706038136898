import React, { useState, useEffect, useCallback } from 'react';

import DisconnectionButton from './Connection/DisconnectionButton';
import ConnectionModal from './Connection/ConnectionModal';
import './ConnectionView.scss';

const ConnectionView = ({
  checkIfConnected,
  userIsAuthenticated,
  login,
  user,
  logout,
}) => {
  const [menuIsDisplayed, setMenuIsDisplayed] = useState(false);
  const [modalConnectionIsOpen, setModalConnectionIsOpen] = useState(false);

  useEffect(() => {
    checkIfConnected();
  }, [checkIfConnected]);

  const toggleMenu = () => {
    setMenuIsDisplayed(boolean => !boolean);
  };

  const toggleModalConnection = () => {
    setModalConnectionIsOpen(boolean => !boolean);
  };

  const connectIconClick = useCallback(() => {
    if (!userIsAuthenticated) {
      toggleModalConnection();
    } else {
      toggleMenu();
    }
  }, [userIsAuthenticated]);

  const displayLogoutMenu = menuIsDisplayed && userIsAuthenticated;
  const connectionIcon = !userIsAuthenticated
    ? 'icon-user-circle-o'
    : 'icon-logout';

  return (
    <span className="connection-navigation" data-cy="connection">
      <span
        className={`icon ${connectionIcon}`}
        onClick={() => connectIconClick()}
        role="button"
        tabIndex={0}
        onKeyPress={() => connectIconClick()}
        aria-label="Toggle menu"
        data-cy="connection-icon"
      />
      {modalConnectionIsOpen && (
        <ConnectionModal
          closeAction={toggleModalConnection}
          loginAction={login}
        />
      )}
      {displayLogoutMenu && (
        <DisconnectionButton
          user={user}
          toggleMenu={toggleMenu}
          logout={logout}
        />
      )}
    </span>
  );
};

export default ConnectionView;
