import React from 'react';
import ReactTooltip from 'react-tooltip';

import Loader from '../../Loader/Loader';

import './ValidationModal.scss';

class WarningModal extends React.Component {
  handleSubmit = event => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const comment = formData.get('comment');

    const { bulletin, warningAlert, closeAction } = this.props;
    const updatedAlert = {
      id: bulletin.id,
    };

    if (comment) {
      updatedAlert.comment = comment;
    }
    warningAlert(updatedAlert);
    closeAction();
  };

  render() {
    const { show, closeAction, loadingAlertComment, isValidated } = this.props;
    const helpWarning =
      'Warning page to launch an alert. If you think the complete bulletin is corrupted, this action will send an email to the managers.';
    const showHideClassName = show ? 'display-block' : 'display-none';

    return (
      <div className={`${showHideClassName} bulletinModal`}>
        <section className="frontModal">
          <h2>
            Alert for the daily bulletin!
            <span
              data-tip={helpWarning}
              data-for="help"
              className="icon-info-circle"
            />
          </h2>
          <ReactTooltip
            id="help"
            place="top"
            type="dark"
            effect="solid"
            delayHide={1500}
            className="tooltip"
            multiline
          />

          <form onSubmit={this.handleSubmit}>
            {loadingAlertComment ? (
              <Loader>Loading Alert comment...</Loader>
            ) : (
              <textarea
                placeholder="Type here the message you want to send by email"
                className="commentZone"
                name="comment"
              />
            )}
            <div className="buttonZone">
              <div className="button-left">
                <button
                  type="button"
                  className="modal-button closing"
                  onClick={() => closeAction()}
                >
                  Discard
                </button>
              </div>
              <div className="button-right">
                {!isValidated ? (
                  <button
                    type="submit"
                    className="modal-button"
                    data-cy="warning-bulletin-button"
                  >
                    Confirm your alert
                  </button>
                ) : (
                  <p>The bulletin has been validated today !</p>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}

export default WarningModal;
