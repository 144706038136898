import APIService from '../../services/api';
import { setError } from '../error/actions';

import { setMinMaxDates } from '../date/actions';

export const SET_RANGE_FILTERS = 'SET_RANGE_FILTERS';
export const SET_DEFAULT_RANGE_FILTERS = 'SET_DEFAULT_RANGE_FILTERS';

export const SET_DEFAULT_SYNC_FILTERS = 'SET_DEFAULT_SYNC_FILTERS';
export const SET_SYNC_FILTERS = 'SET_SYNC_FILTERS';

export const SET_INSITU_FILTERS = 'SET_INSITU_FILTERS';
export const SET_DEFAULT_INSITU_FILTERS = 'SET_DEFAULT_INSITU_FILTERS';

export const LOAD_AVAILABLE_FILTERS = 'LOAD_AVAILABLE_FILTERS';
export const RECEIVED_AVAILABLE_FILTERS = 'RECEIVED_AVAILABLE_FILTERS';

export const setRangeFilters = filters => ({
  type: SET_RANGE_FILTERS,
  filters,
});

export const setDefaultRangeFilters = filters => ({
  type: SET_DEFAULT_RANGE_FILTERS,
  filters,
});

export const setSyncFilters = filters => ({
  type: SET_SYNC_FILTERS,
  filters,
});

export const setDefaultSyncFilters = filters => ({
  type: SET_DEFAULT_SYNC_FILTERS,
  filters,
});

export const setInSituFilters = filters => ({
  type: SET_INSITU_FILTERS,
  filters,
});

export const setDefaultInSituFilters = filters => ({
  type: SET_DEFAULT_INSITU_FILTERS,
  filters,
});

export const loadAvailableFilters = () => ({
  type: LOAD_AVAILABLE_FILTERS,
});

export const receivedAvailableFilters = systems => ({
  type: RECEIVED_AVAILABLE_FILTERS,
  systems,
});

/**
 * Build the initial array of filters
 * A filter is a criteria to allow the user choose a system / leadtime / area / level ...
 * Filters default values depends on the type of graph
 * In case of 'In Situ' or '3D' graphs, default values had to be an available value for the filter
 */
export const getAvailableFilters = () => async dispatch => {
  dispatch(loadAvailableFilters());
  try {
    const res = await APIService.getFiltersData();
    let defaultSystem;
    res.systems.forEach(system => {
      if (system.default) {
        defaultSystem = system;
      }
    });

    if (defaultSystem === undefined) {
      [defaultSystem] = res.systems;
    }
    const rangeFilters = {
      system: defaultSystem.pk,
      stattype: defaultSystem.stattype,
    };
    const inSituFilters = {
      system: defaultSystem.pk,
      stattype: defaultSystem.stattype,
      date: res.end,
    };
    const syncFilters = {
      system: false,
      statype: false,
    };

    // find the default value for each filter of the system
    let inSitu = false;
    defaultSystem.filters.forEach(filter => {
      let defaultValueRange;
      let defaultValueInSitu;
      let firstValueInSitu;
      filter.values.forEach(value => {
        if (value.default === true) {
          defaultValueRange = value;
          if (value.in_situ === true) {
            defaultValueInSitu = value;
            inSitu = true;
          }
        }
        // if we find a in_situ value for the first time,
        // we affect it for default value for in situ
        // in case of no default value given by the backend
        if (value.in_situ === true && !firstValueInSitu) {
          firstValueInSitu = value;
        }
      });

      // if range graph still doesn't have default value
      // we pick the first value of the filter
      if (!defaultValueRange) {
        [defaultValueRange] = filter.values;
        if (filter.values[0].in_situ) {
          inSitu = true;
        }
      }

      // if insitu(or 3d) graph still doesn't have default value
      // we pick the first insitu value of the filter
      // else we aren't in a 'insitu' filter, so we pick the range default value
      if (!defaultValueInSitu && firstValueInSitu) {
        defaultValueInSitu = firstValueInSitu;
      } else {
        defaultValueInSitu = defaultValueRange;
      }

      rangeFilters[filter.name] = defaultValueRange.pk;
      inSituFilters[filter.name] = defaultValueInSitu.pk;
      syncFilters[filter.name] = false;
    });

    // overwrite level value if not insitu filter
    if (!inSitu) {
      rangeFilters.level = null;
      syncFilters.level = null;
    }

    dispatch(receivedAvailableFilters(res));
    dispatch(setDefaultRangeFilters(rangeFilters));
    dispatch(setDefaultInSituFilters(inSituFilters));
    dispatch(setDefaultSyncFilters(syncFilters));
    dispatch(setMinMaxDates(res));
  } catch (err) {
    dispatch(setError(`Issue while fetching data: ${err}`));
  }
};
