import { SET_ERROR, UNSET_ERROR } from './actions';

export const defaultState = null;

export const error = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return action.error;

    case UNSET_ERROR:
      return null;

    default:
      return state;
  }
};
