/**
 * Make a new route with a path, and a query string with actual + new params
 *
 * @param {Object} actualLocation
 * Location of react-router
 *
 * @param {Object} newParams
 * Object of new params.
 * Each pair of key/value will be added to query string of new route
 */
export function getNewRoute(actualLocation, newParams = {}) {
  const parsedSearch = new URLSearchParams(actualLocation.search);
  Object.entries(newParams).forEach(([key, value]) => {
    parsedSearch.set(key, JSON.stringify(value));
  });
  return `${actualLocation.pathname}?${parsedSearch.toString()}`;
}

export default getNewRoute;
