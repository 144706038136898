import { connect } from 'react-redux';

import OmiView from '../components/OmiView/OmiView';

const mapStateToProps = state => ({
  availableFilters: state.availableFilters.data,
});

const OmiPage = connect(mapStateToProps)(OmiView);

export default OmiPage;
