import React from 'react';

import './DisconnectionButton.scss';

const DisconnectionButton = ({ user, logout, toggleMenu }) => (
  <div>
    <div
      className="connection-dropdown"
      onClick={() => toggleMenu()}
      role="button"
      tabIndex={0}
      onKeyPress={() => toggleMenu()}
      aria-label="Toggle menu"
    />
    <section className="front-dropdown" data-cy="disconnect-dropdown">
      <div>
        <div className="username">Connected as {user}</div>
        <div className="connection-block">
          <button
            type="button"
            className="connection-button disconnect"
            onClick={() => {
              logout();
              toggleMenu();
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </section>
  </div>
);

export default DisconnectionButton;
