import React from 'react';
import ReactTooltip from 'react-tooltip';

import './MapBox.scss';
import FlagModal from './FlagModal';
import GenericBox from './GenericBox';
import MapLegendModal from './MapLegendModal';
import { FLAG, SEEN } from '../BulletinView/constantes';

const Image = ({ alt = '', src, onClick }) => {
  if (src === undefined) {
    return null;
  }

  const img = <img src={src} alt={alt} loading="lazy" className="picture" />;

  if (onClick) {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={() => onClick()}
        onKeyPress={() => onClick()}
      >
        {img}
      </div>
    );
  }

  return img;
};

class MapBox extends GenericBox {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showHelpModal: false,
    };
  }

  updateFlagValue(comment) {
    const { content, updateFlag, variable } = this.props;
    const { status } = this.state;
    if (status === FLAG) {
      updateFlag(variable, content, '', false);
      this.setState({ status: SEEN });
    } else {
      updateFlag(variable, content, comment, true);
      this.setState({ status: FLAG });
    }
  }

  toggleHelpModal() {
    this.setState(state => ({
      showHelpModal: !state.showHelpModal,
    }));
  }

  render() {
    const {
      content,
      index,
      toggleImageModal,
      disableFlag,
      noButton,
      userIsAuthenticated,
    } = this.props;
    const { flagModalIsOpen, comment, status, showHelpModal } = this.state;

    const mainImage = (
      <Image
        src={content.picture}
        alt={content.name}
        onClick={() => toggleImageModal(index)}
        onKeyPress={() => toggleImageModal(index)}
      />
    );
    const mainImageInLegend = (
      <Image src={content.picture} alt={content.name} />
    );
    const legendImage = (
      <Image
        src={content?.help_text?.image?.image}
        alt={`${content.name}-legend`}
      />
    );

    if (JSON.stringify(content) === '{}') {
      return (
        <div className="card bulletin-content map">
          <p className="noContentError">No picture matching the query !</p>
        </div>
      );
    }

    return (
      <>
        <MapLegendModal
          legend={content?.help_text?.legend}
          legend_warn={content?.help_text?.legend_warn}
          legendImage={legendImage}
          mainImageInLegend={mainImageInLegend}
          showHelpModal={showHelpModal}
          toggleHelpModal={() => this.toggleHelpModal()}
        />

        <div
          className={`card bulletin-content map ${
            status === FLAG && userIsAuthenticated ? 'map-error' : ''
          }`}
        >
          {mainImage}
          {!noButton && (
            <div>
              <div className="button-left">
                {userIsAuthenticated && (
                  <button
                    className="filters-button error-button"
                    type="button"
                    disabled={disableFlag || content.locked}
                    data-cy="map-flag-button"
                    onClick={() =>
                      status === FLAG
                        ? this.updateFlagValue('')
                        : this.toggleFlagModal()
                    }
                  >
                    {status === FLAG ? 'Unflag' : 'Flag'}
                  </button>
                )}
              </div>
              <div className="button-right">
                <button
                  className="filters-button"
                  type="button"
                  disabled={!content?.help_text}
                  data-cy="map-help-button"
                  onClick={() => this.toggleHelpModal()}
                >
                  Help
                </button>
                <ReactTooltip
                  id={`help-tooltip${content.id}`}
                  globalEventOff="click"
                  className="tooltip"
                  effect="solid"
                  multiline
                />
                <a href={content.picture} download={content.name}>
                  <button className="filters-button" type="button">
                    Download
                  </button>
                </a>
              </div>
            </div>
          )}
          {flagModalIsOpen && !noButton ? (
            <FlagModal
              show={flagModalIsOpen}
              closeAction={this.toggleFlagModal}
              updateFlag={this.updateFlagValue}
              updateComment={this.updateComment}
              comment={comment}
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default MapBox;
