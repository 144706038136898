import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Card from '../Card/Card';
import Loader from '../Loader/Loader';

import './BulletinView.scss';
import MenuDisplay from './MenuDisplay';
import ReportView from '../ReportView/ReportView';
import VariableView from '../VariableView/VariableView';
import ViewTitle from '../ViewTitle/ViewTitle';
import ValidationBloc from './ValidationBloc';
import BulletinDatePicker from './BulletinDatePicker';
import { EnvContext } from '../../context/Env';

import {
  getOrderedEntries,
  getPrevNextEntry,
  getEntryLink,
} from './Bulletin.helper';
import MessageBulletinDate from './MessageBulletinDate';
import ReviewProgression from './ReviewProgression';

const BulletinView = ({
  AlerteBulletin,
  bulletin,
  bulletinDate: date,
  bulletinComment,
  comment,
  getBulletin,
  getBulletinComment,
  getReport,
  getVariable,
  loading,
  loadingBulletinComment,
  loadingContent,
  noData,
  reportIdToDisplay,
  reportToDisplay,
  setMapStatus,
  setGraphStatus,
  systems,
  userIsAuthenticated,
  validateBulletin,
  variableIdToDisplay,
  variableToDisplay,
}) => {
  const location = useLocation();
  const [disableFlag, setDisableFlag] = useState(true);
  const [entriesStatus, setEntriesStatus] = useState(undefined);
  const {
    env: { BULLETIN_DEFAULT_DATE },
  } = EnvContext();

  const bulletinDate = date ?? BULLETIN_DEFAULT_DATE;
  const isLatestBulletin = date === BULLETIN_DEFAULT_DATE;

  const generateEntriesStatus = useCallback(() => {
    const nextEntriesStatus = {};
    systems.forEach(system => {
      system.categories.forEach(category => {
        category.entries.forEach(({ name, id, flag, unseen }) => {
          nextEntriesStatus[`${id}-${name}`] = {
            isFlagged: flag,
            isUnseen: unseen,
          };
        });
      });
    });
    return nextEntriesStatus;
  }, [systems]);

  const updateGenerateEntriesStatus = useCallback(
    flag => {
      setEntriesStatus(generateEntriesStatus());
      setDisableFlag(flag);
    },
    [generateEntriesStatus],
  );

  useEffect(() => {
    getBulletin(bulletinDate);
  }, [bulletinDate, getBulletin]);

  useEffect(() => {
    if (variableIdToDisplay !== null) {
      getVariable(variableIdToDisplay, bulletinDate);
    }
  }, [bulletinDate, getVariable, variableIdToDisplay]);

  useEffect(() => {
    if (reportIdToDisplay !== null) {
      getReport(reportIdToDisplay);
    }
  }, [getReport, reportIdToDisplay]);

  useEffect(() => {
    if (systems.length > 0 && entriesStatus === undefined) {
      updateGenerateEntriesStatus(bulletin.isValidated);
    }
  }, [
    entriesStatus,
    bulletin.isValidated,
    systems.length,
    updateGenerateEntriesStatus,
  ]);

  const handleValidateBulletin = updatedBulletin => {
    setDisableFlag(true);
    validateBulletin(updatedBulletin);
  };

  const updateEntryStatus = useCallback(
    (entry, status) => {
      if (!isLatestBulletin) {
        return;
      }
      setEntriesStatus(prevEntriesStatus => {
        const item = `${entry.id}-${entry.name}`;
        return {
          ...prevEntriesStatus,
          [item]: {
            ...prevEntriesStatus[item],
            ...status,
          },
        };
      });
    },
    [isLatestBulletin],
  );

  const displayVariable =
    entriesStatus !== undefined &&
    variableIdToDisplay !== null &&
    variableToDisplay?.graphs;
  const displayReport = reportIdToDisplay && reportToDisplay;

  if (noData) {
    return <MessageBulletinDate message="There is no bulletin on" />;
  }

  if (loading || !entriesStatus) {
    return <Loader>Loading bulletin...</Loader>;
  }

  const entryId = variableIdToDisplay ?? reportIdToDisplay;
  const entryType = variableIdToDisplay ? 'variable' : 'report';

  const orderedEntries = getOrderedEntries(systems);
  const prevEntry = getPrevNextEntry(
    orderedEntries,
    entryId,
    entryType,
    'prev',
  );
  const nextEntry = getPrevNextEntry(
    orderedEntries,
    entryId,
    entryType,
    'next',
  );
  const prevEntryLink = getEntryLink(bulletinDate, prevEntry);
  const nextEntryLink = getEntryLink(bulletinDate, nextEntry);

  // Navigate to first entry when accessing /bulletin
  // (no variable or report to display)
  const emptyPage =
    (!loading && !variableIdToDisplay && !reportIdToDisplay) || !date;
  const hasFirstEntry = orderedEntries?.length > 0;
  if (emptyPage && hasFirstEntry) {
    const firsEntryLink = getEntryLink(bulletinDate, orderedEntries[0]);
    return <Navigate replace to={firsEntryLink} />;
  }

  return (
    <>
      <ViewTitle pageTitle="Bulletin" />
      <div className="BulletinView">
        <Card className="nav">
          <div className="bulletin-info">
            <ReviewProgression
              bulletinDate={bulletinDate}
              variableIdToDisplay={variableIdToDisplay}
              reportIdToDisplay={reportIdToDisplay}
              entriesStatus={entriesStatus}
              userIsAuthenticated={userIsAuthenticated}
            />
            {bulletin.last_update && (
              <div>Last update: {bulletin.last_update}</div>
            )}
            <BulletinDatePicker endDate={bulletin.last_update} />
            <ValidationBloc
              AlerteBulletin={AlerteBulletin}
              bulletin={bulletin}
              bulletinComment={bulletinComment}
              comment={comment}
              entriesStatus={entriesStatus}
              getBulletinComment={getBulletinComment}
              handleValidateBulletin={handleValidateBulletin}
              loadingBulletinComment={loadingBulletinComment}
              userIsAuthenticated={userIsAuthenticated && isLatestBulletin}
            />
          </div>
          <div className="categories">
            <MenuDisplay
              bulletinDate={bulletinDate}
              entriesStatus={entriesStatus}
              systems={systems}
              userIsAuthenticated={userIsAuthenticated && isLatestBulletin}
              entryId={entryId}
              entryType={entryType}
              orderedEntries={orderedEntries}
              prevNextCategory={location?.state}
            />
          </div>
        </Card>
        {loadingContent ? (
          <div className="main">
            <Loader>Loading content...</Loader>
          </div>
        ) : (
          <div className="main">
            <MessageBulletinDate />
            {displayVariable ? (
              <VariableView
                variableToDisplay={variableToDisplay}
                variableIdToDisplay={variableIdToDisplay}
                disableFlag={disableFlag}
                setMapStatus={setMapStatus}
                updateEntryStatus={updateEntryStatus}
                userIsAuthenticated={userIsAuthenticated && isLatestBulletin}
                bulletinDate={bulletin.date}
                prevEntryLink={prevEntryLink}
                nextEntryLink={nextEntryLink}
              />
            ) : null}

            {displayReport ? (
              <ReportView
                reportToDisplay={reportToDisplay}
                updateEntryStatus={updateEntryStatus}
                disableFlag={disableFlag}
                setGraphStatus={setGraphStatus}
                userIsAuthenticated={userIsAuthenticated && isLatestBulletin}
                prevEntryLink={prevEntryLink}
                nextEntryLink={nextEntryLink}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default BulletinView;
