import React from 'react';

class GenericBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flagModalIsOpen: false,
      // GenericBox is extended by another components.
      // Theses states are useful.

      // eslint-disable-next-line react/no-unused-state
      comment: props.content.comment,
      // eslint-disable-next-line react/no-unused-state
      status: props.status,
    };
    this.updateComment = this.updateComment.bind(this);
    this.updateFlagValue = this.updateFlagValue.bind(this);
  }

  toggleFlagModal = () => {
    this.setState(state => ({
      flagModalIsOpen: !state.flagModalIsOpen,
    }));
  };

  updateComment(comment) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ comment });
  }
}

export default GenericBox;
