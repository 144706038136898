import React, { useState, useEffect, useRef } from 'react';

import './ConnectionModal.scss';

const ConnectionModal = ({ loginAction, closeAction }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [shouldAutoFocus, setShouldAutoFocus] = useState(true);

  const focusInput = useRef(null);

  useEffect(() => {
    if (shouldAutoFocus) {
      focusInput.current.focus();
    }
  }, [shouldAutoFocus]);

  const validate = () => {
    loginAction({ username, password });
    closeAction();
  };

  return (
    <div className="connection-modal">
      <section className="front-modal">
        <h2>Connection</h2>
        <input
          placeholder="Username"
          className="input-credential"
          value={username}
          onChange={e => setUsername(e.target.value)}
          ref={input => {
            focusInput.current = input;
          }}
        />
        <input
          placeholder="Password"
          type="password"
          className="input-credential"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              validate();
            }
          }}
          onFocus={() => setShouldAutoFocus(false)}
        />
        <div className="button-zone">
          <div className="button-left">
            <button
              type="button"
              className="modal-button close"
              onClick={() => closeAction()}
            >
              Cancel
            </button>
          </div>
          <div className="button-right">
            <button
              type="button"
              className="modal-button"
              onClick={() => validate()}
            >
              Log In
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConnectionModal;
