import {
  SET_RANGE_FILTERS,
  SET_DEFAULT_RANGE_FILTERS,
  SET_SYNC_FILTERS,
  SET_DEFAULT_SYNC_FILTERS,
  SET_INSITU_FILTERS,
  SET_DEFAULT_INSITU_FILTERS,
  LOAD_AVAILABLE_FILTERS,
  RECEIVED_AVAILABLE_FILTERS,
} from './actions';

export const availableDefaultState = {
  data: null,
  loaded: false,
};

export const availableFilters = (state = availableDefaultState, action) => {
  switch (action.type) {
    case LOAD_AVAILABLE_FILTERS:
      return {
        data: null,
        loaded: false,
      };

    case RECEIVED_AVAILABLE_FILTERS:
      return {
        data: action.systems,
        loaded: true,
      };

    default:
      return state;
  }
};

export const activeDefaultState = [];

export const rangeActiveFilters = (state = activeDefaultState, action) => {
  let newState = [...state];
  switch (action.type) {
    case SET_RANGE_FILTERS:
      newState = [...action.filters];
      return newState;

    case SET_DEFAULT_RANGE_FILTERS:
      if (state.length === 0) {
        newState = [action.filters];
        return newState;
      }
      return state;

    default:
      return state;
  }
};

export const activeSyncFiltersDefaultState = {};

export const rangeActiveSyncFilters = (
  state = activeSyncFiltersDefaultState,
  action,
) => {
  let newState = { ...state };
  switch (action.type) {
    case SET_SYNC_FILTERS:
      newState = { ...action.filters };
      return newState;

    case SET_DEFAULT_SYNC_FILTERS:
      if (state.length === 0) {
        newState = action.filters;
        return newState;
      }
      return state;

    default:
      return state;
  }
};

export const inSituActiveFilters = (state = activeDefaultState, action) => {
  let newState = [...state];
  switch (action.type) {
    case SET_INSITU_FILTERS:
      newState = [...action.filters];
      return newState;

    case SET_DEFAULT_INSITU_FILTERS:
      if (state.length === 0) {
        newState = [action.filters];
        return newState;
      }
      return state;

    default:
      return state;
  }
};
