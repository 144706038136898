export const SET_MIN_MAX_DATES = 'SET_MIN_MAX_DATES';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_DATES = 'SET_DATES';

/**
 * Trigger the reducer with {min, max} period
 *
 * @param {Object} dates
 * {min, max} period of selection maximum
 *
 * @param {bool} onlyIfNull
 * Update the state only if it's not already set
 */
export const setMinMaxDates = (dates, onlyIfNull) => ({
  type: SET_MIN_MAX_DATES,
  dates,
  onlyIfNull,
});

export const setStartDate = date => ({
  type: SET_START_DATE,
  date,
});

export const setEndDate = date => ({
  type: SET_END_DATE,
  date,
});

export const setDates = dates => ({
  type: SET_DATES,
  dates,
});
