const getDataInRange = (dateRange, data) => {
  const { start, end } = dateRange;
  const cantProcessData =
    !data ||
    data.length <= 0 ||
    data?.dates?.length <= 0 ||
    data?.values?.length <= 0 ||
    (!start && !end);

  if (cantProcessData) {
    return null;
  }

  const min = dateRange?.min || data.dates[0];
  const max = dateRange?.max || data.dates[data.dates.length - 1];

  const startItem = dateRange.start === 'begin' ? min : dateRange.start;
  const endItem = dateRange.end === 'end' ? max : dateRange.end;

  const dataDates = data?.dates?.filter(
    currentDate => currentDate >= startItem && currentDate <= endItem,
  );

  const startIndex = data.dates?.indexOf(dataDates[0]);
  const endIndex = data.dates?.indexOf(dataDates[dataDates.length - 1]);

  return {
    title: data?.title,
    labels: data?.labels,
    dates: dataDates,
    values: data?.values.map(valuesSet =>
      valuesSet.slice(startIndex, endIndex + 1),
    ),
    stats: data?.stats,
  };
};

export default getDataInRange;
