/**
 * Compute the min / max / size of a dataset,
 * Choose a colorscale related to the dataset
 *
 * @export
 * @param {any} datasets
 * @param {any} colorscale
 * @param {any} minMax
 * @returns Object{minvalue, maxvalue, size, colorbar}
 */
export function getMinMaxColormap(
  datasets,
  colorscale,
  minMax,
  isCentered,
  { min, max } = {},
) {
  const { title } = datasets;
  // calc the max absolute value
  // help to center the colorscale
  // useful only if filter is declared 'centered'
  let maxAbsValue = null;
  // fix an empiric value for the number of DATA
  const valueMax = 5000;
  datasets.values[0].forEach(currentSet => {
    currentSet.forEach(currentValue => {
      (maxAbsValue === null ||
        (Math.abs(currentValue) > maxAbsValue && currentValue < valueMax)) &&
        (maxAbsValue = Math.abs(currentValue));
    });
  });
  let minValue = null;
  datasets.values[0].forEach(currentSet => {
    currentSet.forEach(currentValue => {
      (minValue === null || currentValue < minValue) &&
        (minValue = currentValue);
    });
  });

  /* Set default values */
  let minvalue = minValue;
  let maxvalue = maxAbsValue;
  let size = (maxvalue - minvalue) / 100;

  const patternAVR = new RegExp('AVR');
  const patternRMS = new RegExp('RMS');
  const patternDATA = new RegExp('DATA');
  const patternNMS = new RegExp('NMS');
  const patternSkill = new RegExp('FCST/PERS');
  const patternTemperature = new RegExp('Temperature');
  const patternSalinity = new RegExp('Salinity');

  let colorbar = 'Jet';

  if (isCentered) {
    minvalue = maxAbsValue * -1;
    maxvalue = maxAbsValue;
  }
  if (patternSkill.test(title)) {
    minvalue = 0;
    maxvalue = 1;
  }
  if (patternDATA.test(title)) {
    minvalue = 0;
    maxvalue = 4000;
    size = 200;
  }
  if (patternAVR.test(title)) {
    colorbar = colorscale.blueRed_greycentered;
    if (patternTemperature.test(title)) {
      [minvalue, maxvalue, size] = minMax.temp;
    } else if (patternSalinity.test(title)) {
      [minvalue, maxvalue, size] = minMax.sal;
    }
  }
  if (patternRMS.test(title)) {
    colorbar = colorscale.std;
    if (patternTemperature.test(title)) {
      [, , , minvalue, maxvalue, size] = minMax.temp;
    }
    if (patternSalinity.test(title)) {
      [, , , minvalue, maxvalue, size] = minMax.sal;
    }
    if (patternDATA.test(title)) {
      minvalue = 0;
      maxvalue = maxAbsValue;
    }
  }
  if (patternNMS.test(title)) {
    minvalue = 0;
    maxvalue = 0.02;
    size = 0.001;
  }
  if (min) minvalue = parseFloat(min);
  if (max) maxvalue = parseFloat(max);
  return {
    minvalue,
    maxvalue,
    size,
    colorbar,
  };
}

export default getMinMaxColormap;
